/* eslint-env browser, node, worker */
/* eslint-disable no-debugger, spaced-comment, no-nested-ternary, no-console, prefer-template, 
   import/extensions
*/
//: [Reduced]

import { globalThis } from './global.js'

export const brexru = _ => {
  debugger
  return _
}

export const wassert = (assertion, fallback) => assertion || brexru(assertion) || fallback

wassert(globalThis)

export const weject = denied => denied && brexru(denied)

export const wejectNaN = num => weject(Number.isNaN(num)) || num

export const sfx = (assertion, ret, ...args) => assertion === 'skip'
  ? sfx(ret, ret, ...args)
  : assertion
    ? ret
    : brexru(console.log('sfx args:', ...args)) || ret

export const loxru = (proc, msg) => console.log((proc(), 'loxru proc called: ' + msg))

Error.stackTraceLimit = Infinity
