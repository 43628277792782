/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, spaced-comment, prefer-template, react/no-array-index-key,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
*/
import React from 'react'
import Title from '../../designSystem/Title'
import {RightCollapsible} from '../../components/Collapsible'
import Pagination from '../../components/Pagination'
import {useCryptoartContract} from '../../contracts/useCryptoartContract'
import CombinePanel from '../../wallet/CombinePanel'
import FragmentColorChart from '../../wallet/FragmentColorChart'
import {FragmentColorRow} from '../../wallet/FragmentColorRow'
import {isMobile} from '../../utils/isMobile'
import { FaEllipsisH } from "react-icons/fa";
import {useWallet} from '../../ethWallet/useWallet'
import { FaArrowCircleDown } from "react-icons/fa";
import {ReactComponent as Opensea} from '../../icons/Opensea.svg'
import CalculatorButton from '../../calculator/CalculatorButton'


const TableHeaderSortable = ({sorting, label, title = label}) => {
  const {sortLabels, sortingState, setSortingState} = sorting || {}
  const by = label.toLowerCase()

  if (sortLabels.includes(by)) {
    const act = sortingState.by === by
    const ascC = 'sort-arrow sort-asc ' + (act && sortingState.asc ? 'on' : '')
    const descC = 'sort-arrow sort-desc ' + (act && !sortingState.asc ? 'on' : '')
    return (
      <th className='fm-table-header sort-header' title={title}>
        {label}
        <div className={ascC} onClick={() => setSortingState({by, asc: true})} />
        <div className={descC} onClick={() => setSortingState({by, asc: false})} />
      </th>
    )
  } else {
    return <th className='fm-table-header' title={title}>{label}</th>
  }
}

const HuntingList = ({
  isError, isCombinePanelMode, pageFollowMode,
  onPageTurn, inPage, inPos,
  selectedFrag, selectedId, selectedOtherId,
  
  onSelectIdByFrag,  
  sorting, pagination, isCollapsed, setCollapsed,
  fragList, opsAccessState, below,
  MinFace1,MinFace2,MinFace3,MinFace4,MinFace5,MinFace6,
  MaxFace1,MaxFace2,MaxFace3,MaxFace4,MaxFace5,MaxFace6,
  IsLeaderBoard
}) => {
  //const contractAdapter = useCryptoartContract()
  let hasLeaderboard = IsLeaderBoard
  const wallet = useWallet()
  let fragments
  let j = 1
  if(IsLeaderBoard)
    fragments = fragList.sort((a, b) => b.score - a.score).filter(x=>x.isReady==true)
  else{
  fragments = fragList.filter( x=>
    x.squareCount[0] >= MinFace1 && x.squareCount[0] <= MaxFace1 &&
    x.squareCount[1] >= MinFace2 && x.squareCount[1] <= MaxFace2 &&
    x.squareCount[2] >= MinFace3 && x.squareCount[2] <= MaxFace3 &&
    x.squareCount[3] >= MinFace4 && x.squareCount[3] <= MaxFace4 &&
    x.squareCount[4] >= MinFace5 && x.squareCount[4] <= MaxFace5 &&
    x.squareCount[5] >= MinFace6 && x.squareCount[5] <= MaxFace6 &&
    x.isReady==true
  )}
  
  

  const fragsLen = fragments.length
  const from = pagination.from - 1
  const to = pagination.to - 1



  if (isError || (!isCombinePanelMode && fragsLen === 0)) {
    return (
    <RightCollapsible className='_MyFragsListFrame' collapsible={true} open={!isCollapsed} onChange={setCollapsed}>
     <div className="mt-2 text-xs text-gray-200">
          No fragments with these filtering!
      </div>
    </RightCollapsible>
    )
  }

  const onWheel = event => {
    if (event.deltaY < 0 && pagination.page > 1) {
      onPageTurn(pagination.page - 1)
    }
    if (event.deltaY > 0 && pagination.page < pagination.lastPage) {
      onPageTurn(pagination.page + 1)
    }
  }

  const fragmentsTitle = `Total Fragments` + (fragsLen ? ` (${fragsLen})` : ``)
  if(!hasLeaderboard) fragments = fragments.slice(from, to)
  
 
  const labelAndTitle = label => ({
    predScore: {label, title: 'Predicted score of the combined fragment'},
    Overlap: {label, title: 'The new fragment will be overlapped'}
  }[label] || {})
  
  return (
   
    <RightCollapsible className='_MyFragsListFrame' collapsible={true} open={!isCollapsed} onChange={setCollapsed}>
      {isCombinePanelMode && <CombinePanel onExit={onLeaveCombinePanelMode} frag={selectedFrag} />}  
      <div className='_MyFragmentsListHeader' onWheel={onWheel}>
        <div className='grid grid-cols-3 gap-2 place-content-center w-100'>
          <Title text={fragmentsTitle} />
          { hasLeaderboard ?<div/>:<>
          {isCombinePanelMode || isMobile()
            ? <div />
            : <div className='fm-terminal-font text-sm text-green-452 mb-2'>
                {`Selected: #${selectedId} `}
                <div className='inlink' onClick={() => onPageTurn(inPage, true)}>
                  {`p${inPage}.${inPos}`}&nbsp;{pageFollowMode ? '' : ' Follow'}
                </div>
              </div>
          }
          <div className={`_PaginationWrapper flex items-center justify-end mb-2 ${pageFollowMode ? 'dim' : ''}`}>
            <Pagination {...{pagination, onPageTurn}} />
          </div>
          </>}
        </div>
      </div>
      
    
      <div className={`_MyFragmentsListBody fraglist-wrap custom-scrollbar`} comb={'' + isCombinePanelMode}>
        <table className='table-fixed fm-table text-gray-200 text-xs text-center'>
          <thead>
            <tr>
              {hasLeaderboard && <th className='fm-table-header'>Position</th>}
              {hasLeaderboard && <th className='fm-table-header'>PFP</th>}
              {hasLeaderboard && <th className='fm-table-header'>Owner</th>}
              {hasLeaderboard &&<TableHeaderSortable {...{sorting, label: 'Score'}}/>}
              <TableHeaderSortable {...{sorting, label: 'ID'}} />
              <TableHeaderSortable {...{sorting, label: 'Properties'}} />
              {!hasLeaderboard &&<TableHeaderSortable {...{sorting, label: 'Score'}}/>}
              <TableHeaderSortable {...{sorting, label: 'Combined'}} />
              <th className='fm-table-header'><Opensea fill="#ffffffd0"/></th>
            </tr>
          </thead>
          <tbody>
            {fragments.map((frag, i) => {
              const {
                id: fragId, squareCount, combined, isReady, hasOverlap, isAlien,
                fullnessFloat, score, predScore, redeemStr, leaderboardPos, leaderboardValue,owner,ens,pfp
              } = frag
              const isSelectedFrag = selectedId === fragId
              const isSelectedOtherFrag = selectedOtherId === fragId
              const scoreStr = score?.toFixed(3) || 0
              const trClass = `fm-table-row data-text-row ${isCombinePanelMode ? 'combine-mode ' : ''}` +
                `${isSelectedFrag ? 'fm-table-row-selected ': ''}` + 
                `${isSelectedOtherFrag ? 'fm-table-row-other-selected ': ''}` +
                `${isAlien ? 'alien-frag ' : 'own-frag '}`

              return [
                <tr
                  key={`fragment_${fragId}`}
                  className={trClass}
                  onClick={() => { onSelectIdByFrag(frag)}}
                  style={{backgroundColor: (owner === wallet.account)? "#735e17" : "" }}
                >
                 {hasLeaderboard && <td>{j++}</td>}
                 {hasLeaderboard && <td><img className='rounded-full' width={"40px"} src={pfp} /></td>}
                 {hasLeaderboard && <td>{ens}</td>}
                 {hasLeaderboard && <td className='font-bold'>{scoreStr}</td>}
                
                  <td>#{fragId}</td>
                  <td><FragmentColorChart fragmentId={fragId} {...{squareCount, isReady}} /></td>
                  {!hasLeaderboard && <td className='font-bold'>{scoreStr}</td>}
                  <td className='tc'>{combined ? 'Y' : 'N'}</td>
                  <td className='tc'>
                    <a href={`https://opensea.io/assets/ethereum/0xdEA65Dd08eB6d51fb23038322376897C54668964/${fragId}`}
                      target="_blank" rel="noopener noreferrer">
                      <FaEllipsisH />
                    </a>
                  </td>
                </tr>,
                <FragmentColorRow key={i + 'fcr'} frag={frag} />
              ]}).flat()}
          </tbody>
        </table>
      </div>
      {hasLeaderboard ?
      <div className='flex flex-row items-center justify-center disable mt-3 pt-1' style={{color:"#21571E"}}>
      <FaArrowCircleDown size='38px'/>
      </div>
      : <div/>
      }
      {!hasLeaderboard && !isMobile() ?
      <div className='mt-3'>
        <CalculatorButton />
      </div>
      : <></>
      }
    </RightCollapsible>
  )
}

export default HuntingList
