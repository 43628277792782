/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, no-multi-assign, import/extensions, no-shadow */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger */

import {Corelib} from './improxy-red.js'

const {createPerfTimer, adelay, NoW} = Corelib.Tardis
const {wassert, brexru} = Corelib.Debug

export const extendContractAdapterWithHistory = ({adapter}) => {
  const {
    isReadContractValid, getDateStringYMDHMS, any2int, log, clog, rlog, createThrottledAsyncGetter, pureSolid
  } = adapter.helpers
  const {read: {contract: readContract}} = adapter

  // const qlog = console.log

  const readAttempt = (filter, start, end) => new Promise(resolve => {
    const readContract = adapter.write.contract
    readContract.queryFilter(filter, start, end)
      .then(arr=> resolve({wasOk: true, arr}))
      .catch(err => resolve({wasOk: false, arr: [], err}))
  })

  const robustEventReader = async (state, start, end) => {
    const {limit, now, white, black} = state
    const interval = (start, end) => `📦[${start - now}...${end - now}](${end - start + 1})`
    const intSE = interval(start, end)
    if (state.sum > limit) {
      console.log(`✂️Skip ${intSE}`)
      return({wasOk: true, arr: []})
    }

    console.log(`Trying ${intSE}...`, start, end)
    const response = await readAttempt(state.filter, start, end)
    if (response.wasOk) {
      console.log(`✔️Success ${intSE}: ${response.arr.length}🔔`)
      if (white) {
        response.arr = response.arr.filter(event => white.includes(event.event))
      }
      if (black) {
        response.arr = response.arr.filter(event => !black.includes(event.event))
      }
      state.sum += response.arr.length
      return response
    }

    const intervalLength = end - start + 1
    
    if (intervalLength < 3) {
      console.log(`🧨Cannot split ${intSE}: OVERFLOW`)
      return response
    }

    // const mid = ~~((start + end) * 2 / 3)
    const mid = start + ~~(intervalLength * .7)
    console.group(`⛏%cSplit ${interval(start, mid)} + ${interval(mid + 1, end)}`, 'font-weight: normal')
    const rightArr = await robustEventReader(state, mid + 1, end)
    const leftArr = await robustEventReader(state, start, mid) // fix promiseall
    console.groupEnd()

    if (leftArr.wasOk && rightArr.wasOk) {
      const arr = [...leftArr.arr, ...rightArr.arr]
      console.log(`🧲Joined ${intSE}: ${arr.length}🔔`)
      return {wasOk: true, arr}
    }
    return {wasOk: false, arr: []}
  }

  const extractEvent = ({args = {}, blockNumber, timestamp, gasUsed, event, logIndex}) => ({
    op: event,
    pars: [args.id ?? '', args.ids?.join(', ') ?? '', args.val ?? '', args.lb ?? ''].join(', '),
    humanDate: getDateStringYMDHMS(timestamp * 1000),
    gasUsed, blockNumber, logIndex,
    // id: args.id, 
    // ids: args.ids,
    // val: args.val,
    // lb: args.lb,
    // pack: args.pack,
    ...pureSolid(args),
    // args,
    dispId: args.id ?? args.ids?.[0],
    sender: args.sender || '-' // brexru()
  })

  const blockNumHash = {}

  const extendEventsWithBlockInfo = async events => {
    const promiseArr = []
    for (const event of events) {
      const {blockNumber} = event
      if (!blockNumHash[blockNumber]) {
        promiseArr.push(blockNumHash[blockNumber] = event.getBlock(blockNumber))
      }
    }
    // console.log('bef blockNumHash', blockNumHash, {promiseArr})
    const tmpBlocks = await Promise.all(promiseArr)
      .catch(err => {
        console.error('Error reading blocks', err)
        return []
      })
    for (const block of tmpBlocks) {
      blockNumHash[block.number] = block
    }  
    // console.log('aft blockNumHHash', blockNumHash, {tmpBlocks})
    
    for (const event of events) {
      const block = blockNumHash[event.blockNumber]
      event.timestamp = block.timestamp
      event.gasUsed = any2int(block.gasUsed)
    } 
    return events.map(extractEvent)
  }

  adapter.getRecentActivity = async ({limit = 30, white = null, black = null} = {}) => {
    clog(`getRecentActivity`)
    if (!isReadContractValid()) {
      return []
    }
    await adelay(100)
    const timer = createPerfTimer()

    // const end = await adapter.ethersProvider.getBlockNumber()
    const end = 100 + (adapter.stats.blockNumber || adapter.stats.lastBlockNumber)
    const start = Math.max(0, end - 250000)
    console.log({start, end})
    // const filterOps = onlyOps ? ['Mint', 'Combine', 'Redeem'] : null
    const {arr} = await robustEventReader({sum: 0, limit, filter: '*', white, black, now: end}, start, end)
    timer.mark('queries')

    const events = arr // [...allEvents.arr]
      .sort((a, b) => b.logIndex - a.logIndex)
      .sort((a, b) => b.blockNumber - a.blockNumber)
      .slice(0, limit)

    const activities = await extendEventsWithBlockInfo(events)
    rlog(`getRecentActivity ${activities.length} (${limit} limit, ` +
      `white=[${white?.join(',')}], black=[${black?.join(',')}]) in ${timer.summary()}ms`)
    return activities
  }

  // adapter.getLazyRecentActivity = 
  //   createThrottledAsyncGetter(() => 
  //     adapter.getRecentActivity({offset: 0, limit: 10}), log, 'getLazyRecentAct'
  //   )
  
  adapter.getTxLogs = async ({offset = 0, limit = 100} = {}) => { //____________ get only txlog and update events
    clog(`getTxLogs`)
    if (!isReadContractValid()) {
      return []
    }
    const timer = createPerfTimer()
    const {filters} = readContract

    // fix txLogBN too?

    const mintPromise = readContract.queryFilter(filters.TxLog(null))
      .catch(err => console.log('gethistory/mint error', {...err}))
    const combinePromise = readContract.queryFilter(filters.Update(null))
      .catch(err => console.log('gethistory/combine error', {...err}))

    const [
      myTxLogs = [], myUpdates = []
    ] = await Promise.all([mintPromise, combinePromise])
      .catch(err => {
        console.log('getHistory failed')
        console.log({...err})  
        debugger
      })
      
    timer.mark('queries')

    const hist = [...myTxLogs, ...myUpdates]
      .sort((a, b) => b.logIndex - a.logIndex)
      .sort((a, b) => b.blockNumber - a.blockNumber)
      .slice(offset, offset + limit)

    const history = await extendEventsWithBlockInfo(hist)
    rlog(`getHistory ${history.length} (${limit} limit) in ${timer.summary()}ms`)
    return history
  }  

  adapter.getHistory = async (account = 0, {offset = 0, limit = 100} = {}) => { //____________ get History
    clog(`getHistory`)
    if (!isReadContractValid()) {
      return []
    }
    const timer = createPerfTimer()
    const {filters} = readContract

    const mintPromise = readContract.queryFilter(filters.Mint(account, null))
      .catch(err => console.log('gethistory/mint error', {...err}))
    const combinePromise = readContract.queryFilter(filters.Combine(account, null))
      .catch(err => console.log('gethistory/combine error', {...err}))
    // const redeemPromise = readContract.queryFilter(filters.Redeem(account, null))
    //   .catch(err => console.log('gethistory/redeem error', {...err}))

    const [
      myMints = [], myCombines = []
    ] = await Promise.all([mintPromise, combinePromise])
      .catch(err => {
        console.log('getHistory failed')
        console.log({...err})  
        debugger
      })
      
    timer.mark('queries')

    const hist = [...myMints, ...myCombines]
      .sort((a, b) => b.logIndex - a.logIndex)
      .sort((a, b) => b.blockNumber - a.blockNumber)
      .slice(offset, offset + limit)

    const history = await extendEventsWithBlockInfo(hist)
    rlog(`getHistory ${history.length} (${limit} limit) in ${timer.summary()}ms`)
    return history
  }  
}
