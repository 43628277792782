/* eslint-disable react/prop-types, no-void, jsx-quotes, quotes */

import React from 'react'

export const RightSection = ({children}) => 
  <div className='_RightSection select-none h-full absolute top-0 right-0 custom-scrollbar bg-offblack px-3 py-4'>
    {children}
  </div>

export const CenterRightSection = ({children}) => 
  <div className='_CenterRightSection center-right-section h-full custom-scrollbar bg-offblack'>
    {children}
  </div>
