import { useCallback, useMemo, useState } from "react";

/**
 * Manages state for the situation where a user action can trigger many possible dialogs,
 * but only one should be open at a time.
 */
function useDialogFlow() {
  const [openDialog, setOpenDialog] = useState(null);

  const onDialogOpenChange = useCallback(function onDialogOpenChange(
    dialogName,
    isOpen
  ) {
    setOpenDialog(isOpen ? dialogName : null);
  },
  []);

  return useMemo(
    () => ({
      onDialogOpenChange,
      openDialog,
      setOpenDialog,
    }),
    [openDialog, onDialogOpenChange]
  );
}

export { useDialogFlow };
