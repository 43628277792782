/* eslint-env browser, node, worker */
/* eslint-disable no-debugger, spaced-comment, no-multi-spaces, no-return-assign, valid-typeof, 
   no-restricted-globals, object-curly-spacing, quotes, multiline-ternary, no-nested-ternary, no-console */

export const globalThis = typeof window === 'object'
  ? window
  : typeof global === 'object'
    ? global
    : typeof self === 'object'
      ? self : console.error(`No global scope found`)//:fix for sh** eslint
