/* eslint-env browser, node, worker */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */

// pub these are all owner only methods

export const extendContractAdapterWithFunds = ({adapter}) => {
  const {log} = adapter.helpers

  const ETH2BN = fundETH => {
    const fundmETH = fundETH * 1E6
    const big1ETH = adapter.stats.tokenPriceBN.mul(1000).div(70)
    const bigFund = big1ETH.mul(~~fundmETH).div(1E6)
    return bigFund
  }

  // rem these were for dash - but now this is not supported, only crycli

  adapter.sendToReceiver = async fundETH => {
    log(`sendToReceiver: sending`, {fundETH, bn: ETH2BN(fundETH)})
    await adapter.receiverContract.distribute({value: ETH2BN(fundETH)})
  }

  adapter.sendToReceiverByIx = async (fundETH, ix) => {
    log(`sendToReceiverByIx: sending by ix`, {ix, fundETH, bn: ETH2BN(fundETH)})
    const signer = await adapter.ethersProvider.getSigner(ix)
    const address = await signer.getAddress()
    const tx = await signer.sendTransaction({
      to: adapter.receiverContract.address,
      value: ETH2BN(fundETH)
    })
    log('sendToReceiverByIx: SIGNER', ix, address, {signer, tx})
    await adapter.receiverContract.distribute()
    //await adapter.receiverContract.distribute({value: ETH2BN(fundETH)})
  }

  adapter.payout = async (address, paymentETH) => {
    log(`adapter.payout`, {address, paymentETH, bn: ETH2BN(paymentETH)})
    await adapter.owner.cryMeth.payto(address, ETH2BN(paymentETH))
  }

  adapter.payoutPt = async (address, pt) => {
    await adapter.getStats()
    const fundETH = adapter.stats.contractBalanceETH * pt
    await adapter.payout(address, fundETH)
  }
}
