/* eslint-disable react/no-array-index-key, react/prop-types, prefer-template, no-param-reassign,
   no-return-assign, no-nested-ternary, no-unused-expressions */

import React, { useEffect } from "react";
import Title from "../designSystem/Title";
import {useWallet} from '../ethWallet/useWallet'
import { RecentActivity } from "./History/RecentActivity";
import {isMobile} from "../utils/isMobile";


export const About = () => {
  const wallet = useWallet()

  useEffect(() => {
    isMobile()?
    (document.body.setAttribute('mode','mobile')) :
    (document.body.setAttribute('mode', wallet.status !== 'connected' ? 'about' : 'home'))
  }, [wallet.status])

  return (
   
      wallet.status !== 'connected'
        ? (
        <div className="top-0 right-0 h-30 sm:h-full md:h-full lg:h-full bg-offblack bg-opacity-80 p-3 z-10 
         w-full sm:w-1/3 md:w-1/3 lg:w-1/3" style={{position: isMobile()? "static":"absolute"}} >
            <Title text="About Fragments" />
            <div className="text-sm text-gray-200 mb-3 w-auto" style={{maxWidth: 'fit-content'}}>
              <p className="mb-2">
              Fragments is a gamified generative art project with dynamic NFTs by VerticalCrypto Art.
              </p> 
              <p className="mb-2">
              Fragments intends to challenge a new concept by creating dynamic NFTs which can be upgraded. Users can express their ability by combining Fragments to achieve a higher score. 
              </p>
              <p className="mb-2">
              The score will then be reflected in the leaderboard. Through this, the most skilled player is identified at any given time. 
              </p>
              <p className="mb-2">
              The generative artwork is created in relation to each Fragments mathematical attributes; Fragments are partial cubes that can be combined to create a full cube.
              </p>
              <p className="mb-2">
              Fragments can be combined only if the mathematical attributes allows it, so not every Fragment can be combined with each other. 
              </p>
              <p className="mb-2">
              Fragments is deflationary. 
              </p>
            </div>
          </div>
          )
        : 
        (
        isMobile()?  
        (
        <div className="static top-0 right-0 h-30 sm:h-full md:h-full lg:h-full bg-offblack bg-opacity-80 p-3 z-10"
        style={{ width:'100%' }}>
        <RecentActivity />
        </div> ) : (
        <div className="absolute top-0 right-0 h-30 sm:h-full md:h-full lg:h-full bg-offblack bg-opacity-80 p-3 z-10">
        <RecentActivity />
        </div> )
        )
  )
}