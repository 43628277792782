/* eslint-disable react/no-array-index-key, react/prop-types, prefer-template, 
   no-void, no-multi-assign, no-shadow */

import React, { useState, useEffect } from "react"
import { useQuery } from "react-query"
import { getFetchHistoryFn } from "../../wallet/queries"
import {useCryptoartContract, useAdapterContext} from '../../contracts/useCryptoartContract'
import { useWallet } from "../../ethWallet/useWallet"
import { useToast } from "../../hooks/useToast"
import Title from "../../designSystem/Title"
import Pagination from "../Pagination"
import { HistoryPresentation, Loading, NoContent} from "./Common"

export const History = () => {
  const {adapterState } = useAdapterContext()
  const contractAdapter = useCryptoartContract()
  const {hasLeaderboard} = contractAdapter
  const [activities, setActivities] = useState([])
  const [page, setPage] = useState(1)

  const [eventState, _setEventState] = useState(0)
  const changeEventState = () => _setEventState(eventState + 1)
  contractAdapter.setLazyEventListener('history', changeEventState, 1000)

  const toast = useToast()
  const wallet = useWallet()

  const pageSize = 9
  const total = activities.length
  const pagination = {
    total,
    perPage: pageSize,
    page,
    lastPage: Math.ceil(total / pageSize),
    from: ((page - 1) * pageSize) + 1,
    to: page * pageSize
  }

  const onPageTurn = (page) => setPage(page)

  const history = useQuery( // fix eliminate
    "myRecentHistory",
    getFetchHistoryFn({
      account: wallet.account,
      offset: pagination.from,
      limit: 100
    })
  )

  const {refetch} = history
  useEffect(() => void refetch(), [adapterState, refetch])

  useEffect(() => {
    async function getFragments() {
      try {
        const historyIds = history.data.map(({ dispId }) => dispId)
        const fragments = await contractAdapter.getFragments(historyIds)
        console.log(`historyIds : `, historyIds)
        console.log(`fragments : `,fragments)
        for (let ix = 0; ix < history.data.length; ix++) {
          history.data[ix].fragment = fragments[ix]
        }
        //console.log('HISTORY data', history.data)
        setActivities(history.data)
      } catch (e) {
        console.error('History error caught', e)
        toast("error", "Error loading history")
      }
    }

    if (history.data?.length > 0 && activities.length === 0) {
      getFragments()
    }
  }, [history.data, activities, toast, adapterState, contractAdapter])

  useEffect(() => {
    document.body.setAttribute('mode', 'history')
  }, [])

  if (history.isLoading === true || !adapterState) {
    return <Loading />
  }

  if (activities.length === 0) {
    return <NoContent title="History" />
  }

  const historyTitle = activities.length > 0 ? `History (${activities.length})` : "History"

  return (
    <>
      <div className="grid grid-cols-2 gap-2 place-content-center w-100">
        <Title text={historyTitle} />
        <div className="flex items-center justify-end mb-2">
          <Pagination
            onPageTurn={onPageTurn}
            pagination={pagination}
          />
        </div>
      </div>
      <HistoryPresentation {...{activities, hasLeaderboard, pagination}} />
    </>
  )
}
