/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, no-multi-assign, import/extensions */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger */

import {Corelib} from './improxy-red.js'

const {createPerfTimer, schedule, NoW} = Corelib.Tardis
const {wassert} = Corelib.Debug

export const extendContractAdapterWithDrops = ({adapter}) => {
  const {
    isReadContractValid, isOwnerContractValid,
    pureSolid, any2int, big2eth, log, clog, rlog, flog, ZILLION
  } = adapter.helpers

  const setAndCheck = (hostObj, modObj, name) => {
    let changed = false
    for (const key in modObj) {
      if (hostObj[key] !== modObj[key]) {
        //console.log(`${name} found a dirty prop:`, key, modObj[key])
        changed = true
        if (key !== 'forced') {
          hostObj[key] = modObj[key]((((((((8))))))))
        }
      }
    }
    return changed
  }
  
  // REM controlVars

  const unexpected = msg => err => {
    console.error(`Unexpected exception in ${msg}:`, err, {...err})
    //debugger
    return {}
  }

  const receivedControlFlagsAndVars = lastControls => { // contract -> adapter READS BOTH flags & vars
    // console.log('receivedControlFlagsAndVars', {lastControls})
    const {
      mintOn, combineOn, genRedeemOn, lbRedeemOn, contestOn, whitelistOn, overlapOn, chainLinkOn,
      drop, supply, mintingCost, winnerSize
    } = lastControls
    adapter.controlFlags = {
      mintOn, combineOn, genRedeemOn, lbRedeemOn, contestOn, whitelistOn, overlapOn, chainLinkOn
    }
    adapter.controlVars = {drop, supply, mintingCost, winnerSize}
    adapter.updatedNow('controlFlags')
    adapter.updatedNow('controlVars')
    const {controlFlags, controlVars} = adapter
    log(`receivedControlFlagsAndVars DONE`, {...controlVars, ...controlFlags})
    adapter.wall.emit('contract.event.controlFlags', ({controlFlags}))
    adapter.wall.emit('contract.event.controlVars', ({controlVars}))
  }
  
  const readControlFlagsAndVars = async () => { // contract -> adapter READS BOTH flags & vars
    if (!isReadContractValid()) {
      return {}
    }
    //const resp = await adapter.read.cryMeth.getControlFlags()
    await adapter.getDropState()
    const {controlFlags, controlVars} = adapter
    return {controlFlags, controlVars}
  }

  adapter.getControlFlags = async (read = true) => read 
    ? (await readControlFlagsAndVars()).controlFlags : adapter.controlFlags
  
  adapter.getControlVars = async (read = true) => read 
    ? (await readControlFlagsAndVars()).controlVars : adapter.controlVars
  
  adapter.getControlFlagsAndVars = readControlFlagsAndVars

  const writeControlFlags = () => { // adapter -> contract
    const {mintOn, combineOn, genRedeemOn, lbRedeemOn, contestOn, whitelistOn, overlapOn, chainLinkOn} = adapter.controlFlags
    const flagArr = [mintOn, combineOn, genRedeemOn, lbRedeemOn, contestOn, whitelistOn, overlapOn, chainLinkOn]
    log(`💸💸contract.setControlFlags`, flagArr)
    return adapter.owner.cryMeth.setControlFlagsOnly(flagArr)
  }  

  const writeControlVars = () => { // adapter -> contract
    const {drop, supply, mintingCost, winnerSize} = adapter.controlVars
    log(`💸💸contract.setControlVars`, {drop, supply, mintingCost: big2eth(mintingCost), winnerSize})
    return adapter.owner.cryMeth.startNewDrop(drop, supply, mintingCost, winnerSize)
  }

  // chk adapter.uniAtx?

  const setControlFlagsOrVars = async (modObj, type) => {
    if (!isOwnerContractValid()) {
      return false
    }
    // check for owneronly
    const dis = `💸setControlFlagVars(${type})`
    const controlTypes = `control${type}`
    const changed = setAndCheck(adapter[controlTypes], modObj, controlTypes)
    const toWrite = (changed && (type === 'Flags' || modObj.forced)) || false
    log(`${dis} changed:${changed} toWrite:${toWrite} forced:${Boolean(modObj.forced)}`, modObj)
    if (toWrite) {
      const promise = type === 'Flags' ? writeControlFlags() : writeControlVars()
      //console.log({promise})
      const tx = await promise
        .catch(err => {
          const result = adapter.createResultFromError({err, extra: {op: `setControl${type}`}})
          console.log({err, result})
          debugger
          return result // nobody uses this
        })
      const receipt = await tx?.wait?.()
      wassert(receipt)
      const gas = receipt ? any2int(receipt.gasUsed) : 0
      const gasPerFrag = ~~(gas / adapter.controlVars.supply)
      log(`${dis} awaited, gas:`, {gas, gasPerFrag}, {tx, receipt})

      await readControlFlagsAndVars() // -> will trigger emits, (but no) await here
    }
    return changed
  }

  adapter.setControlFlags = modObj => setControlFlagsOrVars(modObj, 'Flags')
  adapter.setControlVars = modObj => setControlFlagsOrVars(modObj, 'Vars')

  adapter.getCurrDrop = () => adapter.controlVars.drop // CHK isNum?

  // REM read randomize IDs // DEBUG only

  adapter.getDropState = async () => { // todo: profile this
    if (!(isReadContractValid() && adapter.hasDrops)) {
      return {}
    }
    const timer = createPerfTimer()

    return adapter.read.cryMeth.getDropState() // constant
      .then(async ({lastDropState: contractDropState, lastCtrl: contractControls}) => {
        const lastDropState = pureSolid(contractDropState)
        //log(`adapter.getDropState.then:`, {contractDropState, lastDropState})
        const lastControls = pureSolid(contractControls)
        receivedControlFlagsAndVars(lastControls)

        const {drop, supply, winnerSize, mintingCost: mintingCostBN } = lastControls
        const mintingCostETH = big2eth(mintingCost)

        const {
          zk, idStart, idNext, maxSeries, totalFullness, mintCnt, actWinners,
          topList, claimedPool, additionalPool, 
        } = lastDropState
        
        const claimedPoolETH = big2eth(claimedPool)
        const additionalPoolETH = big2eth(additionalPool)
        const purePrizePoolETH = mintCnt * mintingCostETH / 4

        const dropState = adapter.dropState = {
          drop, supply, winnerSize, // fix these
          mintCnt,
          mintingCostBN,
          mintingCostETH,
          zk: any2int(zk.mul(ZILLION)),
          zkETH: big2eth(zk.mul(ZILLION)),
          kETH: big2eth(zk),
          purePrizePoolETH, claimedPoolETH, additionalPoolETH,
          remainingPoolETH: additionalPoolETH + purePrizePoolETH - claimedPoolETH,
          totalPrizePoolETH: additionalPoolETH + purePrizePoolETH,
          topList: topList.map(item => ({fullness: any2int(item.fullness), id: any2int(item.tokenId)})),
          topListSize: topList.length,
          totalFullnessBN: totalFullness,
          totalFullness: any2int(totalFullness),
          idStart, idNext, maxSeries, actWinners
        }
        adapter.updatedNow('dropState')
        adapter.invalidateExtDropState()
        log(`getDropState DONE in ${timer.sum().dur.sum}ms`, dropState)
        return dropState
      })
      .catch(unexpected('getDropState'))
  }

  adapter.getExtDropState = async () => {
    await adapter.getDropState()
    await adapter.updateExtDropState()
    return adapter.extDropState
  }

  // rem owner only functions

  adapter.startRandomizer = () => 
    adapter.uniAtx('owner', {nick: 'updateSeed', methName: 'updateRandomSeed'}, [], {})

  adapter.finishRandomizer = () => 
    adapter.uniAtx('owner', {nick: 'revealFrags', methName: 'revealFragments'}, [50], {})

  adapter.teamUpdate = () => 
    adapter.uniAtx('owner', {nick: 'teamMint', methName: 'teamMint'}, [], {})
}
