/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   no-void, no-console
*/
//import { useState, } from 'react'
import PropTypes from "prop-types";
import { useWallet } from "../ethWallet/useWallet";
import { useAdapterContext } from "../contracts/useCryptoartContract";
import { useCalculator } from "./useCalculator";
import CalculatorDialogTrigger from "./CalculatorDialogTrigger";

function CalculatorButton({ label, loading}) {
  void label
  const wallet = useWallet();
  const { onDialogOpenChange, openDialog } = useCalculator();
 
  useAdapterContext()
 

 
  return (
    <>
      <CalculatorDialogTrigger
        label='Open Simulator'
        loading={loading}
        onOpenChange={(isOpen) => onDialogOpenChange("PURCHASE", isOpen)}
        open={openDialog === "PURCHASE"}
        ready={wallet.status === "connected"}
      />
    </>
  );
}

CalculatorButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool
};

CalculatorButton.defaultProps = {
  label: "Open Simulator",
  loading: false
}

export default CalculatorButton;