/* eslint-disable object-curly-spacing, quotes, jsx-quotes, react/jsx-closing-bracket-location */

import MintButton from "../mint/MintButton"
import {isMobile} from "../utils/isMobile"
import {useCryptoartContract} from "../contracts/useCryptoartContract";
import {ExperiBar} from '../components/MxDia'
import {DropDownMenu} from '../components/DropDownMenu'
import { FaDiscord,FaTwitter } from "react-icons/fa"
import {ReactComponent as Opensea} from '../icons/Opensea.svg'
import {useWallet} from '../ethWallet/useWallet'



const Header = () => {
  const contractAdapter = useCryptoartContract()
  const {hasLeaderboard} = contractAdapter
  const wallet = useWallet()
  const topMenuCl = `fm-link-button fm-terminal-font nowrap`

  return (
    <>
      <div
        className="_Header grid grid-cols-2 bg-offblack"
        style={{borderBottom: "1px solid #228b22"}}
      >
        {isMobile()
          ? (
            <>
            <div className="_Header mm-mobile flex flex-row items-center p-4">
              <DropDownMenu />
            </div>
            
            <div className="_Header-mint flex flex-row items-center justify-end px-1 py-2 fm-terminal-font">
                <MintButton />
            </div>
            </>
            )
          : (
            wallet.status !== 'connected'
            ? (
            <>
              <div className="_Header-in flex flex-row items-center p-4">
                <a className={topMenuCl} href="/home">Home</a>
                {/* <a className={topMenuCl} href="/about">About Fragments</a> */}
                <a className={topMenuCl} href="/faq">FAQ</a>
                <a className={topMenuCl} href="https://opensea.io/collection/fragmentsnft-vca"
                target="_blank" rel="noopener noreferrer">
                  <Opensea fill="#228b22" className="fm-opensea"/>
                </a>
                <a className={topMenuCl} href="https://twitter.com/verticalcrypto"
                target="_blank" rel="noopener noreferrer">
                <FaTwitter className="fa fa-3x" size='22px' />
                </a>
                <a className={topMenuCl} href="https://discord.com/invite/G7HcRz7dSN"
                target="_blank" rel="noopener noreferrer">
                <FaDiscord className="fa fa-3x" size='22px' />
                </a>
              </div>
              <div className="_Header-mint flex flex-row items-center justify-end px-1 py-2 fm-terminal-font">
                <MintButton />
              </div>
            </>
            ):(
            <>
              <div className="_Header-in flex flex-row items-center p-4">
                <a className={topMenuCl} href="/home">Home</a>
                <a className={topMenuCl} href="/wallet">My Fragments</a>
                <a className={topMenuCl} href="/history">My History</a>
                <a className={topMenuCl} href="/leaderboard">Leaderboard</a>
                <a className={topMenuCl} href="/searching">Searching</a>
                {/* <a className={topMenuCl} href="/instructions">About Fragments</a> */}
                <a className={topMenuCl} href="/recentActivity">Recent Activity</a>
                <a className={topMenuCl} href="/faq">FAQ</a>
                <a className={topMenuCl} href="https://opensea.io/collection/fragmentsnft-vca"
                target="_blank" rel="noopener noreferrer">
                  <Opensea fill="#228b22" className="fm-opensea"/>
                </a>
                <a className={topMenuCl} href="https://twitter.com/verticalcrypto"
                target="_blank" rel="noopener noreferrer">
                <FaTwitter className="fa fa-3x" size='22px' />
                </a>
                <a className={topMenuCl} href="https://discord.com/invite/G7HcRz7dSN"
                target="_blank" rel="noopener noreferrer">
                <FaDiscord className="fa fa-3x" size='22px' />
                </a>
                {/* <a className={topMenuCl} href="/stats">Stats</a> */}
                {/* <a className={topMenuCl} href="/fragments-v1-infographic-2.0.pdf" target="_info">Info</a> */}
                {/* <a className={topMenuCl} href="//fragmentsv1.gitbook.io/docs/" target="_docs">Docs</a> */}
              </div>
              <div className="_Header-mint flex flex-row items-center justify-end px-1 py-2 fm-terminal-font">
                <MintButton />
              </div>
            </>
            )
            )}
      </div>
      <ExperiBar />
    </>
  )
}

export default Header
