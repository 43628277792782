/* eslint-disable spaced-comment, no-console, quotes, object-curly-spacing, no-void, 
   no-unused-expressions, prefer-template, react/prop-types
*/
import { useState, createContext, useEffect, useContext } from 'react'
import { ethers } from 'ethers'
import { env, getContractEnv } from '../adapter/contractEnv'
import { useWallet } from "../ethWallet/useWallet"
// import contractLocalInfo from "../artifacts/contracts/CryptoartV2Local.sol/CryptoartV2Local.json"
// import contractRinkebyInfo from "../artifacts/contracts/CryptoartV2.sol/CryptoartV2.json"
import { contractAdapter } from '../adapter/contractAdapter'
// import { envLine2obj } from '../adapter/contractDesc'

const clog = (...args) => console.log('🍄' + args.shift(), ...args)

const logWallet = (wallet, contractEnv) => {
  clog('wallet.account', wallet.account)
  clog('wallet.balance', wallet.balance)
  clog('wallet.status', wallet.status)
  wallet.error && 
    clog('wallet.error', wallet.error)
  clog('wallet.ethersProvider', wallet.ethersProvider)
  clog('wallet.networkName', wallet.networkName)
  clog('wallet.chainId', wallet.chainId)
  clog('contractEnv', contractEnv)
}

const testReadContract = readContract => readContract.contractURI() // direct contract call (v1 & v2)
  .then(tr => console.log('testReadContract: contractURI succeeded', {tr}))
  .catch(err => console.error('testReadContract: contractURI failed', err))

// const contractAddress = env.CONTRACT_ADDRESS
const merkleApiUrl = env.MERKLE_API_URL
const caState = {
  logged: false,
  pending: false,
  ethersProvider: null,
  status: '',
  cnt: 0,
  contractEnv: {}
}

const AdapterContext = createContext({})
const useAdapterContext = () => useContext(AdapterContext)

const AdapterProvider = ({children}) => {
  const [adapterState, setAdapterState] = useState({adapterState: 0, contractEnv: caState.contractEnv})
  caState.setAdapterState = (...args) => {
    console.log('💥💥💥AdapterContext changed', args[0]?.contractAdapter?.write, ...args)
    setAdapterState(...args)
  }

  return (
    <AdapterContext.Provider value={adapterState}>
      {children}
    </AdapterContext.Provider>
  )
}

const initContractAdapter = async wallet => {
  if (caState.pending) {
    console.log(`initContractAdapter is already running, will skip.`)
    return
  }
  const {ethersProvider, status} = wallet
  if (caState.status === status && caState.ethersProvider === ethersProvider) {
    console.log(`initContractAdapter (status, ethersProvider) didn't change, will skip.`)
    return
  }
  if (status !== 'connected') { // chk maybe this will be bad in certain circumstances
    console.log(`initContractAdapter (status !== connected), will skip.`, {status})
    return
  }

  const contractEnv = await getContractEnv()
  const {adelay} = contractAdapter.helpers
  contractAdapter.contractEnv = contractEnv
  // context changed, let's reinit contractAdapter:
  caState.status = status
  caState.ethersProvider = ethersProvider
  caState.pending = true
  await adelay(2000)
  console.group('🍄🍄initContractAdapter starts running with', {status, ethersProvider})
  logWallet(wallet, contractEnv)

  // INT new flexcontract

  const {urlPath, version, envStr, address: contractAddress, network} = contractEnv

  // 1003:20220316-181135:v2:localhost:1337:CryptoartV2Local:0x95401dc811bb5740090279Ba06cfA8fcF6113778

  // if (!env.CONTRACT) {
  //   console.error(`No REACT_APP_CONTRACT in .env!`, {env})
  //   return
  // }
  // const desc = envLine2obj(env.CONTRACT)
  // // eslint-disable-next-line no-unused-vars
  // const {id, datetime, urlPath, version, network, chainId, contractName, address: contractAddress} = desc
  // console.log({desc})

  document.querySelector('.debug-footer')?.remove()
  //set$(document.body, {class: 'debug-footer', text: envStr})
  const node = document.createElement('div')
  node.textContent = envStr?.split(':').join(' : ')
  node.className = 'debug-footer'
  document.body.appendChild(node)

  console.log({urlPath})
  const contractInfo = await fetch(urlPath)
    .then(response => response.json())
    .catch(err => console.error('Could not fetch contract file!', err, urlPath))

  // const contractInfo = contractLocalInfo

  //const contractInfo = env.CHAIN_ID === 4 ? contractRinkebyInfo : contractLocalInfo
  // console.log({contractInfo, contractLocalInfo})

  if (contractInfo && ethersProvider) { // reseting readContract first
    clog(`Connecting to readContract (without signer) at:`, contractAddress, {contractEnv})
    const readContract = new ethers.Contract(contractAddress, contractInfo.abi, ethersProvider)
    clog(`readContract:`, {readContract})
    testReadContract(readContract)
    contractAdapter.resetReadContract(ethers, readContract, {contractInfo, contractAddress, version, network})
    
    if (wallet.status === 'connected') { // if wallet is connected, let's reinit writeContract too
      const signer = ethersProvider.getSigner(wallet.account) 
      clog(`Connecting to writeContract with signer:`, signer)
      const writeContract = readContract.connect(signer)
      if (writeContract) {
        clog(`writeContract:`, {writeContract})
        contractAdapter.resetWriteContract(writeContract, signer, {merkleApiUrl})
      } else { 
        clog(`Could not create writeContract.`)
      }
    } else {
      clog(`No wallet connected, no writeContract.`)
    }  

    // if (!merkleApiUrl?.length) {
    //   console.warn('Add MERKLEAPI_URL to .env!') // test
    // }
    await contractAdapter.onReady()
    // switch off logs:
    // contractAdapter.helpers.hideVerboseLogs()
    // contractAdapter.helpers.setLog('failure', false)

    console.log('🍄🍄initContractAdapter finished running.', contractAdapter)
    console.groupEnd()
    // caState.pending = false
    caState.setAdapterState({adapterState: ++caState.cnt, contractEnv})
    await adelay(1000)
    //console.log({contractAdapter})
    //contractAdapter.triggerStateListeners()
    //caState.stateChange()
    //caState.setAdapterState({contractAdapter, adapterState: caState.cnt++})
  } else {
    console.log('No ethersProvider, no adapter reset.')
  }
  caState.pending = false
}

const lazyInitController = contractAdapter.helpers.startEndThrottle(wallet => initContractAdapter(wallet), 500)

function useCryptoartContract () {
  const wallet = useWallet()
  useEffect(() => void lazyInitController(wallet), [wallet, wallet.status, wallet.ethersProvider])
  return contractAdapter
}

const useOpsAccess = () => {
  const [canState, setCanState] = useState({})
  const {adapterState} = useAdapterContext()
  // const {contractAdapter} = useAdapterContext()

  useEffect(() => {
    const fetchData = async () => contractAdapter.hasDrops
      ? setCanState({ ...await contractAdapter.getControlFlags() })
      : setCanState({ mintOn: true, combineOn: true, genRedeemOn: true })

    fetchData()
      .catch(console.error)
  }, [adapterState])

  return canState
}

export { useCryptoartContract, useAdapterContext, AdapterProvider, useOpsAccess }
