/* eslint-env browser, node, worker */
/* eslint-disable import/extensions, no-return-assign */
/* eslint-enable no-unused-vars */
/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-void, no-multi-assign */

import {Corelib, Logre} from './improxy-red.js'

const {nop} = Corelib
const {createPerfTimer} = Corelib.Tardis
const {inspectAsObject} = Logre

export const extendContractAdapterWithStats = ({adapter}) => {
  const {
    log, clog, rlog, big2eth, any2int, isReadContractValid, createThrottledAsyncGetter, 
    pureSolid, getDateStringYMDHMS
  } = adapter.helpers

  const commonFailure = (type, err) => {
    console.group(`BAD SETUP!`)
    console.log(`⛔️⛔️⛔️There was an error calling contract.get${type}:`, err)
    console.log({...err})
    console.table({...err, transaction: JSON.stringify(err.transaction)})
    inspectAsObject({...err, transaction: JSON.stringify(err.transaction)}, {dark: true, name: 'Err'})
    if (err.code === 'CALL_EXCEPTION') {
      console.warn([
        `This is a CALL_EXCEPTION. 🥴🥺😲😥`,
        `(There are problems with the underlying blockchain infrastructure and/or MetaMask.)`,
        `Steps you can try in order to fix this:`,
        `1. Reset MetaMask`,
        `2. Restart hardhat node`,
        `3. Recompile and redeploy the contract`,
        `4. Restart the web application server.`,
        `5. Restart your computer.`
      ].join('\n'))
      console.groupEnd()
    }
  }

  const getStats = async () => { // INT getStats
    if (!isReadContractValid() || adapter.v1) {
      return {}
    }
    const timer = createPerfTimer()
    clog(`🟥getStats`)

    return adapter.read.cryMeth.getStats()
      .then(async st => {
        timer.mark('awaited')
        const {blockState = ['-', '-'], opStats = {}, globals = [] } = st
        const {mintCnt, combineCnt, redeemCnt, circCnt} = opStats
        const [
          totalBN, throttleBN, contractBalanceBN, contractBalanceLinkBN, SERIES_LENGTH_BN
        ] = globals
        
        let DynamicThrottle=40;
        // if(circCnt<100)
        // DynamicThrottle=40;

        adapter.stats = {
          blockState,
          mintCnt, combineCnt, redeemCnt, circCnt,
          contractBalanceETH: big2eth(contractBalanceBN),
          contractBalanceLNK: big2eth(contractBalanceLinkBN),
          throttle: any2int(DynamicThrottle),
          totalSupply: any2int(totalBN), 
          SERIES_LENGTH: any2int(SERIES_LENGTH_BN)
         
        }
        rlog(`🟩getStats ${timer?.summary()}ms, resolving :`, {st, stats: adapter.stats})
        return adapter.stats
      })
      .catch(err => {
        commonFailure('Stats', err)
        return {}
      })
  }

  const processRandState = _rand => {
    const {randomness, ...rest} = pureSolid(_rand)
    adapter.randState = adapter.v1
      ? rest
      : {randomness: randomness?._hex.slice(0, 10) || 'N/A', ...rest}
    adapter.updatedNow('randState')
    return adapter.randState
  }

  const processRandStateFromStats = _stats => {
    const {seedCnt, revealCnt, mintCnt, ...rest} = pureSolid(_stats)
    void rest
    adapter.randState = adapter.v1
      ? {seedCnt, revealCnt, mintCnt}
      : {}
    adapter.updatedNow('randState')
    return adapter.randState
  }

  adapter.getAdapterRandState = async () => {
    const timer = createPerfTimer()
    return adapter.v2
      ? adapter.read.cryMeth.getRandState()
        .then(contractRandState => {
          const randState = processRandState(contractRandState)
          log(`getRandState DONE in ${timer.sum().dur.sum}ms`, randState)
          return randState
        })
        .catch(adapter.contractException('getRandState.v2'))
      : adapter.getState()
        .then(() => adapter.randState)
        .catch(adapter.contractException('getRandState.v1'))
  }
  
  // int v1

  const getState = async () => { // INT getStae
    if (!isReadContractValid() || adapter.v2) {
      return {}
    }
    const timer = createPerfTimer()
    clog(`🟥getState`)

    return adapter.read.cryMeth.getState()
      .then(async st => {
        timer.mark('awaited')
        const {
          blockState = [0, 0], _stats = {}, _rand = {} , globals = [], _roles = [] , _mintIsActive=[]
        } = st
        void _rand
        const {
          mintCnt, combineCnt, redeemCnt, circCnt, teamMintCnt=0 , lastBlockNumber, aggregatedRefund
        } = _stats
        const [adminAddress] = _roles
        const [
          mintingCostBN,, throttleBN, contractBalanceBN, BLOCK_SEED_DELAY_BN, SERIES_LENGTH_BN
        ] = globals
        const[mintIsActive] =_mintIsActive
     

        let DynamicThrottle=40;
        // if(circCnt<100)
        // DynamicThrottle=40;

        const aggregatedRefundETH = big2eth(aggregatedRefund)
        const mintingCostETH = big2eth(mintingCostBN)
        const throttle = any2int(DynamicThrottle)
        const blockSeedDelay = any2int(BLOCK_SEED_DELAY_BN) // same pos used for this is v1
        const SERIES_LENGTH = any2int(SERIES_LENGTH_BN)
        const [blockTimestamp, blockNumber] = blockState.map(any2int)
       // const mintingCostBN1 = await adapter.getPrice()
      
        const teamMintPending = ~~((mintCnt - teamMintCnt) / 9 - teamMintCnt)

        //processRandState(_rand)
        processRandStateFromStats(_stats)

        const contractBalanceETH = big2eth(contractBalanceBN)
        adapter.processLiquidityPoolData(6E8, contractBalanceETH)

        adapter.stats = {
          blockTimestamp, blockNumber,
          humanBlockTimestamp: getDateStringYMDHMS(blockTimestamp * 1000),
          blockState, lastBlockNumber, blockSeedDelay,
          mintCnt, combineCnt, redeemCnt, circCnt, teamMintCnt, teamMintPending,
          aggregatedRefundETH, // fix no need for this in both?
          contractBalanceETH,
          throttle,
          totalSupply: mintCnt,
          SERIES_LENGTH,
          mintIsActive
        }

        adapter.state = {
          ...adapter.stats, // this is the stats duplicated // fix no need?
          adminAddress,
          mintingCostBN,
          mintingCostETH,
          contractBalanceBN,
          mintIsActive
        }
        rlog(`adminAddress : `, adminAddress)
        rlog(`mintIsActive : `, mintIsActive)
        rlog(`contractBalanceETH : `, contractBalanceETH)
        rlog(`🟩getState ${timer?.summary()}ms, resolving test :`, {st,state: adapter.state})
        return adapter.state
      })
      .catch(err => {
        commonFailure('State', err)
        return {}
      })
  }
  
  adapter.getState = createThrottledAsyncGetter(getState, nop, 'getState')

  adapter.getStats = adapter.v1
    ? createThrottledAsyncGetter(getState, nop, 'getState')
    : createThrottledAsyncGetter(getStats, nop, 'getStats')

  adapter.getMaxSeries = () => adapter.hasDrops
    ? adapter.dropState.maxSeries
    : ~~(adapter.stats.mintCnt / (adapter.state.SERIES_LENGTH || 1))

  adapter.getMintingCostBN = () => 
    adapter.hasDrops ? adapter.controlVars.mintingCost : adapter.state.mintingCostBN

  adapter.getMintingCost = () => 
    adapter.hasDrops ? adapter.controlVars.mintingCost : adapter.state.mintingCostBN

  adapter.getContractBalance = () => adapter.state.contractBalanceBN  
}
