import React from 'react'
import {History} from '../components/History/History'
import {Sidebar} from '../components/Sidebar'
import {RandomCube, CubeWrapper} from '../components/CubeComponents'
import {RightSection} from '../components/Layout'
import {isMobile} from '../utils/isMobile'

const HistoryPage = () => 
  // <div className='relative flex flex-1'>

  isMobile()
  ? (                                          // mobile
      <div className='_MyFragmentsPage-mobile' style={{margin:"9px"}}>
        <div className='mobile-grid'>
          <div className="_MyFragList-wrapper fullrow fr-list">
            <History />
          </div>
        </div>
      </div>
    ) : (
  <div className='page-mid-base'>
    <CubeWrapper>
      <RandomCube />
      <Sidebar />
      <RightSection><History /></RightSection>
    </CubeWrapper>
  </div>
  )
export default HistoryPage
