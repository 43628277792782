import { useContext } from "react";
import MintContext from "./MintContext";

function useMint() {
  const context = useContext(MintContext);

  if (typeof context === "undefined") {
    throw new Error("useMint() must be used within a MintProvider")
  }

  return context;
}

export { useMint };
