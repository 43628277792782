/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   no-void, no-console
*/
//import { useState, } from 'react'
import PropTypes from "prop-types";
import { useWallet } from "../ethWallet/useWallet";
//import { nFragments } from "../utils/plurals";
//import { contractAdapter } from "../adapter/contractAdapter";
import { useAdapterContext } from "../contracts/useCryptoartContract";
import { useOperations } from "../hooks";
import { useMint } from "./useMint";
import DownloadWalletDialogTrigger from "./DownloadWalletDialogTrigger";
import ConnectWalletDialogTrigger from "./ConnectWalletDialogTrigger";
import MintPurchaseDialogTrigger from "./MintPurchaseDialogTrigger";

function MintButton({ label, loading , homepageCta=false}) {
  void label
  const wallet = useWallet();
  const { mint, onDialogOpenChange, openDialog, setOpenDialog } = useMint();
  const dropState = useOperations();
  const mintDisabled = !(dropState.mintOn ?? true)
  // const {adapterState } = 
  useAdapterContext()
  // console.log({adapterState})

  // const adapterState = useAdapterState()
  
  // const [count, setCount] = useState(0)
  // const lazyRefresh = contractAdapter.helpers.debounce(() => setCount(count + 1), 1000)
  // contractAdapter.setStateListener('mintButton', lazyRefresh)

  //console.log('////////MintButton body', wallet.status, {loading})

  async function onMint(data) {
    // console.info(`Minting ${nFragments(data.count)}...`);
    return mint(data.count);
  }

  function onConnectWalletComplete() {
    setOpenDialog(null);
  }

  if (!window.ethereum) {
    return <DownloadWalletDialogTrigger />;
  }

  return (
    <>
      {/* {console.log('////////MintButton render', wallet.status, {loading, dropState})} */}
      <ConnectWalletDialogTrigger
        label='Connect wallet'
        loading={loading}
        onComplete={onConnectWalletComplete}
        onOpenChange={(isOpen) => onDialogOpenChange("CONNECT_WALLET", isOpen)}
        open={openDialog === "CONNECT_WALLET"}
        ready={wallet.status !== "connected"}
        homepageCta={homepageCta}
      />
      <MintPurchaseDialogTrigger
        disabled={mintDisabled}
        label='Mint fragments'
        loading={loading}
        onMint={onMint}
        onOpenChange={(isOpen) => onDialogOpenChange("PURCHASE", isOpen)}
        open={openDialog === "PURCHASE"}
        ready={wallet.status === "connected"}
        homepageCta={homepageCta}
      />
    </>
  );
}

MintButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool
};

MintButton.defaultProps = {
  label: "Mint fragments",
  loading: false
}

export default MintButton;