import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { FaBars,FaDiscord,FaTwitter } from "react-icons/fa";
import {ReactComponent as Opensea} from '../icons/Opensea.svg'
import {useWallet} from '../ethWallet/useWallet'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const DropDownMenu = () => {
  const wallet = useWallet()
  return (
    <Menu as="div" className="text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 "
        >
        <FaBars className="fa fa-3x" style={{color:"228b22"}} size='22px' />
          {/* <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
      { wallet.status !== 'connected' ? (
        <Menu.Items className="absolute left-10 z-40 mt-2 w-56 origin-top-left focus:outline-none text-green-450"
        style={{'backgroundColor':"#071d07"}}>
          <div className="py-1">
            <Menu.Item>
                <a href="/home" className={classNames('block px-4 py-2 text-sm' )}>
                  Home
                </a>
            </Menu.Item>
      
            <Menu.Item>
                <a href="/faq" className={classNames('block px-4 py-2 text-sm')}>
                 FAQ
                </a>
            </Menu.Item>

            <Menu.Item>
                <a
                  href="https://opensea.io/collection/fragmentsnft-vca"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <Opensea fill="#00FF00" />
                </a>
            </Menu.Item>

            <Menu.Item>
                <a
                  href="https://twitter.com/verticalcrypto"
                  className={classNames('block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                <FaTwitter className="fa fa-3x" size='18px' />
                </a>
            </Menu.Item>

            <Menu.Item>
                <a
                  href="https://discord.com/invite/G7HcRz7dSN"
                  className={classNames( 'block px-4 py-2 text-sm')}
                  target="_blank" rel="noopener noreferrer">
                 <FaDiscord className="fa fa-3x" size='18px' />
                </a>
            </Menu.Item>
           
          </div>
        </Menu.Items>
        ):(
        <Menu.Items className="absolute left-10 z-40 mt-2 w-56 origin-top-left focus:outline-none text-green-450"
          style={{'backgroundColor':"#071d07"}}>
            <div className="py-1">
              <Menu.Item>
                  <a href="/home" className={classNames('block px-4 py-2 text-sm' )}>
                    Home
                  </a>
              </Menu.Item>
        
              <Menu.Item>
                  <a href="/wallet" className={classNames('block px-4 py-2 text-sm')} >
                   My Fragments
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a href="/history" className={classNames('block px-4 py-2 text-sm')} >
                    My History
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a href="/leaderboard" className={classNames('block px-4 py-2 text-sm')}>
                   Leaderboard
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a href="/searching" className={classNames('block px-4 py-2 text-sm')}>
                   Searching
                  </a>
              </Menu.Item>
        
  
              <Menu.Item>
                  <a href="/recentActivity" className={classNames('block px-4 py-2 text-sm')}>
                  Recent Activity
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a href="/faq" className={classNames('block px-4 py-2 text-sm')}>
                   FAQ
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a
                    href="https://opensea.io/collection/fragmentsnft-vca"
                    className={classNames('block px-4 py-2 text-sm')}
                    target="_blank" rel="noopener noreferrer">
                  <Opensea fill="#00FF00" />
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a
                    href="https://twitter.com/verticalcrypto"
                    className={classNames('block px-4 py-2 text-sm')}
                    target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="fa fa-3x" size='18px' />
                  </a>
              </Menu.Item>
  
              <Menu.Item>
                  <a
                    href="https://discord.com/invite/G7HcRz7dSN"
                    className={classNames( 'block px-4 py-2 text-sm')}
                    target="_blank" rel="noopener noreferrer">
                   <FaDiscord className="fa fa-3x" size='18px' />
                  </a>
              </Menu.Item>
             
            </div>
        </Menu.Items>
        )}
      </Transition>
    </Menu>
  )
}