/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, no-multi-assign, import/extensions, no-await-in-loop */
/* eslint-disable no-unused-vars, guard-for-in */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger */

import {Corelib} from './improxy-red.js'

const {createPerfTimer, debounce, schedule, NoW} = Corelib.Tardis
const {wassert, brexru} = Corelib.Debug
const {isFun} = Corelib

export const extendContractAdapterWithEvents = ({adapter}) => {
  const {isReadContractValid, any2int, log, clog, rlog, flog, getDateStringYMDHMS} = adapter.helpers
  const {read: {contract: readContract}} = adapter
  /** Possible events / filters:
   Approval(address,address,uint256): (...args)=> {…}
    ApprovalForAll(address,address,bool): (...args)=> {…}
    Changed(string): (...args)=> {…}
    Combine(uint32[2]): (...args)=> {…}
    ErrCode(uint16): (...args)=> {…}
    Mint(uint32): (...args)=> {…}
    OwnershipTransferred(address,address): (...args)=> {…}
    Ready(uint32): (...args)=> {…}
    Redeem(uint32,uint256): (...args)=> {…}
    Transfer(address,address,uint256): (...args)=> {…}
    TxLog(string): (...args)=> {…}
  */
  /** Full event map used from contract:
   SetControlFlags()
    - Changed('CF')
    prepareDropState()
    - TxLog('Minted')
    - TxLog('NoSeed')
    - Changed('IDs')
    addToLiquidityPoolValues()  
    - Changed('LP')
    modifyLiqPoolForGeneralRedeem()
    - Changed('LP')
    fulfillRandomness()
    - Other('gotRnd')
    randomizeFragment()
    - Ready(id)  
    mint()
    - Mint(id)
    combine()
    - ErrCode(code)
    - Combine([id1, id2])  
    redeem()
    - ErrCode(code) x2
    - Redeem(id, val)  
  */
  adapter.initEventListeners = () => {
    for (const event in readContract.filters) {
      if (event.includes('(')) {
        log(`Start listening to event`, event)
        readContract.on(event, (...args) => {
          if (adapter.massModeOn) {
            return
          }
          const tx = args.pop()
          wassert(tx.blockNumber)
          const pars = args
          //console.log('🌠.on(event)', {event, pars, tx})
          //console.log('🌠.on(event)', {args})
          if (event.includes('Changed')) {
            adapter.onChangedEvent?.(pars, tx)
          }
          if (event.includes('Update')) {
            adapter.processUpdate(pars, tx)
          }
          adapter.onAnyEvent?.(event, pars, tx)
          const eventCore = event.split('(')[0]
          if (['Changed', 'Mint', 'Ready', 'Combine', 'Redeem'].includes(eventCore)) {
            adapter.onAppEvent?.(eventCore, pars, tx)
          }
          log(`%c🔔${event}:`, 'font-size:14px;background:#068;', pars, {tx})
          //wall.emit(``)
        })
      }
    }
    //contract.on('Transfer', (...args) => console.log(`%c🔔Transfer2:`, 'font-size:14px;background:#068;', args))
  }

  adapter.onChangedEvent = (pars, tx) => {
    const resp = pars[0]
    //log(`contract emitted a Changed(${resp}) event`, {tx})
    wassert(isFun(resp?.toLowerCase))
    const fun = {
      cf: () => adapter.wall.emit('contract.changed.controlFlags'),
      sc: () => adapter.wall.emit('contract.changed.controlFlags'), // BUG
      lp: () => adapter.wall.emit('contract.changed.liqPool'),
      ids: () => adapter.wall.emit('contract.changed.IDs')
    }[resp.toLowerCase()]
    fun?.()
  }

  adapter.onAnyEvent = (event, pars, tx) => {
    const eventCore = event.split('(')[0]
    const [p0, p1 = [], p2, p3] = pars
    log(`contract emitted a ${eventCore} event`, {event, pars, tx})
    const fun = {
      Changed: () => adapter.wall.emit('contract.event.changed', {type: p0, tx}),
      Mint: () => adapter.wall.emit('contract.event.mint', {owner: p0, id: p1, tx}),
      Combine: () => adapter.wall.emit('contract.event.combine', {owner: p0, id1: p1[0], id2: p1[1], tx}),
      Redeem: () => adapter.wall.emit('contract.event.redeem', {owner: p0, id: p1, val: p2, lb: p3, tx}),
      Ready: () => adapter.wall.emit('contract.event.ready', {id: p0, tx}),
      Update: () => adapter.wall.emit('contract.event.update', {pack: p0, tx}),
      TxLog: () => adapter.wall.emit('contract.event.txlog', {msg: p0, tx}),
      ErrCode: () => adapter.wall.emit('contract.event.errcode', {id: p0, tx}),
      Other: () => adapter.wall.emit('contract.event.other', {msg: p0, tx})
    }[eventCore]
    //wassert(fun)
    fun?.()
  }

  adapter.onAppEvent = (eventCore, pars, tx) => {
    const templates = {
      any: 'Changed,Mint,Ready,Combine,Redeem'.split(','),
      global: 'Changed,Mint,Ready,Combine,Redeem'.split(','),
      frag: 'Mint,Ready,Combine,Redeem'.split(','),
      myfrags: 'Mint,Ready'.split(','),
      prizepool: 'Mint,Combine,Redeem'.split(',')
    }
    console.log('Got an event:', eventCore, pars)
    for (const type in templates) {
      if (templates[type].includes(eventCore)) {
        adapter.appEventCallback[type]?.(eventCore, pars, tx) // arg?
      }
    }
  }
  adapter.setEventListener = (type, cb) => adapter.appEventCallback[type] = cb
  adapter.setLazyEventListener = (type, cb, lag) => adapter.appEventCallback[type] = debounce(cb, lag)

  // adapter.getHistoryLength = async (account = 0) => {
  //   clog(`getHistoryLength`)
  //   if (!isReadContractValid()) {
  //     return 0
  //   }
  //   const timer = createPerfTimer()
  //   const {filters} = readContract
  //   const [myMints, myCombines, myRedeems] = await Promise.all([
  //     readContract.queryFilter(filters.Mint(account, null)),
  //     readContract.queryFilter(filters.Combine(account, null)),
  //     readContract.queryFilter(filters.Redeem(account, null))
  //   ])
  //   const len = [...myMints, ...myCombines, ...myRedeems].length
  //   rlog(`getHistoryLength ${len} in ${timer.summary()}ms`)

  //   return len
  // }


}