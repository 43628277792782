import * as Dialog from "../designSystem/Dialog";
import ExternalLink from "../designSystem/ExternalLink";
import { ReactComponent as MetamaskLogo } from "../mint/metamask-fox-wordmark-stacked.svg";

function DownloadWalletDialog() {
  return (
    <Dialog.Content
      aria-describedby="download-wallet-dialog-description"
      aria-label="Download wallet"
    >
      <Dialog.Body>
        <Dialog.CloseButton />
        <div className="space-y-4 flex flex-col items-center text-center">
          <p id="download-wallet-dialog-description">
            You need an Ethereum wallet to use Fragments.
          </p>

          <div className="space-y-2 flex flex-col items-center text-center">
            <ExternalLink
              aria-labelledby="metamask-download-link"
              border={false}
              href="https://metamask.io/"
            >
              <MetamaskLogo
                className="bg-white"
                height="88px"
                width="110px"
              />
            </ExternalLink>
            <ExternalLink
              href="https://metamask.io/"
              id="metamask-download-link"
            >
              Download Metamask
            </ExternalLink>
          </div>
        </div>
      </Dialog.Body>
    </Dialog.Content>
  );
}

export default DownloadWalletDialog;
