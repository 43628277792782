/* eslint-disable react/prop-types, react/sort-prop-types, react/jsx-boolean-value, react/jsx-no-bind,
   react/require-default-props */

import PropTypes from "prop-types";
import * as Dialog from "../designSystem/Dialog";
import { ReactComponent as CheckCircled } from "../icons/CheckCircled.svg";
import { nFragments } from "../utils/plurals";
import Link from "../designSystem/Link";
import { FaDiscord } from "react-icons/fa";

function MintSuccessDialog({
  n,
  onNavigateToWallet,
  onOpenChange,
  open,
  onClose = () => {},
}) {
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Overlay />
      <Dialog.Content>
        <Dialog.Body>
          <Dialog.CloseButton onClick={onClose} />
          <div className="space-y-10 flex flex-col items-center text-center text-white">
            <div className="space-y-4 flex flex-col items-center pt-4">
              <CheckCircled height="64px" width="64px" />
              <p id="mint-success-dialog-description">
                Successfully minted {nFragments(n)}.
              </p>
              <p><b>Please now wait for some on-chain magic to reveal your fragments.</b></p>

              <a className="text-green-452 hover:text-green-450" style={{cursor:"pointer"}}
               href="https://discord.com/invite/QWSvE68QYy" target="_blank" rel="noopener noreferrer">
              <u>Join the Discord to meet the Fragments community and receive further announcements.</u>
              </a>
            </div>
           
            <a className="text-green-452 hover:text-green-450" style={{cursor:"pointer"}}
             href="https://discord.com/invite/QWSvE68QYy" target="_blank" rel="noopener noreferrer">
             <FaDiscord className="fa fa-3x" size='22px' />
            </a>
            <Link onClick={onNavigateToWallet} to="/wallet">
              View my wallet
            </Link>
          </div>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}

MintSuccessDialog.propTypes = {
  n: PropTypes.number,
  onNavigateToWallet: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

MintSuccessDialog.defaultProps = {
  n: false,
};

export default MintSuccessDialog;
