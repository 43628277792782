/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, no-unused-vars, spaced-comment
*/
import { Cross2Icon } from "@radix-ui/react-icons";
import IconButton from "../designSystem/IconButton";
import Title from "../designSystem/Title";
import FragmentSummary from "./FragmentSummary";
import {isMobile} from "../utils/isMobile"

const CombinePanel = ({onExit, frag}) =>
  <div className="_CombinePanel mb-3">
    <div className='green-label in-blk vtop abs-lt-0 text-left' 
    style={{visibility: isMobile()? 'hidden':'visible'}}>
      {`Combining`}
      <br />
      {`Fragment #${frag.id}`}
    </div>
    <div className='in-blk up-5'>
      <FragmentSummary frag={frag} />
    </div>
    <div className='in-blk vtop abs-rt-0 up-5'>
      <IconButton aria-label="Close" onClick={onExit}>
        <Cross2Icon />
      </IconButton>
    </div>
  </div>
  

export default CombinePanel
