/* eslint-disable no-debugger, spaced-comment, no-multi-spaces, no-return-assign, valid-typeof, quotes,
   object-curly-spacing, no-trailing-spaces, indent, object-property-newline, block-spacing, camelcase,
   comma-spacing, handle-callback-err,  prefer-template, no-unused-expressions, no-floating-decimal,
   no-void, object-curly-newline, new-cap, no-unused-vars, no-param-reassign, array-callback-return,
   no-restricted-syntax, no-use-before-define, import/extensions, radix, no-console, no-nested-ternary,
   no-restricted-globals, import/order, consistent-return */

import * as Corelib from './corelib-esm.js'
import * as Moisture from '../devtools/moisture-esm.js'

//8#591Logre log function generator - logre.js

const {floor, min, max} = Math
const {Ø, undef, isArr, s_a, isObj, exportToGlobal} = Corelib
const {safeStringifyJSON} = Corelib.Types
const {NoW, since} = Corelib.Tardis
const {wassert, weject, brexru, loxru, getStackLines, getCallerLinesShortNames} = Corelib.Debug
const {inspectObject} = Moisture

const statik = {
  silenceWillFall: false
}

const konstant = {
  debugVerbose: false,
  debugLog: false
}

const compileStyles = origStyle => {
  if (isArr(origStyle)) {
    return origStyle.map(compileStyles)
  }
  
  const expandRule = ([prop, value]) => {
    const rule = {prop, value}
    
    const fixEnd = (val, fix) => 
      '.0123456789'.includes((val || '0').toString().slice(-1)[0]) ? val + 'px' : val

    rule.value = {
      w: 'white',
      b: 'black'
    }[value] || value
    
    s_a('br,fs,wi,hi').includes(prop) && (rule.value = fixEnd(value, 'px'))
    
    rule.prop = {
      c: 'color',
      b: 'background',
      bg: 'background',
      bo: 'border',
      br: 'border-radius',
      td: 'text-decoration',
      fs: 'font-size',
      fw: 'font-weight',
      ts: 'text-shadow',
      bs: 'box-shadow',
      ma: 'margin',
      pa: 'padding',
      onw: 'onwhite',
      onb: 'onblack'
    }[prop] || prop
  
    const borderType = {
      s: 'solid',
      d: 'dotted'
    }
    
    const specFun = {
      onwhite: _ => rule.rule = `background:white;color:${rule.value}`,
      onblack: _ => rule.rule = `background:black;color:${rule.value}`,
      malr: _ => rule.rule = `margin-left:${rule.value}px; margin-right:${rule.value}`,
      palr: _ => rule.rule = `padding-left:${rule.value}px; padding-right:${rule.value}`,
      matp: _ => rule.rule = `margin-top:${rule.value}px; margin-bottom:${rule.value}`,
      patp: _ => rule.rule = `padding-top:${rule.value}px; padding-bottom:${rule.value}`,
      bg0: _ => rule.rule = `background:#000`,
      bl: _ => rule.rule = `display:block`,
      in: _ => rule.rule = `display:inline`,
      inbl: _ => rule.rule = `display:inline-block`,
      dino: _ => rule.rule = `display:none`,
      ita: _ => rule.rule = `font-style:italic`,
      brx: _ => rule.rule = `border-radius:${rule.value}px`,
      bor: _ => rule.rule = `border: ${value[0]}px ${borderType[value[1]]} ${value.slice(2)}`
      //4cinv: color: lastbgcol inverted -> all props until this point must be stored in a map
    }[prop]
    
    specFun && specFun()
    
    return rule.rule || (rule.prop + ':' + rule.value)
  }
  wassert(typeof origStyle === 'string')
  wassert(typeof origStyle.split === 'function')
  
  let starr = origStyle.split(';').map(a => a.trim()).filter(a => a)
  starr = starr.map(rule => expandRule(rule.split('=').join(':').split(':'))).join(';').split(';')//4split multiples
  const propMap = {}
  starr.map(rule => {
    const [prop, value] = rule.split(':')
    propMap[prop] = value
  })
  const retarr = []
  propMap.loopKeys(key => retarr.push(`${key}:${propMap[key]}`))
  return retarr.join(';')
}

const logSkipFirst = (first, ...args) => console.log(...args)

console.silence = (nuval = true) => statik.silenceWillFall = nuval

const genLog = (params, style, ...argsIn) => {
  if (statik.silenceWillFall) {
    return
  }
  if (konstant.debugVerbose) {
     console.log('genLogCalled with params:', params)
     console.log('genLogCalled with style:', style)
     console.log('genLogCalled with argsIn:', argsIn)      
  }
  const defaultPars = {
    realLog: console.log,
    dynRealLog: undef,
    stackInfo: false,
    stackInfObj: false,
    skipPars: 0        
  }
  
  const logre = {
    ...defaultPars,
    ...params
    //...(params.logger || {})//9NOT USED
  }
  
  style && style.slice(-1) !== ';' && (style += ';')
  
  const parsSkipped = argsIn.slice(0, logre.skipPars)//9will be [] if skiPpars === 0
  const args = argsIn.slice(logre.skipPars)//        
  
  let main = args.shift()
  
  typeof main === 'number' && (main += '')
  typeof main === 'object' && (main = safeStringifyJSON(main).substr(0, 50) + '...')
  wassert(typeof main === 'string')
  const styledParts = main.split('%c')
  //4 %calma %ckorte %cbanan -> 0='' 1='alma' 2='korte' 3='banan'
  //4 alma %ckorte %cbanan -> 0='alma' 1='korte' 2='banan'
  const styleCount = styledParts.length - 1
  const styleArgs = args.slice(0, styleCount).map(arg => style + arg)//4all added to style
  const remainingArgs = args.slice(styleCount)

  if (style && main.substr(0, 2) !== '%c') {
    main = '%c' + main
    styleArgs.unshift(style)
  }  
  const styledStyleArgs = compileStyles(styleArgs)
  konstant.debugVerbose && console.log(`BEFORE: ${styleArgs.join(' // ')}\nAFTER: ${styledStyleArgs.join(' // ')}`)
  
  const caller = logre.stackInfo 
    ? ['l:' + getCallerLinesShortNames(2, 5)
      .split('.js:').join(':').split('-> :')
      .filter(a => !a.beginS('logre'))
      .slice(-3)
      .join(' ')
      .repL('-esm', 'E')] 
    : []
  //console.log('caller: ', caller)
  const stack = logre.stackInfObj
    ? (new Error()).stack.split('\n').slice(5, 10)
    : undef
  const realArgs = logre.stackInfObj 
    ? [...parsSkipped, main, ...styledStyleArgs, ...remainingArgs, {s: stack}]
    : [...parsSkipped, main, ...styledStyleArgs, ...remainingArgs, ...caller]
  //params.type && realArgs.push({type: params.type}) //type: ilog => menjen a vegere  
  //nem jo, mert utana rakja a stacket, zsakutca
  const logger = logre.dynRealLog ? logre.dynRealLog() : logre.log || logre.realLog

  if (params.buffered) {
    params.buffer = params.buffer || []
    params.buffer.push({logger, realArgs, at: NoW()})
    
    const buffTime = typeof params.buffered === 'string'
    //const buffCount = !buffTime
    let flushTime = false
    
    if (buffTime) {//4time period type
      params.bufferStart = params.bufferStart || NoW()
      if (since(params.bufferStart) > parseInt(params.buffered) * 1000) {
        flushTime = true
        params.bufferStart = NoW()
      }
    } else {//4counter type
      params.bufferStart = params.bufferStart || params.buffered //4 -> e.g. 10
      if (params.bufferStart-- < 1) {
        flushTime = true
        params.bufferStart = params.buffered
      }
    }
    if (flushTime) {
      params.buffer.map(buf => buf.logger(...buf.realArgs, -since(buf.at) / 1000 + 's'))
      params.buffer = []
    }
  } else {
    return logger(...realArgs)
  }
}

export const createLog = logDesc => createLogs([logDesc]).arr[0]

export const createLogs = logArr => {
  /*const logger = {...{//9NOT USED
    stackInfo: false
  }, ...par}*/
  
  const arr = []
  const obj = {}
  const objPars = {}
    
  logArr.map(([name, style, params]) => {
    //params.logger = logger
    if (name === undef || style === undef || typeof params !== 'object') {
      console.log('Define log function: bad parameters! skip.', name, style, params)
      return
    }
    statik.debugLog && console.log('Define log function: ', name, style, params)
    wassert(name !== undef)
    wassert(style !== undef)
    wassert(params !== undef)
    
    weject(typeof params.realLog !== Ø && params.realLog === undef)
    weject(typeof params.dynRealLog !== Ø && params.dynRealLog === undef)
    
    const copyRealLog = typeof params.realLog === 'string'
    const copyDynRealLog = typeof params.dynRealLog === 'string'
    
    copyRealLog && (params.realLog = obj[params.realLog])//4func
    copyDynRealLog && (params.dynRealLog = objPars[params.dynRealLog].dynRealLog)
    
    params.name = name
    objPars[name] = params//4mert az obj[] csak a log fv-t tarolja, de kell a tobbi param is
    
    obj[name] = (...args) => genLog(params, style, ...args)
    arr.push(obj[name])
    //arr.push(obj[name] = (...args) => genLog(params, style, ...args))
  })
  return {arr, obj}
}

export const inspectAsObject = (obj, pars = {}) => {
  const log = pars.log || console.log
  
  const out = inspectObject(obj, {silent: true, dark: false, ...pars})//9was dark true
  const argsArr = [out.out.join('\n'), ...out.outStyles]
  const collapse = out.out.length > (pars.collapseOnLines || 18)
  collapse && console.groupCollapsed(out.out[0].wipE('%c') + ` ⮞Click to expand! (${out.out.length} lines)`)
  log(...argsArr)
  collapse && console.groupEnd()
}

export const inspectAsTable = (arr, fieldStr, paramsIn = {}) => {
  const defaultPars = {
    align: 'left',
    space: ' ',
    isRec: false,
    trim: true,
    hueHead: 120,
    hueEven: 180,
    hueOdd: 60,
    hueRot: 0,
    maxLines: 100,
    log: console.log
  }
  const tab = {
    ...defaultPars,
    ...paramsIn
  }
  
  isObj(arr) && (arr = arr.toArr())
  
  if (!fieldStr && arr[0]) {
    const fieldArr = []
    arr[0].loopKeys(key => fieldArr.push(tab.space.repeat(min(max(key.length, 8), 32))))
    const tofill = (80 - fieldArr.join(',').length) / fieldArr.length
    const fillPerField = tofill > 0 ? floor(tofill) : 0
    fieldArr.length = 0
    arr[0].loopKeys(key => fieldArr.push(key + ':' + (min(max(key.length, 8), 32) + fillPerField)))
    fieldStr = fieldArr.join(',')
    console.log(`generated fieldStr (${fieldArr.length} fields): `, fieldStr)
  }
  wassert(typeof (fieldStr || '').split === 'function')
  const fields = (fieldStr || '').split(',').map(a => ({orig: a.trim()}))
  fields.map(field => {
    const [prop, len] = field.orig.split(':')//4after second ':' could be type or anything
    field.prop = prop
    field.len = len ? parseInt(len) : prop.length + 1
    if (prop === '#') {
      field.type = 'index'
    } else {
      arr.map(ob => {
        const type = typeof ob[field.prop]
        field.type = field.type || (s_a('number,string,object').includes(type) && type)
      })
      field.type = field.type || 'string'//4final fallback
    }
  })
  
  const format = (variable, type, len) => {
    let str = type === 'object' ? safeStringifyJSON(variable) : variable + ''
    str = (str || '').wipE('"')
    weject(typeof str === Ø)
    if (str.length < len) {
      const filler = tab.space.repeat(len - str.length)
      const half = floor(filler.length / 2 + .9)
      str = tab.align === 'right' 
        ? filler + str 
        : tab.align === 'center'
          ? (filler + str).substr(half) + tab.space.repeat(half)
          : str + filler
    }
    str.length > len && tab.trim && (str = str.substr(0, len))
    
    let style = {
      string: 'color:hsl(20, 55%, 83%);',
      number: 'color:hsl(330, 50%, 65%);',
      object: 'color:hsl(185, 59%, 78%);',
      index: 'color:hsl(124, 51%, 68%);'
    }[type] || ''
    
    variable === undef && (style = 'color:hsl(0,0%,80%);')
    type === 'number' && isNaN(variable) && (style = 'color:hsl(0,0%,90%);')
      
    return {str, style}
  }
  const baseStyle = 'font: 100 10px Hack;padding:2px 1px 2px 2px;margin:0 0 0 0;'
  const header = []
  const body = []
  const styles = []
  let rowCounter = 0
  const headBg = `hsl(${tab.hueHead + tab.hueRot}, 30%, 35%)`
  const oddBg = `hsl(${tab.hueOdd + tab.hueRot}, 25%, 25%)`
  const evenBg = `hsl(${tab.hueEven + tab.hueRot}, 25%, 25%)`
  
  const mapField = (ob, index, line, horb) => {
    const isHeader = horb === 'header'
    const odd = rowCounter % 2
    fields.map(field => {            
      const bgStyle = 'background:' + (isHeader ? headBg : odd ? oddBg : evenBg) + ';'
      const borderCol = '1px solid ' + (isHeader ? 'white;' : '#888;')
      const borderStyle = `border-left:${borderCol} border-right:${borderCol}`
      const {str, style} = isHeader 
        ? format(field.prop, 'string', field.len) 
        : format(field.prop === '#' ? index : ob[field.prop], field.type, field.len)
      const fullStyle = bgStyle + borderStyle + style + (isHeader ? 'color: white' : '')
      
      line.push('%c' + str)
      styles.push(baseStyle + fullStyle + ';' + (tab.style || ''))//4setting style
    })
  }
  //!generate header:
  mapField(undef, '#', header, 'header')
  body.push(header.join(''))
  
  //!generate body:
  arr.slice(0, tab.maxLines).map((ob, index) => {
    rowCounter++ //4for alternating bg
    const line = []
      
    mapField(ob, index, line, 'body')
    body.push(line.join(''))
  })
  //console.log(fields, body, styles)
  styles[0] += ';margin-left: 5px;'
  tab.isRec && tab.log('%%timeStampSkipForNext')
  tab.log(body.join('\n'), ...styles)//9override with widgetrec?
}

const isNum = val => typeof val === 'number'
const isStr = val => typeof val === 'string'
const trimmer = (value, fixed) => fixed === -1
  ? value : isStr(value) ? value.substr(0, fixed) : isNum(value) ? value.toFixed(fixed) : value

export const printObj = (obj, format, {aft = ', ', eq = ': ', log = console.log} = {}) => {
  const parts = format.split('{') // logs.3} -> to + logb}ms
  let out = parts.shift()  
  for (const part of parts) {
    const [prop, noprop] = part.split('}') //logs.3
    const dot = prop.indexOf('.')
    const hash = prop.indexOf('#')
    const propLen = min(dot > 0 ? dot : 999, hash > 0 ? hash : 999)
    const search = prop.substr(0, propLen)
    const fixed = dot > 0 ? parseInt(prop.substr(dot + 1)) : -1
    const propTrim = hash > 0 ? parseInt(prop.substr(hash + 1)) : -1
    const props = []
    for (const property in obj) {
      if (('-' + property + '-').includes(search)) {
        const value = obj[property]
        const propValue = trimmer(value, fixed)
        const propName = trimmer(property, propTrim)
        const prefix = propName.length ? `${propName}${eq}` : ''
        props.push(prefix + propValue)
      }
    }
    out += props.join(aft) + noprop
  }
  log(out)
}

const test = _ => {
  console.groupEnd()

  const testpo = {
    tranq: 1.45353,
    zotran: 2.88654,
    traverse: 45654.22,
    zohan: 'beaucoup'
  }
  
  printObj(testpo, 'trs= {-tra.3#4} -> to {zo.2} grr {ran}')
  
  const [log, elog, slog, clog] = createLog([
    ['log', 'b: #ffe;font-size:14px;', {}],
    ['elog', 'b: #fee', {realLog: 'log'}],
    ['slog', 'c: blue;', {skipPars: 1, realLog: logSkipFirst}],
    ['clog', 'c: green;', {skipPars: 1, realLog: logSkipFirst}]
  ]).arr
  
  log('maci%cfule%ctarka', 'font-weight:900', '')
  elog('%cturbobela%ckalandjai', 'bo: 1px solid black', 'color: red')
  slog(1, 'macilaci')
  clog(2, '%cmici%cmaci', 'color:red', 'bor:1s#5af')
  const obi = {//9 ??
    laci: 4,
    maci: 'macko',
    macarr: [4, 8, 11]
  }
  log(obi)
  //!debugger
}

//test()

console.inspectAsTable = inspectAsTable
