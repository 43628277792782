const actions = {
  TRANSACTION_REQUESTED: "TRANSACTION_REQUESTED",
  TRANSACTION_CONFIRMED: "TRANSACTION_CONFIRMED",
  TRANSACTION_SUCCEEDED: "TRANSACTION_SUCCEEDED",
  TRANSACTION_FAILED: "TRANSACTION_FAILED",
  TRANSACTION_CANCELLED: "TRANSACTION_CANCELLED",
  RESET: "RESET",
  HIGHLIGHT_RECENTLY_MINTED: "HIGHLIGHT_RECENTLY_MINTED",
  CLEAR_RECENTLY_MINTED: "CLEAR_RECENTLY_MINTED",
};

const initialState = {
  status: "READY",
  n: null,
  recentlyMintedTokens: null,
  recentMintHighlightIsActive: false,
};

function reducer(state, action) {
  switch (action.type) {
    case actions.TRANSACTION_REQUESTED:
      return {
        status: "CONFIRMING",
        n: action.n,
        recentlyMintedTokens: null,
        recentMintHighlightIsActive: false,
      };
    case actions.TRANSACTION_CONFIRMED:
      return {
        ...state,
        status: "MINTING",
      };
    case actions.TRANSACTION_SUCCEEDED:
      return {
        ...state,
        status: "SUCCESS",
        recentlyMintedTokens: action.recentlyMintedTokens,
      };
    case actions.TRANSACTION_FAILED:
      return {
        ...state,
        status: "ERROR",
      };
    case actions.TRANSACTION_CANCELLED:
    case actions.RESET:
      return {
        ...state,
        status: "READY",
        n: null,
      };
    case actions.HIGHLIGHT_RECENTLY_MINTED:
      return {
        ...state,
        recentMintHighlightIsActive: true,
      };
    case actions.CLEAR_RECENTLY_MINTED:
      return {
        ...state,
        recentlyMintedTokens: null,
        recentMintHighlightIsActive: false,
      };
    default:
      return state;
  }
}

export { actions, initialState, reducer };
