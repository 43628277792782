import {BrowserRouter as Router} from "react-router-dom";
import {QueryClientProvider} from "react-query";
// import {ReactQueryDevtools} from "react-query/devtools";
import WalletProvider from "./ethWallet/WalletProvider";
import MintProvider from "./mint/MintProvider";
import CalculatorProvider from "./calculator/CalculatorProvider"
import {queryClient} from "./query/queryClient";
import ToastContainer from "./components/Toast/ToastContainer";
import {ToastProvider} from "./context/ToastContext";
import {AdapterProvider} from "./contracts/useCryptoartContract";
import Main from "./Main";

function App() {
  return (
    <AdapterProvider>
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          {/* <ReactQueryDevtools initialIsOpen={false}/> */}
          <Router>
            <ToastProvider>
              <MintProvider>
                <CalculatorProvider>
                  <Main />
                  <ToastContainer/>
                </CalculatorProvider>
              </MintProvider>
            </ToastProvider>
          </Router>
        </WalletProvider>
      </QueryClientProvider>
    </AdapterProvider>
  );
}

export default App;
