/* eslint-disable react/prop-types */

import React from "react"
import {useCryptoartContract} from "../contracts/useCryptoartContract"
import FragmentColorChart from "./FragmentColorChart"

const FragmentSummary = ({ frag }) => { // FIX this should be fragment, not token
  const {hasLeaderboard} = useCryptoartContract()

  const fullness = frag.fullnessFloat > 0 
    ? frag.fullnessFloat.toFixed(3) 
    : 0;
  const score = (frag.score || 0).toFixed(3)
  
  return (
    <table className="_FragmentSummary table-fixed fm-table text-gray-200 text-left">
      <thead>
          <tr>
              <th className="fm-table-header">ID</th>
              <th className="fm-table-header">Properties</th>
              {hasLeaderboard
                ? <th className="fm-table-header">Fullness</th>
                : <th className="fm-table-header">Score</th>
              }
          </tr>
      </thead>
      <tbody>
      <tr key={`fragment_${frag.id}`}>
          <td>
            {frag.id}
          </td>
          <td>
            <FragmentColorChart
              fragmentId={frag.id}
              isReady={frag.isReady}
              squareCount={frag.squareCount}
            />
          </td>
          {hasLeaderboard ? <td>{fullness}</td> : <td>{score}</td>}
        </tr>
      </tbody>
    </table>
  )
}

export default FragmentSummary;
