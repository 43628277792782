/* eslint-disable no-return-assign, spaced-comment, no-unused-expressions, no-console, arrow-body-style,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/prop-types, 
   no-nested-ternary, no-shadow */

import { useEffect, useState } from 'react'
import Title from '../designSystem/Title'
import { contractAdapter } from '../adapter/contractAdapter'
import { useAdapterContext } from '../contracts/useCryptoartContract'
import Spinner from '../designSystem/Spinner'
// import { LiquidityPool, PrizePool } from './LiquidityPool'
// import { LiqPoolChart } from './LiqPoolChart'

const {eth} = contractAdapter.helpers

const Field = ({ name, value }) =>
  <>
    <div className='text-xs text-gray-300'>{name}</div>
    <div className='text-xs font-black text-white text-right'>{value}</div>
  </>

const ClickField = ({ name, value, onClick }) =>
  <>
    <div className='text-xs text-gray-300'>{name}</div>
    <div className='inlink text-xs font-black text-white text-right' onClick={onClick}>{value}</div>
  </>

const onoff = on => on 
  ? <span className='text-green-452'>Available</span>
  : <span className='text-red-600'>Unavailable</span>

const OnChainInfo = ({previewFrag: frag, justSVG=false}) => {
  const [metaUrl, setMetaUrl] = useState('')
  const [src, setSrc] = useState('')
  const [loadingState, setLoadingState] = useState('loading')

  const onMetaClick = () => window.open('', 'metadata')?.document?.write(`<iframe src="${metaUrl}" frameborder="0" style="border:0; inset:0; width: 100vw; height:100%; margin: 0; padding: 0;" allowfullscreen></iframe>`)
  
  useEffect(() => {
    let mounted = true
    setLoadingState('loading')
    contractAdapter.onReady().then(async () => {
      if (frag?.isReady) {
        const metaUrl = await contractAdapter.read.cryMeth.tokenURI(frag.id)
        // console.log({metaUrl})
        if (contractAdapter.hasOnchainMetadata) {
          const response = await fetch(metaUrl)
          const data = await response.json()

          if (mounted) {
            setMetaUrl(metaUrl)
            setSrc(data.image)
            setLoadingState('done')
          }
        }
      }
    })
    return () => mounted = false
  }, [frag])

  return frag
    ? justSVG ?<> <div className='flex justify-center mb-1'><img alt='' src={src} style={{width: '75%'}} /></div></>:
     <div className='_OnChainInfo sidebar-bottom'>
        {loadingState === 'loading'
          ? <div className='spinner-center'><Spinner /></div>
          : <>
              <Title text='On-chain NFT info & 2D SVG' />
              <div className='grid grid-rows grid-cols-2auto gap-y-2 mb-3 p-2 green-border'>
                <ClickField loadingState={loadingState} name={`Token #${frag.id}`} onClick={onMetaClick} value='Open metadata...' />
              </div>
              {/* <div className='grid grid-rows grid-cols-2auto gap-y-2 p-1 green-border'> */}
                <div className='thumb-holder'><img alt='' src={src} style={{width: '100%'}} /></div>
              {/* </div> */}
            </>
        }
      </div>
    : null  
}

const DataV1 = ({ state, liqPoolSum, isMobile }) => 
  <div className='_DataV1 overx-hidden'>
    {/* {console.log('mm', {dropState, controlVarsFlags})} */}
    <Title text='Contract' />
    <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-3 p-2 green-border'>
      <Field name='Total Minted' value={state.mintCnt} />
      <Field name='Total Combined' value={state.combineCnt} />
      <Field name='In Circulation' value={state.circCnt} />
      <Field name='Mint price' value={eth(state.mintingCostETH, 4)} />
      {/* <Field name='Total Supply' value={'5000'} /> */}
    </div>
    {/* <Title text='Pool' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 p-2 green-border'>
      <Field name='Pool Value' value={eth(liqPoolSum, 4)} />
    </div> */}
    {/* {!isMobile && <LiquidityPool />}
    <LiqPoolChart /> */}
  </div>

const DataV2 = ({ stats, controlVarsFlags, dropState, liqPoolSum, isMobile }) => 
  <div className='_DataV2'>
    {/* {console.log('mm', {dropState, controlVarsFlags})} */}
    <Title text='Current Drop' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 mb-3 p-2 green-border'>
      <Field name='Drop Number' value={`${controlVarsFlags.drop}`} />
      <Field name='Mint price' value={eth(dropState.mintingCostETH, 4)} />
      <Field name='Minted' value={`${dropState.mintCnt}`} />
      <Field name='Remaining' value={`${dropState.supply - dropState.mintCnt}`} />
      <Field name='Total Fullness' value={dropState.totalFullness} />
    </div>
    <Title text='Current Drop Operations' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 mb-3 p-2 green-border'>
      <Field name='Minting' value={onoff(controlVarsFlags.mintOn)} />
      <Field name='Combine' value={onoff(controlVarsFlags.combineOn)} />
      <Field name='Rainbow Whale Redeem' value={onoff(controlVarsFlags.genRedeemOn)} />
      <Field name='White Whale Redeem' value={onoff(controlVarsFlags.lbRedeemOn)} />
      <Field name='Contest' value={onoff(controlVarsFlags.contestOn)} />
      <Field name='Overlap' value={onoff(controlVarsFlags.overlapOn)} />
    </div>
    <Title text='Contract' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 mb-3 p-2 green-border'>
      <Field name='Total Minted' value={stats.mintCnt} />
      <Field name='Total Combined' value={stats.combineCnt} />
      {/* <Field name='Total Redeemed' value={stats.redeemCnt} /> */}
      <Field name='In Circulation' value={stats.circCnt} />
    </div>
    <Title text='White Whale Pool' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 p-2 green-border'>
      <Field name='White Whale Pool' value={eth(dropState.totalPrizePoolETH, 4)} />
    </div>
    {/* {!isMobile && <PrizePool />}
    <Title text='Rainbow Whale Pool' />
    <div className='grid grid-rows grid-cols-2auto gap-y-2 p-2 green-border'>
      <Field name='Pool Value' value={eth(liqPoolSum, 4)} />
    </div>
    {!isMobile && <LiquidityPool />} */}
  </div>
    
export const Sidebar = ({previewFrag, isMobile=false, justSVG=false}) => {
  const [stats, setStats] = useState({})
  const [state, setState] = useState({})
  const [controlVarsFlags, setControlVars] = useState({})
  const [dropState, setDropState] = useState({})
  const [liqPoolSum, setLiqPSum] = useState(0)
  const [count, setCount] = useState(0)
  const account = contractAdapter.getSelectedAccount() // fix adapterContext should replace this
  // useWallet ^
  const {adapterState} = useAdapterContext()

  const lazyRefresh = contractAdapter.helpers.debounce(() => setCount(count + 1), 1000)
  contractAdapter.setEventListener('any', lazyRefresh)

  useEffect(() => {
    let mounted = true

    contractAdapter.getLiquidityPoolAbsValues?.(500)
      .then(() => mounted && setLiqPSum(contractAdapter.lastLiqPool.liqPoolSum))

    contractAdapter.hasDrops
      ? Promise.all([
          contractAdapter.getStats?.(),
          contractAdapter.getDropState?.() // will call getControlFlags too
        ])
          .then(() => {
            if (mounted) {
              const {stats, controlVars, controlFlags, dropState} = contractAdapter
              // console.log({_stats, controlFlags, topLists: [], liqPool})
              setStats({...stats})
              setControlVars({...controlFlags, ...controlVars})
              setDropState({...dropState})
            }
          })
      : contractAdapter.getState?.()
          .then(() => mounted && setState({...contractAdapter.state}))

    return () => mounted = false
  }, [account, count, adapterState])

  if (!adapterState) {
    console.log('adapter is not ready yet, sidebar will skip', {adapterState})
    return null
  }

  return (
    <>
    { justSVG ? <OnChainInfo {...{previewFrag,justSVG}} />:
    <div
    className='_Sidebar absolute h-30 sm:h-full md:h-full lg:h-full bg-offblack bg-opacity-80 p-2 z-10 overflow-y-hidden'
    style={{ width: isMobile ? '100%' : '240px', position: isMobile? 'static':'absolute' }}>
      {contractAdapter.v1
        ? <DataV1 {...{ state, liqPoolSum, isMobile }} />
        : <DataV2 {...{ stats, controlVarsFlags, dropState, liqPoolSum, isMobile }} />
      }
      <OnChainInfo {...{previewFrag}} />
    </div>
    }
  </>
)}
