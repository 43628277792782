import {useState} from "react";
import Header from "./app/Header";
import Footer from "./app/Footer";
import {useEagerConnect} from "./ethWallet/useEagerConnect";
import MintFeedbackDialogs from "./mint/MintFeedbackDialogs";
import Routes from "./Routes";
import {isMobile} from './utils/isMobile'
import { contractAdapter } from './adapter/contractAdapter'

function Main () {
  const eagerConnectionAttempted = useEagerConnect();

  const [count, setCount] = useState(0)
  contractAdapter.forceAppRerendering = () => {
    console.log('force redraw', count)
    setCount(count + 1)
  }

  // Checking the wallet connection status doesn't take long: the entire process happens
  // locally. Not long enough to need a spinner, but async nonetheless. To play it safe,
  // the UI isn't rendered until the auto-connect attempt has been made.
  if (!eagerConnectionAttempted) {
    return null
  }

  return (
    <div className='flex flex-col bg-offblack font-mono text-white min-h-screen max-h-full max-w-full'>
      <Header />
      <Routes />
      {isMobile() || <Footer />}
      <MintFeedbackDialogs />
    </div>
  )
}

export default Main
