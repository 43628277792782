import { useContext } from "react";
import CalculatorContext from "./CalculatorContext";

function useCalculator() {
  const context = useContext(CalculatorContext);

  if (typeof context === "undefined") {
    throw new Error("useCalculator() must be used within a CalculatorProvider")
  }

  return context;
}

export { useCalculator };
