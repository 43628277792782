import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import Spinner from "./Spinner";

const Button = forwardRef(({ children, className, loading, px, ...props }, ref) => (
  <button
    ref={ref}
    className={clsx(
      "_Button fm-button fm-gray-button", 
      className
    )}
    disabled={loading}
    type="button"
    {...props}
  >
    <span className={loading ? "opacity-0" : null}>{children}</span>
    {loading ? (
      <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </span>
    ) : null}
  </button>
));

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  px: PropTypes.string
};

Button.defaultProps = {
  className: null,
  loading: false,
  px: "10"
};

export default Button;
