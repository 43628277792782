import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const IconButton = forwardRef(({ className, ...props }, ref) => (
  <button
    ref={ref}
    className={clsx(
      "fm-icon-button",
      className
    )}
    type="button"
    {...props}
  />
));

IconButton.propTypes = {
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: null,
};

export default IconButton;
