/* eslint-env browser, node, worker */
/* eslint-disable no-shadow, consistent-return, import/extensions */
/* eslpint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes, no-void,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */


// import { ethers } from 'hardhat'

import {Corelib, Logre} from './improxy-red.js'

const {inspectAsObject} = Logre
const {wassert, weject} = Corelib.Debug

//const logOn = false
void weject

export const extendContractAdapterWithOps = ({adapter}) => {
  const {
    isReadContractValid, isWriteContractValid, pureSolid, big2eth, log, clog, rlog, eth,BigNumber
  } = adapter.helpers


  adapter.withdraw = async() =>{
  const contractBalance = adapter.getContractBalance()
  const result = await adapter.write.cryMeth.withdraw(contractBalance);
  if(result.wasOk){
    result.okMessage="withdraw successfully"
  }
  return result;
  }

  adapter.changeMintState = async() =>{
  const result = await adapter.write.cryMeth.changeMintState();
  if(result.wasOk){
    result.okMessage="Mint state changed successfully"
  }
  return result;
  }
  
  adapter.SetAnimationUrl = async(url) =>{
  await adapter.setConfig(url)
  }
 
  // TODO ____silent: to every op

  // Transactions can be: mint / combine / redeem (both)

  // PUB mint() _________________

  adapter.mint = async (account, amount, params = {}) => {
    if (!isWriteContractValid()) {
      return adapter.creatResultFromError({err: 'No contract.'})
    }
    console.log(adapter.write)
    const {onConfirm, onSuccess, onError, silent = false} = params

    // rem Merkle proof

    const proof = adapter.hasMerkle && adapter.controlFlags.whitelistOn
      ? await (await fetch(`${adapter.merkleApiUrl}proof/${account}`)).json().catch(console.error) || []
      : []
      
   
    silent || clog(`mint(${amount})`)

   
    const mintingCost = adapter.getMintingCost()
    clog(`price :`, mintingCost)
    const value = mintingCost.mul(amount)
    const parray = adapter.hasMerkle ? [account, amount, proof] : [account, amount]
  
    // pre-flight
    const estResult = await adapter.estGasForTx('mint', [...parray, {value}], {over: 120})
    clog(`estResult :`,estResult)
    if (!estResult.wasOk) {
      onError?.(estResult)
      return estResult
    }
    const gasLimit = estResult.gasEstimate

    const result = await adapter.write.cryMeth.mint(...parray, {value, gasLimit})
      .then(async transaction => {
        onConfirm?.()
        const tx2 = await adapter.ethersProvider.getTransaction(transaction.hash)
        console.log('miiiiiiiiiiiiii', {transaction, tx2})
        const result = await adapter.txPostProcessor(transaction, {type: 'mint', hueRot: 30})
        if (result.wasError) {
          onError?.(result)
        } else { // extend results with newTokenIds and call callbacks
          const transferEvents = result.events.filter(e => e.event === 'Transfer') // ???
          const newTokenIds = transferEvents.map(e => e.args.tokenId)
          onSuccess?.(newTokenIds)
          silent || rlog(`Mint transaction was successful (${amount} frags).`)

          //// adapter.updateLiquidityPoolCache()
          // adapter.onFragChangeSidebar?.('mint')        // fix deprecated
          // adapter.onFragChangeLiquidityPool?.('mint')  // fix deprecated

          result.response = {newTokenIds}
          result.okMessage = `${amount} fragments minted.`
        }
        return result
      })
      .catch(err => {
        console.warn('AFTER8', err)
        console.warn('AFTER8', {...err})
        const result = adapter.createResultFromError({err, extra: {amount, op: 'mint'}})
        onError?.(result)
        return  result
      })

    // error or not  ...

    //log('After8', result)

    result.op = 'mint'
    result.request = {amount}
    adapter.smartLog(result)
    return result  
  }

  // PUB combine() ____________________________

  /*
    function calcLeaderboardRedeem(uint32 id) public view returns (uint256 leaderboardValue) {
      Fragment storage frag = fragments[id];
      DropState storage dropState = dropStates[frag.drop];
      uint32 fullness = calcCombinedFullness(unpackCP(frag.packedSquareCount));
      leaderboardValue = dropState.zk * fullness; //  * CUBE_LCD;
      //console.log('calcPrize', leaderboardValue, dropState.zk, frag.fullness);
      //console.log('calcPrize nozill', dropState.zk * frag.fullness);
    }

  function modifyLiqPoolForGeneralRedeem(uint32 id) internal returns(uint256 redeemValue) {
    uint32[7] memory pool = calcCurrentPoolMods(id);
    uint32 a;

    for (uint ix = 1; ix < 7; ix++) {
      a = pool[ix];
      liquidityPool[ix] -= a; // can be negative? should we check here? should be int?
      redeemValue += a;
    }
    liquidityPool[0] -= uint32(redeemValue);
    redeemValue *= Twei;
    emit Changed('LP'); // +50b, optional
  }
  */

  adapter.combine = async (id1, id2, {onSuccess, onError} = {}) => {
    void onSuccess
    clog(`Combine(${id1}, ${id2})`)
    if (!isWriteContractValid()) {
      return adapter.creatResultFromError({err: 'No contract.'})
    }
    const frag1 = await adapter.cache.checkToken(id1) // frags.getTokenById!!!!!
    const frag2 = await adapter.cache.checkToken(id2)
    if (!frag1.isReady || !frag2.isReady) {
      console.error('TRBL: not ready', {id1, id2, frag1, frag2}) // ui should prevent this -> int err
      //debugger
    }
    const preview = adapter.combinePreview(frag1, frag2)
    if(preview==-1){
      console.error('Err : Has overlaps', {id1, id2, frag1, frag2}) // ui should prevent this -> int err
    } else {

    // pre-flight
    const estResult = await adapter.estGasForTx('combine', [id1, id2], {over: 120})
    if (!estResult.wasOk) {
      onError?.(estResult)
      return estResult
    }
    const gasLimit = estResult.gasEstimate

    return adapter.write.cryMeth.combine(id1, id2, {gasLimit})
      .then(async transaction => {
        //console.log({transaction})
        const result = await adapter.txPostProcessor(transaction, {type: 'combine', hueRot: 320})
        if (result.wasOk) {
          adapter.cache.invalidateTokens([id1, id2])
        }
        result.op = 'combine'
        result.request = {id1, id2}
        const ids = result.returned?.ids || ['?', '?']
        result.response = result.wasError ? {} : {ids}
        result.okMessage = `Fragment ${ids[0]} combined with ${ids[1]}.`
        console.groupCollapsed('🦄🦄🦄COMBINE')
        console.log({preview, result})
        console.groupEnd()
        adapter.smartLog(result)
        return result
      })
      .catch(err => adapter.createResultFromError({err, extra: {id1, id2, op: 'comb'}}))
  }}

  // PUB redeem() ____________________________

  adapter.redeemPrediction = async (id, {useLbValue = false, silent = true} = {}) => {
    if (!isReadContractValid()) {
      return adapter.createResultFromError({err: 'No read contract in redeemPrediction.'})
    }
    const plog = silent ? () => {} : log

    const frag = await adapter.cache.checkToken(id)
    const prediction = {
      leaderboard: {},
      general: {},
      frag
    }

    if (useLbValue) {
      wassert(adapter.hasDrops)
      const _prize = await adapter.read.cryMeth.calcLeaderboardRedeem(id)
      const prize = big2eth(_prize)
      log(`lbRedeem(${id}) contract: ${prize} adapter/frag: ${frag.leaderboardValue}`)
      prediction.leaderboard.contractValue = prize
      prediction.leaderboard.adapterValue = frag.leaderboardValue
    } else {
      await adapter.getLiquidityPoolState() // general redeem, liquidity pool must be updated first

      prediction.general.adapter = await adapter.js_calcCurrentPoolMods(frag)
      // prediction.general.contract =  adapter.contract_calcCurrentPoolMods(frag)
      const adapterValue = prediction.general.adapter.poolSum
      const contractValue = 0
      // const contractValue = prediction.general.contract.poolSum

      log(`genRedeem(${id}) contract: ${contractValue} adapter: ${adapterValue} frag: ${frag.redeemValue}`)
      prediction.general.fragValue = frag.redeemValue
      prediction.general.diffCA = contractValue - frag.redeemValue
      prediction.general.diffSync = adapterValue - frag.redeemValue
      if (Math.abs(prediction.general.diffCA) > .001) {
        console.warn('genRedeem (contract vs adapter) diff > .001', prediction.general)
      }
      if (Math.abs(prediction.general.diffSync) > .001) {
        console.warn('genRedeem (adapter vs frag sync) diff > .001', prediction.general)
      }
    }
    plog('redeemPrediction', {prediction})

    return prediction
  }

  adapter.redeem = async (id, pars = {}) => {
    const {useLbValue = false, useOwner = false, showPrediction = false, onSuccess, onError} = pars
    void onSuccess
    void onError
    clog(`Redeem(${id}) LbV=${useLbValue}`)
    showPrediction && adapter.redeemPrediction(id, {useLbValue, silent: false})

    const contractObj = useOwner? adapter.owner : adapter.write

    if (!isReadContractValid() || !isWriteContractValid()) {
      return adapter.createResultFromError({err: 'No contract.'})
    }
    const frag = await adapter.cache.checkToken(id)

    if (!frag?.isReady) {
      log(`Redeem(${id}) no such id or not ready!`)
      return 0 // fix error!!!
    }
   
    const parray = adapter.hasDrops ? [id, useLbValue] : [id]
    
    const estResult = await adapter.estGasForTx('redeem', parray, {over: 120}) // pre-flight
    if (!estResult.wasOk) {
      onError?.(estResult)
      return estResult
    }
    const gasLimit = estResult.gasEstimate
    // const gasLimit = adapter.ethers.BigNumber.from(29E6)
    const transaction = await contractObj.cryMeth.redeem(...parray, {gasLimit})
      .catch(err => {
        console.log({...err})
        debugger
      })

    // and now calc it's own
    let fragmentValue = 0
    
    const result = await adapter.txPostProcessor(transaction, {type: 'redeem', hueRot: 80})
      .catch(err => adapter.createResultFromError({err, extra: {id, useLbValue, op: 'redeem'}}))

    if (result.wasOk) {
      result.info = { // extra data for cryptoVerse
        leaderboardValue: frag.leaderboardValue,
        adapterRedeemValue: frag.redeemValue,
        squareCount: `[${frag.squareCount?.join(', ')}]`,
        // squareCount: [...frag.squareCount],
        // poolObj: adapter.js_calcCurrentPoolMods(frag),
        liqPoolObj: adapter.lastLiqPool.deepCopy()
      }
      adapter.cache.invalidateTokens([id])
      fragmentValue = result.returned.valETH
      //////// chk adapter.updateLiquidityPoolCache()
      // adapter.onFragChangeSidebar?.('redeem')
      // adapter.onFragChangeLiquidityPool?.('redeem')
    }
    result.fragmentValue = fragmentValue
    rlog(`Redeem(${id}) awaited, value:`, {fragmentValue, result})
    try {
      const res = JSON.parse(JSON.stringify(result))
      for (const event of res.events || []) {
        event.args = pureSolid(event.args)
      }
      if (adapter.hasDrops) {
        res.frozenDropState.topList = res.frozenDropState.topList
          .map(item => `{id: ${item.id}, fullness: ${item.fullness}}`)
      }

      console.groupCollapsed('🦄🦄🦄REDEEM')
      console.log('Redeem done.', {result})
      console.groupEnd()

      inspectAsObject(res, {dark: true, name: '🔥🔥Result from Redeem🔥🔥'})
    } catch (err) {
      console.warn(`redeem inspectAsObject`, err)
    }
    // CHK set error if 0?

    result.op = 'redeem'
    result.request = {id, useLbValue}
    result.response = {fragmentValue}
    result.okMessage = `Fragment ${id} ${useLbValue ? 'drop' : 'gen'} redeemed for ${eth(fragmentValue, 6)}`
    adapter.smartLog(result)

    return result
  }
}
