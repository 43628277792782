import React from 'react'
import { RandomCube, CubeWrapper,CubeMainWrapperHomePage,CubeMainWrapperCombinations ,CubeMainWrapper} from '../components/CubeComponents'
import {isMobile} from '../utils/isMobile'
import {Corelib} from '../adapter/improxy-red'
import MintButton from "../mint/MintButton";
import FragmentColorChart from '../wallet/FragmentColorChart'
import {ReactComponent as Homesvg} from '../icons/Homesvg.svg'


export const ZILLION = 9 * 16 * 25 * 36 * 49 * 64
const tileLimits = [9, 16, 25, 36, 49, 64]
const z6 = ZILLION * 6
const calcFullness = squareCount => {
  let fullness = 0
  let fullFaces = 0
  for (let ix = 0; ix < 6; ix++) {
    fullness += squareCount[ix] * ZILLION / tileLimits[ix]
    if (squareCount[ix] === tileLimits[ix]) {
      fullFaces++
    }
  }
  return fullFaces === 6 ? 6 : (fullness / z6 + fullFaces)
} 

const {getRnd} = Corelib
const Config1 = [5, 8, 15, 20, 20, 32].map(x => getRnd(0, x))
const Config2 = [4, 8, 10, 16, 29, 32].map(x => getRnd(0, x))
const Config3 = [Config1[0]+Config2[0], Config1[1]+Config2[1], Config1[2]+Config2[2],
                 Config1[3]+Config2[3], Config1[4]+Config2[4], Config1[5]+Config2[5]]
const Config4 = [4, 10, 9, 13, 17, 17]
const LandingPage = () =>{
 

  return(
  isMobile()? 
  <div className='flex flex-grow flex-col px-5'>
    <div className=' Static flex flex-grow flex-row h-full'>
       <div className="p-3 z-10 m-3 w-full" >
            <h1 className="fm-terminal-font text-2xl font-bold text-green-452 my-6" style={{marginTop:"15%"}}>WHAT IS FRAGMENTS?</h1>
           
            <CubeMainWrapper
              key={`Main1`}
              frag1Config={Config1}
            /> 
          
            <div className="text-xl text-gray-200 mb-3 ">
              <p className="mb-5">
              Fragments is a gamified generative art project by
              <a href='https://www.verticalcrypto.art/' target="_blank" rel="noopener noreferrer"
              className='cursor-pointer pl-1'> 
              <u>VerticalCrypto Art</u></a>.
              </p> 
              <p className="mb-5">
              A collection of dynamic NFTs, Fragments are partial cubes with mathematical attributes embedded in their metadata.
              </p>
              <p className="mb-5">
              Based on these attributes, Fragments can be combined with others to complete sides or a full cube, creating unique generative art. 
              </p>
            </div>
            <h1 className="fm-terminal-font text-2xl font-bold text-green-452 my-6" style={{marginTop:"20%"}}>Why Fragments?</h1>

           

            <div className="text-xl text-gray-200 mb-3">
              <p className="mb-5">
              An innovative and challenging new concept  upgradable, dynamic NFTs.
              </p> 
              <p className="mb-5">
              Each Fragment is assigned a numerical weight. To combine two Fragments, the value of both must be within the limitations of the entire cube.
              </p>
              <p className="mb-5">
              Players skilled enough to complete full sides and cubes of their Fragments will be featured on the leaderboard and can unlock achievements and rewards.
              </p>
            </div>
            <div className='flex flex-col flex-grow justify-items-center text-center' style={{marginTop:"10%",marginLeft:"14%"}}>
              <p className='text-green-450 font-bold mb-6 -ml-3'>2D Model of Fragment</p>
              <Homesvg width={"90%"} />
            </div>


            <h1 className="fm-terminal-font text-2xl font-bold text-green-452 my-6" style={{marginTop:"20%"}}>Combining Fragments?</h1>
            <div className="text-xl text-gray-200 mb-3">
              <p className="mb-5">
              Each Fragment is an incomplete cube. By combining two Fragments with the correct mathematical properties players can complete, or fill sides of the cube. Once combined, a Fragment can be added to complete faces until a full cube is achieved, as long as the mathematical value of the Fragment allows. 
              </p> 
              <p className="mb-5">
              The original two Fragments, now one can be combined again, if the mathematical value allows, to complete more of the cube, enhancing the score of the cube and the depth of the art.
              </p>
              <p className="mb-5">
              Every combination of two Fragments opens new possibilities, while closing others. As the mathematical value of the Fragment changes with combinations, finding the correct combination becomes more difficult.
              </p>
              <p className="mb-5">
              The more complete the Fragment, the higher the Fragments score. The first players to complete sides will be rewarded. The first player to complete a cube will be a legend.
              </p>
            </div>
            <h1 className="fm-terminal-font text-2xl font-bold text-green-452 my-6" style={{marginTop:"20%"}}>How is the art generated ?</h1>

            <CubeMainWrapper
              key={`Mainart`}
              frag1Config={Config1}
              isCombined={true}
              previewCube={false}
              hideControlsNot
              /> 

            <div className="text-xl text-gray-200 mb-3 w-auto">
              <p className="mb-5">
               The same algorithm that gives the weighted value to each Fragment holds the encoded DNA of its generative art.
              </p> 
              <p className="mb-5">
               Each Fragment being unique, its expression in combination with another Fragment will differ from any other, creating a 3 dimensional gen art piece.
              </p>
            </div>
        </div>
     </div>
  </div>
  :
  <div className='flex flex-grow flex-col px-10'>
    <div className=' Static flex flex-grow flex-row h-full justify-items-end ' style={{height:"80vh"}}>
       <div className="h-30 sm:h-full md:h-full lg:h-full p-3 z-10 m-3 w-full sm:w-1/2 md:w-1/2 lg:w-1/2 " >
        <div style={{marginLeft:"25%"}}>
            <h1 className="fm-terminal-font text-3xl font-bold text-green-452 my-6" style={{marginTop:"25%"}}>WHAT IS FRAGMENTS?</h1>
            <div className="text-xl text-gray-200 mb-3 max-w-md">
              <p className="mb-5">
              Fragments is a gamified generative art project by
              <a href='https://www.verticalcrypto.art/' target="_blank" rel="noopener noreferrer"
              className='cursor-pointer pl-1'> 
              <u>VerticalCrypto Art</u></a>.
              </p> 
              <p className="mb-5">
              A collection of dynamic NFTs, Fragments are partial cubes with mathematical attributes embedded in their metadata.
              </p>
              <p className="mb-5">
              Based on these attributes, Fragments can be combined with others to complete sides or a full cube, creating unique generative art. 
              </p>
            </div>
          </div>
      </div>
      <CubeWrapper>
        <RandomCube backgroundColor="#111111" />
      </CubeWrapper>
    </div>
    {/* <div className='flex flex-grow flex-row text-center justify-center mt-3 '>
      {/* <a className='fm-green-button py-3 px-6 text-4xl font-bold' href='/mint'>MINT FRAGMENTS</a> 
      <MintButton homepageCta={true}/>
    </div> */}

    <div className='fixed flex flex-grow flex-row text-green-450 text-center justify-center bottom-4'style={{left:"46%"}}>
    <p className='pl-3 text-green-450 font-bold text-xl'>SCROLL DOWN</p>
    </div>

    <div className='flex flex-grow flex-row-reverse' style={{height:"80vh"}}>
      <div className="h-30 sm:h-full md:h-full lg:h-full p-3 z-10 m-3 w-full sm:w-1/2 md:w-1/2 lg:w-1/2"  >
        <div style={{marginLeft:"15%"}}>
       <h1 className="fm-terminal-font text-3xl font-bold text-green-452 my-6" style={{marginTop:"20%"}}>Why Fragments?</h1>
            <div className="text-xl text-gray-200 mb-3 w-auto" style={{maxWidth: '80%'}}>
              <p className="mb-5">
              An innovative and challenging new concept  upgradable, dynamic NFTs.
              </p> 
              <p className="mb-5">
              Each Fragment is assigned a numerical weight. To combine two Fragments, the value of both must be within the limitations of the entire cube.
              </p>
              <p className="mb-5">
              Players skilled enough to complete full sides and cubes of their Fragments will be featured on the leaderboard and can unlock achievements and rewards.
              </p>
            </div>
        </div>
      </div>
     
      <div className='flex flex-col flex-grow justify-items-center text-center' style={{marginTop:"10%",marginLeft:"14%"}}>
        <p className='text-green-450 font-bold mb-6 -ml-3'>2D Model of Fragment</p>
        <Homesvg width={"90%"} />
        <div className='mt-4 -ml-6 mr-6'>
        <FragmentColorChart fragmentId='4' isReady={true} squareCount={Config4}/>
        </div>
      </div>
    
     
     
    </div>

    <div className='flex flex-grow flex-col' style={{height:"110vh"}}>
      <h1 className="fm-terminal-font text-3xl font-bold text-green-452 my-6 pl-6 ml-10 z-10 ">Combining Fragments?</h1>
      <div className=' Static flex flex-grow flex-row m-5 ' style={{height:"28%"}} >
        <div className='flex flex-col flex-grow text-center'>
        <CubeWrapper >
        <p className='text-green-450'>Score : {calcFullness(Config1).toFixed(3)}</p>
        <CubeMainWrapperCombinations
                key={`Main1`}
                frag1Config={Config1}
                isCombined={false}
                previewCube={false}
                hideControlsNot /> 
        </CubeWrapper>
        <div className=' justify-items-center mx-6 mb-3 px-6'>
        <FragmentColorChart fragmentId='1' isReady={true} squareCount={Config1}/>
        </div>
        </div>
        <div className="_CubeWrapper overflow-hidden">
          <div className="_CubeChildrenWrapper w-1/2 h-full relative justify-center" style={{top:"45%"}}>
          <h1 className="fm-terminal-font text-6xl font-black text-green-452">+</h1>
          </div>
        </div>
        <div className='flex flex-col flex-grow text-center'>
        <CubeWrapper>
        <p className='text-green-450'>Score : {calcFullness(Config2).toFixed(3)}</p>
        <CubeMainWrapperCombinations
                key={`Main2`}
                frag1Config={Config2}
                isCombined={false}
                previewCube={false}
                hideControlsNot /> 
        </CubeWrapper>
        <div className=' justify-items-center mx-6 mb-3 px-6'>
        <FragmentColorChart isReady={true} squareCount={Config2}/>
        </div>
        </div>
        <div className="_CubeWrapper overflow-hidden">
          <div className="_CubeChildrenWrapper w-1/2 h-full relative justify-center" style={{top:"45%"}}>
          <h1 className="fm-terminal-font text-6xl font-black text-green-452">=</h1>
          </div>
        </div>
        <div className='flex flex-col flex-grow text-center'>
        <CubeWrapper>
        <p className='text-green-450'>Score : {calcFullness(Config3).toFixed(3)}</p>
        <CubeMainWrapperCombinations
                key={`Main3`}
                frag1Config={Config3}
                isCombined={false}
                previewCube={false}
                hideControlsNot /> 
        </CubeWrapper>
        <div className=' justify-items-center mx-6 mb-3 px-6'>
        <FragmentColorChart isReady={true} squareCount={Config3}/>
        </div>
        </div>
      </div>
      <div className=' Static flex flex-grow flex-row mx-6'  >
        <div className='flex flex-row'>
          <div className=" text-xl text-gray-200 w-auto px-6 mx-6" style={{maxWidth: '50%'}}>
                <p className="mb-5 pl-3">
                  Each Fragment is an incomplete cube. By combining two Fragments with the correct mathematical properties players can complete, or fill sides of the cube. Once combined, a Fragment can be added to complete faces until a full cube is achieved, as long as the mathematical value of the Fragment allows. 
                </p> 
                <p className="mb-5 pl-3">
                 The original two Fragments, now one can be combined again, if the mathematical value allows, to complete more of the cube, enhancing the score of the cube and the depth of the art.
                </p>
          </div>

          <div className=" text-xl text-gray-200 w-auto px-6 mx-6" style={{maxWidth: 'fit-content'}}>
                  <p className="mb-5 pl-3">
                    Every combination of two Fragments opens new possibilities, while closing others. As the mathematical value of the Fragment changes with combinations, finding the correct combination becomes more difficult.
                  </p>
                  <p className="mb-5 pl-3">
                  The more complete the Fragment, the higher the Fragments score. The first players to complete sides will be rewarded. The first player to complete a cube will be a legend.
                  </p>
          </div>
      </div>
      </div>
    </div>

    <div className=' Static flex flex-grow flex-row-reverse h-full' style={{height:"90vh"}} >
       <div className="h-30 sm:h-full md:h-full lg:h-full p-3 z-10 m-3 w-full sm:w-1/2 md:w-1/2 lg:w-1/2"  >
       <div style={{marginLeft:"20%"}}>
            <h1 className="fm-terminal-font text-3xl font-bold text-green-452 my-6" style={{marginTop:"20%"}}>How is the art generated ?</h1>
            <div className="text-xl text-gray-200 mb-3 w-auto" style={{maxWidth: '80%'}}>
              <p className="mb-5">
               The same algorithm that gives the weighted value to each Fragment holds the encoded DNA of its generative art.
              </p> 
              <p className="mb-5">
              Each Fragment being unique, its expression in combination with another Fragment will differ from any other, creating a 3 dimensional gen art piece.
              </p>
             
            </div>
            </div>
        </div>
      <CubeWrapper>
      <CubeMainWrapperHomePage
              key={`Mainart`}
              frag1Config={Config1}
              isCombined={true}
              previewCube={false}
              hideControlsNot
              disableZoom={true} /> 
      </CubeWrapper>
    </div>
  </div>
 )
}
export default LandingPage
