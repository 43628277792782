import Page from "../designSystem/Page";
import Link from "../designSystem/Link";

export const TosLink = () => 
  <a href='/tos' style={{textDecoration: 'normal', color: '#fff'}} target='_tos' >Terms and Conditions</a>

export const TosPage = () => 
  <Page>
    <div className="space-y-8 pb-12 px-12">
      <h2 className="text-4xl font-bold">Terms and Conditions</h2>
      <div className="tos">
        
        <div level='1' mead='1'>Introduction</div>
        <div level='2' mead='a'>“Fragments NFT” is a distributed application that runs on the Ethereum network, using uniquely coded smart contracts (each, a “Smart Contract”) that allow users to mint and combine the unique digital NFTs known as Fragments (hereafter “Fragments”) located at fragmentsnft.xyz (the “Site”). The Smart Contracts and the Site are collectively referred to in these T&C as the “App”. Using the App, users can view their NFTs, and use the Smart Contracts to mint and combine their NFTs on the Ethereum network.</div>

        <div level='2' mead='b'>VRTCL LTD (“VerticalCrypto Art”,”VCA”,“The team”, “we”, or “us”) is the party making the App available for you to use. Before using the App, the Smart Contracts, or the Site, you must agree to these T&C and any other terms and conditions incorporated or referenced herein (the T&C and any other terms and conditions incorporated or referenced herein are collectively referred to as the “Terms”). PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP, THE SMART CONTRACTS, OR THE SITE. BY USING THE APP, THE SMART CONTRACTS, THE SITE, OR ANY PART THEREOF, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THE TERMS CONTAINED HEREIN. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN THAT COMPANY OR LEGAL ENTITY. ALL TRANSACTIONS INITIATED THROUGH THE APP ARE FACILITATED AND RUN BY THIRD-PARTY ELECTRONIC WALLETS ON THE ETHEREUM BLOCKCHAIN, AND BY USING THE APP YOU AGREE THAT YOU ARE GOVERNED BY THE TERMS OF SERVICE FOR THOSE APPLICABLE WALLETS.</div>

        <div level='2' mead='c'>THE APP</div>
        <div level='3' mead='i'>To use the App, you will need to have a web browser and an Ethereum wallet that is compatible with the Non-Fungible Token (NFT) standard on the Ethereum network.</div>
        <div level='3' mead='ii'>All transactions regarding Fragments are managed and confirmed via the Ethereum blockchain. You understand that your Ethereum public address may be made publicly visible whenever you engage in a transaction.</div>
        <div level='3' mead='iii'>We do not own nor control your web browser, your Ethereum wallet, the Ethereum network, or any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to perform a transaction with your NFTs. We are not liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.</div>
        <div level='3' mead='iv'>The App may show certain data that has been loaded and collected from the Ethereum blockchain. We are not liable for any incorrect information or typographical errors of any kind, including any incorrect information that is due to a coding error, blockchain code errors or due to a data outage. It is your duty and obligation to confirm any and all data on your own and base any decisions you make on your own information and data.</div>
        <div level='3' mead='v'>Any calculations that are displayed on the App are for information purposes only. These calculations are in no way predictions of value and you agree that you will not construe these calculations as predictions of future prices or values in any way or that we are giving you any advice on what to do, purchase or not to purchase. All such decisions are made at your sole discretion without reliance on any information provided on the App.</div>
        <div level='3' mead='vi'>We are not responsible or liable for any failed transactions, out of gas transactions or cancelled transactions.</div>
        <div level='3' mead='vii'>You agree that you will only use the App to the extent you are not in violation of any local laws or regulations.</div>		

        <div level='2' mead='d'>Fragments.</div> 
        <div level='3' mead='i'>Fragments are intangible digital assets that exist only by virtue of the ownership record maintained in the ethereum network</div> 
        <div level='3' mead='ii'>By connecting your Ethereum wallet and minting a NFT with our smart contract, you gain full and complete ownership of your NFT</div>
        <div level='3' mead='iii'>Fragments are digital art collectibles and all sales are final.</div> 
        <div level='3' mead='iv'>Fragments serve exclusively as Non-Fungible Tokens. They are not intended for any investment purposes. We make absolutely no promise or guarantee that these NFTs will hold any particular value once minted.</div>

        <div level='1' mead='2'>Disclaimers.</div>
        <div level='2' mead='a'>You expressly understand and agree that your access to and use of the app is at your sole risk, and that the App is provided “as is” and “as available” without warranties of any kind, whether express or implied. to the fullest extent permissible pursuant to applicable law, the Team, affiliates, and licensors make no express warranties and hereby disclaim all implied warranties regarding the app and any part of it (including, without limitation, the site, any smart contract, or any external websites), including the implied warranties of merchantability, fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability. Without limiting the generality of the foregoing, the Team, affiliates, and licensors do not represent or warrant to you that:</div>
        <div level='3' mead='i'>your access to or use of the App will meet your requirements,</div>
        <div level='3' mead='ii'>your access to or use of the App will be uninterrupted, timely, secure or free from error (including any blockchain code errors or exploits),</div>
        <div level='3' mead='iii'>usage data provided through the App will be accurate,</div>
        <div level='3' mead='iv'>the App or any content, services, or features made available on or through the app are free of viruses or other harmful components, or</div>
        <div level='3' mead='v'>that any data that you disclose when you use the App will be secure. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to you.</div>
        <div level='2' mead='b'>You accept the inherent security risks of providing information and dealing online over the internet, and agree that the team has no liability or responsibility for any breach of security</div>
        <div level='2' mead='c'>The Team will not be responsible or liable to you for any losses you incur as the result of your use of the ethereum network or any ethereum wallet or other electronic wallet, including but not limited to any losses, damages or claims arising from:</div>
        <div level='3' mead='i'>user error, such as forgotten passwords or incorrectly construed smart contracts or other transactions;</div>
        <div level='3' mead='ii'>server failure or data loss;</div>
        <div level='3' mead='iii'>corrupted wallet files; or</div>
        <div level='3' mead='iv'>unauthorised access or activities by third parties, including but not limited to the use of viruses, phishing, bruteforcing or other means of attack or exploits against the App, ethereum network, or any ethereum wallet or other electronic wallet.</div>
        <div level='2' mead='d'>Fragments are intangible digital assets that exist only by virtue of the ownership record maintained in the ethereum network. all smart contracts are conducted and occur on the decentralised ledger within the ethereum platform. We have no control over and make no guarantees or promises with respect to smart contracts.</div>
        <div level='2' mead='e'>The team is not responsible for losses due to blockchains or any other features of the ethereum network or any ethereum wallet or other electronic wallet, including but not limite to late report by developers or representatives (or no report at all) of any issues with the blockchain supporting the ethereum network, including forks, technical node issues, or any other issues having fund losses as a result.</div>


        <div level='1' mead='3'>No Professional Advice or Fiduciary Duties</div>
        <div level='2' mead='a'>All information provided in connection with your access and use of the App is for informational purposes only and should not be construed as professional advice. You should not take, or refrain from taking, any action based on any information contained in the Interface or any other information that we make available at any time, including, without limitation, blog posts, articles, links to third-party content, news feeds, tutorials, tweets and videos. Before you make any financial, legal, or other decisions involving the Interface, you should seek independent professional advice from an individual who is licensed and qualified in the area for which such advice would be appropriate.</div>
        <div level='2' mead='b'>The Terms are not intended to, and do not, create or impose any fiduciary duties on us. To the fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities to you or any other party, and that to the extent any such duties or liabilities may exist at law or in equity, those duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further agree that the only duties and obligations that we owe you are those set forth expressly in the Terms.</div>

        <div level='1' mead='4'>Limitation of Liability.</div>
        <div level='2' mead='a'>You understand and agree that the Team, the Team subsidiaries, affiliates, and licensors will not be liable to you or to any third party for any indirect, incidental, special, consequential, or exemplary damages which you may incur, howsoever caused and under any theory of liability, including, without limitation, any loss of profits (whether incurred directly or indirectly), loss of goodwill or business reputation, loss of data, cost of procurement of substitute goods or services, or any other intangible loss, even if the Team has been advised of the possibility of such damages.</div>
        <div level='2' mead='b'>You acknowledge and agree that the Team has made the App available to you and entered into these terms in reliance upon the warranty disclaimers and limitations of liability set forth herein, which reflect a reasonable and fair allocation of risk between the parties and form an essential basis of the bargain between us. The Team would not be able to provide the app to you without these limitations.</div>
        <div level='2' mead='c'>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, and some jurisdictions also limit disclaimers or limitations of liability for personal injury from consumer products, so the above limitations may not apply to personal injury claims.</div>
        <div level='2' mead='d'>You agree to not hold the Team accountable for any losses you may incur as a consequence of minting, combining or redeeming your NFT. These potential losses include any gas fees for failed transactions, any excessive gas fees during the minting process, and any exploits or malicious attacks.</div>

        <div level='1' mead='5'>Risks.</div>
        <div level='1'         >You accept and acknowledge the following risks:</div>
        <div level='2' mead='a'>The prices of blockchain assets and cryptocurrencies are extremely volatile. Fluctuations in the price of other digital assets could materially and adversely affect the value of your Fragments, which may also be subject to significant price volatility. We cannot guarantee that any minter or purchaser of Fragments will not lose money.</div>
        <div level='2' mead='b'>You are solely responsible for determining what, if any, taxes apply to your Fragments-related transactions. The team is not responsible for determining the taxes that apply to any of your transactions.
</div>
        <div level='2' mead='c'>The App does not store, send, or receive Fragments. Fragments exist only by virtue of the ownership record maintained on the blockchain in the Ethereum network. Any transfer of Fragments occurs within the supporting blockchain in the Ethereum network.</div>
        <div level='2' mead='d'>There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorised access to information stored within your wallet. You accept and acknowledge that The Team will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Ethereum network, however caused.</div>
        <div level='2' mead='e'>A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact Fragments&apos; ecosystem, and therefore the potential utility or value of Fragments.</div>
        <div level='2' mead='f'>The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and new regulations or policies may materially adversely affect Fragments&apos; ecosystem, and therefore the potential utility or value of Fragments.</div>
        <div level='2' mead='g'>Upgrades by Ethereum to the Ethereum platform, a hard fork in the Ethereum platform, or a change in how transactions are confirmed on the Ethereum platform may have unintended, adverse effects on all blockchains using the ERC-721 standard and all current smart contract implementations, including Fragments&apos; ecosystem.</div>

        <div level='1' mead='6'>Third Party Sites.</div>
        <div level='2' mead='a'>The App may permit you to link to other websites, services or resources on the Internet, which are provided solely as a convenience to you. You access these third-party websites, services or resources at your own risk. These other websites, services or resources are not under the Team control and you acknowledge that we are not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of those websites, services or resources.</div>
        <div level='2' mead='b'>The inclusion of any link to third party websites, services or resources does not imply the Team endorsement of them or any association with their operators. You acknowledge and agree that we shall not be responsible or liable (directly or indirectly) for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods or services available on or through any third party websites, services or resources.</div>

        <div level='1' mead='7'>Class Action and Jury Trial Waiver</div>
        <div level='1'         >You must bring any and all Disputes against us in your individual capacity and not as a plaintiff in or member of any purported class action, collective action, private attorney general action, or other representative proceeding. This provision applies to class arbitration. You and we both agree to waive the right to demand a trial by jury.</div>

        <div level='1' mead='8'>Children.</div>
        <div level='2' mead='a'>You affirm that you are over the age of 13, as the App is not intended for children under 13. If you are 13 or older but under the age of 18, or the legal age of majority where you reside if that jurisdiction has an older age of majority, then you agree to review these terms with your parent or guardian to make sure that both you and your parent or guardian understand and agree to these terms. you agree to have your parent or guardian review and accept these terms on your behalf.</div>
        <div level='2' mead='b'>If you are a parent or guardian agreeing to the terms for the benefit of a child over 13, then you agree to and accept full responsibility for that child&apos;s use of the app, including all financial charges and legal liability that he or she may incur.</div>

        <div level='1' mead='9'>Entire Agreement, Severability and Governing Law.</div>
        <div level='2' mead='a'>These Terms (including those terms incorporated herein by reference) are the entire Agreement between you and the Team with respect to the App, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us with respect to the App.</div>
        <div level='2' mead='b'>If any provision of the Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary for the rest of the Terms to remain enforceable. The Team failure to enforce any part of these Terms shall not constitute a waiver of its right to later enforce that or any other part of these Terms. For any waiver of compliance with these Terms to be binding on the Team, one of the Team&apos;s authorised representatives must provide you with written notice of that waiver. There are no third-party beneficiaries to these Terms. Nothing in these Terms create any agency, partnership, or joint venture. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party. You may not assign any or your rights or obligations under these Terms, whether by operation of law or otherwise, without the Team&apos; prior written consent. We shall not be liable for any failure to perform the Team obligations here where such failure results from any cause beyond the Team reasonable control, including without limitation mechanical, electronic or communications failure or degradation (including “line-noise” interference).</div>
        <div level='2' mead='c'>These Terms will be governed by the laws of England and Wales, without regard to conflict of law provisions.</div>
        <div level='1' mead='10'>Contact Information</div>
        <div level='1'         >If you have any questions, would like to provide feedback, or would like more information about VerticalCrypto Art, please feel free to email us at info@verticalcrypto.art.</div>
        <p>
          <Link to="/home">Return home</Link>
        </p>
      </div>
    </div>
  </Page>
