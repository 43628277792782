import PropTypes from "prop-types";
import {  useMemo } from "react";
import { useDialogFlow } from "../utils/useDialogFlow";
import CalculatorContext from "./CalculatorContext";

function CalculatorProvider({ children }) {
  const { onDialogOpenChange, openDialog, setOpenDialog } = useDialogFlow();

  
  const value = useMemo(
    () => ({

      onDialogOpenChange,
      openDialog, 
      setOpenDialog,
    }),
    [
      onDialogOpenChange,
      openDialog,
      setOpenDialog,

    ]
  );

  return <CalculatorContext.Provider value={value}>{children}</CalculatorContext.Provider>;
}

CalculatorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalculatorProvider;
