/* eslint-env browser, node, worker */
/* eslint-disable import/extensions, no-return-assign */
/* eslint-enable no-unused-vars */
/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-void, no-multi-assign */

import {Corelib} from './improxy-red.js'

const {wassert} = Corelib.Debug
// const {nop} = Corelib
// const {createPerfTimer} = Corelib.Tardis
// const {inspectAsObject} = Logre

export const extendContractAdapterWithUpdates = ({adapter}) => {
  const {any2int, getDateStringYMDHMS} = adapter.helpers
  
  adapter.processUpdate = async (pars, tx) => {
    const receipt = await tx?.getTransactionReceipt?.()
    // const trans = await tx?.getTransaction?.()
    const block = await tx?.getBlock?.()
    // console.log({tx, receipt, block, trans})
    const str = pars?.[0]?._hex || ''
    const gasLeftAtStart = parseInt(str.slice(-8), 16)
    const gasPrice = parseInt(str.slice(-16, -8), 16)
    const gasPriceGwei = gasPrice / 1E3
    const p2 = parseInt(str.slice(-24, -16), 16)
    const p3 = parseInt(str.slice(-32, -24), 16)
    const p4 = parseInt(str.slice(-40, -32), 16)
    const type = (p3 || p4) ? 1 : 2
    const refundGwei = 1E3 * parseInt(str.slice(-48, -40), 16)
    const gasLeftAtEnd = parseInt(str.slice(-56, -48), 16)
    const gasRefunded = parseInt(str.slice(-64, -56), 16)
    const common = {
      gasLeftAtEnd,
      gasRefunded,
      gasPriceGwei,
      refundETH: refundGwei / 1E9,
      extraTimestamp: getDateStringYMDHMS(block?.timestamp * 1000),
      extraBlockNumber: block?.number,
      extraGasUsed: receipt?.gasUsed,
    }
    if (type === 1) { // update
      adapter.lastSeedUpdate = {
        seeds: p2 & 0xFFFF,
        reveals: p2 >> 16,
        gasLeftAtStart,
        gasUsedForSeed: p3,
        gasUsedForReveal: p4,
        ...common
      }
      adapter.updatedNow('lastSeedUpdate')
    } else if (type === 2) { // teammint
      adapter.lastTeamMintUpdate = {
        teamMinted: p2,
        gasLeftAtStart,
        ...common
      }
      adapter.updatedNow('lastTeamMintUpdate')
    } else {
      console.log('Bad type in Update event', {type, pars})
    }
  }

  adapter.seedUpdate = async (limit = 100) => {
    // estimate!
    const promise = adapter.owner.cryMeth.update([limit, 100])
    const tx = await promise
      .catch(err => {
        const result = adapter.createResultFromError({err, extra: {op: `update(${limit})`}})
        console.log({err, result})
        debugger
        return result // nobody uses this
      })
    tx.receipt = await tx?.wait?.()
    // wassert(receipt)
    // const gas = receipt ? any2int(receipt.gasUsed) : 0
    // const gasPerFrag = ~~(gas / adapter.controlVars.supply)
    // log(`${dis} awaited, gas:`, {gas, gasPerFrag}, {tx, receipt})

    // await readControlFlagsAndVars() // -> 
    return tx
  }

  // const teamMintUpdate = async (lowerLimit = 1, upperLimit = 100, conf = {}) => {

  //   const {skipExec = false, skipEst = false} = conf
  //   const res = {
  //     parray: [lowerLimit, upperLimit],
  //     wasOk: true
  //   }
  //   console.log(`estGasForTx`)

  //   if (!skipEst) {
  //     res.estResult = await adapter.estGasForTx('teamMint', res.parray, {over: 120})
  //     if (!res.estResult.wasOk) {
  //       console.warn('update gas estimation failed', res.estResult)
  //       res.wasOk = false
  //       res.failReason = 'est'
  //       return res
  //     } else {
  //       res.gasLimit = res.estResult.gasEstimate
  //     }
  //   }
  //   console.log(` res.gasLimit : `, res.gasLimit)
  //   console.log(` res.parray : `, res.parray)
   
  //   if (!skipExec) {
  //     const promise = adapter.owner.cryMeth.teamMint(res.parray, {gasLimit: res.gasLimit})
  //     res.tx = await promise
  //       .catch(err => {
  //         res.txResult = adapter.createResultFromError({err, extra: {op: `update(${lowerLimit})`}})
  //         console.log({err, txResult: res.txResult})
  //         res.wasOk = false
  //         res.failReason = 'tx'
  //         return res
  //       })
  //     res.receipt = await res.tx?.wait?.()
  //     // wassert(receipt)
  //     // const gas = receipt ? any2int(receipt.gasUsed) : 0
  //     // const gasPerFrag = ~~(gas / adapter.controlVars.supply)
  //     // log(`${dis} awaited, gas:`, {gas, gasPerFrag}, {tx, receipt})

  //     // await readControlFlagsAndVars() // -> 
  //   }
  
  //   return tx
  // }

  const anyUpdate = async (name, parray, conf) => {
    const {skipExec = false, skipEst = false, nonOwner = false, over = 200, pre} = conf
    const res = {
      parray,
      wasOk: true
    }
    const contractObj = nonOwner ? adapter.write : adapter.owner

    wassert(adapter.owner) // chk

    await pre?.()

    if (!skipEst) {
      try {
        res.gasEstimate = await contractObj.estGas[name](...parray) // read or owner!
        res.gasLimit = res.gasEstimate.mul(over).div(100)
      } catch (err) {
        console.warn(`contract.${name}() cannot be called (estGas), error:`, err)
        res.estResult = adapter.createResultFromError({err, extra: {parray, op: name}})
        res.wasOk = false
        res.failReason = 'est'
        return res
      }
    }

    await pre?.()

    if (!skipExec) {
      const promise = contractObj.cryMeth[name](...res.parray, {gasLimit: res.gasLimit})
      res.tx = await promise
        .catch(err => {
          res.txResult = adapter.createResultFromError({err, extra: {op: name}})
          console.log({err, txResult: res.txResult})
          res.wasOk = false
          res.failReason = 'tx'
          return res
        })
      res.receipt = await res.tx?.wait?.()
      // wassert(receipt)
      res.gasUsed = res.receipt ? any2int(res.receipt.gasUsed) : 0
      // log(`${dis} awaited, gas:`, {gas, gasPerFrag}, {tx, receipt})
    }
    return res
  }

  const seedUpdate = (limit = 100, conf = {}) => anyUpdate('update', [limit], conf)

  adapter.seedUpdateEstimation = limit => seedUpdate(limit, {skipExec: true})

  adapter.seedUpdate = limit => seedUpdate(limit)

  adapter.seedUpdateNonOwner = (limit, pre) => seedUpdate(limit, {nonOwner: true, pre})

  const teamMintUpdate = (lower = 1, upper = 100, conf = {}) =>  anyUpdate('teamMint', [lower, upper], conf)  

  adapter.teamMintUpdateEstimation = (lower, upper) => teamMintUpdate(lower, upper, {skipExec: true})

  adapter.teamMintUpdate = (lower, upper) => teamMintUpdate(lower, upper)
}
  