import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const Input = forwardRef(({ className, ...props }, ref) => (
  <input
    ref={ref}
    className={clsx(
      "border-2 border-black px-4 disabled:bg-offblack disabled:text-gray-400 disabled:border-gray-400",
      className
    )}
    {...props}
  />
));

Input.propTypes = {
  className: PropTypes.string,
};

Input.defaultProps = {
  className: null,
};

export default Input;
