/* eslint-env browser, node, worker */
/* eslint-disable no-shadow, consistent-return, import/extensions */
/* eslpint-disable no-unused-vars */

import {Corelib, Logre} from './improxy-red.js'

const {inspectAsObject, inspectAsTable} = Logre
const {isFun} = Corelib
const {wassert, brexru} = Corelib.Debug

export const extendContractAdapterWithTxs = ({adapter}) => {
  const {
    isReadContractValid, isWriteContractValid, isOwnerContractValid,
    any2int, big2eth, log, rlog, flog, logOn
  } = adapter.helpers
  void rlog

  // INT Internal pre/post processors________________________________

  adapter.smartLog = result => { // fix -> global logArr
    void result
    // result.loggedAt = NoW()
    // const resultJson = JSON.stringify(result)
    // adapter.write.cryMeth.addExtLog(resultJson) <- this makes the double popup
  }

  const ERR = {
    0: {
      main: 'GLOB_ERR',
      subs: {
        1: ['GLOB_ERR_NO_REQUESTID']
      }
    },
    1: {
      main: 'MINT_ERR',
      subs: {
        1: ['MINT_ERR_BAD_MINT_COST'],
        2: ['MINT_ERR_OUT_OF_MINTWIN', " Minting is disabled now."],
        // should probably not be shown to user as reason for error is more likely to be bug than user error
        4: ['MINT_ERR_AMOUNT_TOO_HIGH', "No more than 5 fragments can be minted at the same time."],
        8: ['MINT_ERR_CIRCULATION_TO_HIGH', "Out of the drop's fragment supply."],
        16: ['MINT_ERR_NO_CREDIT', "Only whitelisted users can mint now."],
        32: ['MINT_ERR_OUT_OF_CREDIT', "Out of whitelisted credit."]
      }
    },
    2: {
      main: 'COMBINE_ERR',
      subs: {
        1: ['COMBINE_ERR_NOT_AUTHORISED'],
        2: ['COMBINE_ERR_NOT_READY', "A source fragment is not ready yet."],
        4: ['COMBINE_ERR_TOO_MANY_TILES', "The combined fragment has too many tiles."],
        8: ['COMBINE_ERR_NO_REQUESTID'],
        16: ['COMBINE_ERR_IN_REDEEMWIN', "Combination is disabled now."]
      }
    },
    3: {
      main: 'REDEEM_ERR',
      subs: {
        1: ['REDEEM_ERR_NOT_AUTHORISED'],
        2: ['REDEEM_ERR_OUT_OF_REDEEMWIN', "Drop prize redeem is disabled now."],
        4: ['REDEEM_ERR_TOO_LATE'],
        8: ['REDEEM_ERR_NO_WINNER', "This fragment is not a winner."],
        16: ['REDEEM_ERR_NOT_COMBINED', "This fragment is not combined."],
        32: ['REDEEM_ERR_NOT_READY'],
        64: ['REDEEM_ERR_ZERO_VALUE', "This fragment has 0 value (INT_ERR?)"]
      }
    }
  }

  const humanize = code => '~' + code[0] + code.slice(1).split('_').join(' ').toLowerCase()

  const errCodeToError = errcode => {
    const errTopic = ERR[~~(errcode / 256)]
    wassert(errTopic)
    const {main, subs} = errTopic
    const errors = []

    for (let i = 1; i < 256; i <<= 1) {
      const sub = errcode & i
      if (sub) {
        wassert(subs[sub])
        const [code, message] = subs[sub]
        errors.push({main, code, message, error: message || humanize(code)})
      }
    }
    flog(`Errors from error code:`, {errors})
    return errors
  }

  adapter.createResultFromError = ({err, extra = {}}) => {
    const {body, requestBody, message, ...rest} = err
    const customRaw = message.split(`'`)[1] || rest.error?.message || err.data?.message?.split(`'`)[1] || ''
    const custom = customRaw.includes('MzJQ') ? 'MzJQ' + customRaw.split('MzJQ')[1] : customRaw

    const origError = {
      body: JSON.parse(body || '{}'),
      requestBody: JSON.parse(requestBody || '{}'),
      message,
      custom,
      ...rest
    }

    const result = {
      hasErrSource: true,
      wasOk: false,
      wasReturn: false,
      wasError: true,
      error: {
        code: custom,
        message: custom,
        error: custom
      },
      origError,
      extra,
      frozenDropState: {...adapter.dropState},
      frozenControlFlagsAndVars: {...adapter.controlFlags, ...adapter.controlVars}
    }
    const errors = []
    if (custom?.slice(0, 4) === 'MzJQ') {
      flog(`MINTERR`, {custom})

      const digits = custom.slice(4)
      for (let ix = 0; ix < 6; ix++) {
        digits[ix] === 'E' && errors.push(...errCodeToError(256 + (1 << ix)))
        result.hasMintReqSource = true
      }
      flog(`MINTERR`, {errors})
    }
    result.errors = errors
    result.error = errors[0] ? {...errors[0]} : {code: custom, message: custom, error: custom}
    if (logOn.failure) {
      inspectAsObject(result, {dark: true, name: 'Result from Exception'})
      inspectAsObject(origError, {dark: true, name: 'Exception from Solidity'})
      flog('createResultFromError', {err, origError, extra, message, rest})
    }

    return result
  }

  const txLogs = []

  const logReceipt = async (receipt, msg) => {
    if (receipt) {
      const blno = receipt.blockNumber || 0
      const block = await adapter.ethersProvider.getBlock(blno)
      const {gasUsed, cumulativeGasUsed} = receipt
      const gasU = any2int(gasUsed)
      const cGasU = any2int(cumulativeGasUsed)
      // log(`⚓️${msg} 💸gas: ${gasU}/${cGasU}`)
      const {difficulty, _nonce, timestamp} = block
      const nonce = parseInt(_nonce, 16)
      txLogs.push({desc: `⚓️${msg} 🏢🏢`, gasU, cGasU, blno, difficulty, timestamp, nonce})
    } else {
      log(`⚓️${msg} - no receipt`)
    }
    console.table(txLogs.slice(-10))
  }

  // INT Transaction Post Processor______________________________________

  adapter.txPostProcessor = async (transaction, {type = '?', hueRot = 0} = {}) => {
    //transaction.gasLimit = 1E9
    const transactionExt = await adapter.ethersProvider.getTransaction(transaction.hash)
    const [receipt, controlFlagsAndVars, dropState] = await Promise.all([ // meltdown sync was here
      transaction?.wait?.(),
      adapter.massModeOn 
        ? {...adapter.controlFlags, ...adapter.controlVars}
        : adapter.getControlFlagsAndVars(),
      adapter.massModeOn
        ? adapter.dropState
        : adapter.getDropState()
    ])
    const receiptExt = await adapter.ethersProvider.getTransactionReceipt(transaction.hash)
    console.log({transaction, transactionExt, receipt, receiptExt})
    logReceipt(receipt, `txPostProc(${type})`)

    const errors = []
    const {gasUsed, cumulativeGasUsed} = receipt
    const result = {
      hasTxSource: true,
      tx: {gasUsed: any2int(gasUsed), cumulativeGasUsed: any2int(cumulativeGasUsed)},
      errors,
      wasOk: false,
      wasReturn: false,
      wasError: false,
      frozenDropState: {...dropState},
      frozenControlFlagsAndVars: {...controlFlagsAndVars}
    }
    result.events = receipt?.events?.map(eventItem => {
      const {event, eventSignature, args} = eventItem
      const ours = ['Log', 'ReturnS', 'Mint', 'Combine', 'Redeem'].includes(event)
      //const {message, num, bnum, barr, errcode} = args || {}
      const {message, id, ids, val: valBN, errcode} = args || {}
      const valETH = valBN ? big2eth(valBN) : undefined

      if (['Mint', 'Combine', 'Redeem'].includes(event)) {
        result.wasReturn = true
        result.returned = { args, message, id, ids, valETH }
      }
      if (event === 'ErrCode') {
        result.wasError = true
        errors.push(...errCodeToError(errcode))
      }
      // TEST logging:
      let humanArgs = ''
      if (logOn.log) {
        try {
          humanArgs = ours
            ? message + ' / ' + (ids?.length ? ids.join(', ') : id) + (valBN ? valETH : '')
            : JSON.stringify(args || '').slice(0, 114)
        } catch (e) {
          console.warn(`Could not create humanArgs`, e)
        }
        //console.log({message, num, bnum, barr, args})
      }
      return {event, eventSignature, id, ids, valETH, humanArgs, args, message}
    })
    result.wasOk = result.wasReturn && !result.wasError
    if (!result.wasOk) {
      if (!result.errors.length) {
        result.errors.push({main: 'UNKNOWN', code: 'NO_RETURN_OR_ERROR', error: 'NO_RETURN_OR_ERROR'})
      }
      const {code, message, error} = errors[0]
      result.error = {code, message, error}
    }
    if (logOn.log) {
      const commonSt = 'border:1px solid #ccc;margin-left:5px;'
      const prest = `background:#555;border-radius:8px 8px 0 0;` + commonSt
      console.log(`%c Transaction: ${type} gasUsed: ${any2int(gasUsed)} cumulativeGasUsed: ${any2int(cumulativeGasUsed)} `, prest)
      inspectAsTable(result.events, 'event:10,eventSignature:40,humanArgs:60', {hueRot})
      result.errors.length && inspectAsTable(result.errors, 'main:11,code:30,message:40', {hueRot: -120})
      const {wasReturn, wasOk, wasError, error = {}} = result
      const [cr, co, ce] = [wasReturn ? '🟡' : '⚫️', wasOk ? '🟢' : '⚫️', wasError ? '🔴' : '⚫️']
      const bg = wasError ? '#800' : wasOk ? '#060' : '00a'
      const st = `background:${bg};border-radius:0 0 8px 8px;` + commonSt
      console.log(`%c wasReturn: ${cr} wasOk: ${co} wasError: ${ce} ${error.error || ''} `, st, {result})
      //console.log({result})
    }
    return result
  }

  // fix not in contractTx

  adapter.setRandomizerThrottle = async throttle => { // set randomizer throttle percent
    if (!adapter.v2) {
      return {}
    }
    if (!isOwnerContractValid()) { 
      console.warn('setRandomizerThrottle can be called only by owner!')
      return
    }
    // chk must check owner (or handle the error as the contract will reject this)
    console.log('💸💸setRandomizerThrottle', throttle)

    const metadataUrl = 'https://simplecontract-metadata2-fdugspmk2q-ew.a.run.app/'
    const cubeUrl = 'https://arweave.net/XhK9cImzeR8VqlcUc4u-cTdmNSZn5DqWoYzS7lNCLb8/?conf='
  
    return adapter.owner.cryMeth.configSetter(metadataUrl, cubeUrl, throttle)
      .then(async transaction => {
        console.log({transaction})
        const result = await adapter.txPostProcessor(transaction, {type: 'throttle', hueRot: 230})
        result.op = 'throttle'
        result.request = {throttle}
        result.response = {}
        result.okMessage = `Randomizer throttled to ${throttle}%.`
        console.log('THROTTLE', {result})
        adapter.smartLog(result)
        return result
      })
      .catch(err => {
        console.warn('THROTTLE.catch', {...err})
        const result = adapter.createResultFromError({err, extra: {op: 'throttle', throttle}})
        return result
      })
  }

  adapter.transferPrevDropPool = async drop => {
    if (!isOwnerContractValid()) { 
      console.warn('transferPrevDropPool can be called only by owner!')
      return
    }
    // fix uniatx?
    const tx = await adapter.owner.cryMeth.transferPoolFromPreviousDrop(drop)
    void tx
  }

  // INT universal tx processor
  
  // fix cryMeth

  adapter.testMeth = async (contractObject, name, parray, {silent = false} = {}) => {
    wassert(isFun(contractObject?.cryMeth[name]))

    try {
      const gas = await contractObject.estGas[name](...parray)
      silent || log(`contract.${name}() will cost ${gas} gas and can be executed.`)
      return {wasOk: true, gas}
    } catch (err) {
      silent || flog(`contract.${name}() cannot be called (estGas), error:`, err)
      return {wasOk: false, err}
    }
  }

  // fix cryMeth

  const getContractObject = (type, ...args) => {
    if (type === 'owner') {
      if (!isOwnerContractValid()) {
        console.warn('uniAtx: no owner contract for', type, ...args)
        return
      }
    } else if (type === 'write') {
      if (!isWriteContractValid()) {
        console.warn('uniAtx: no write contract for', type, ...args)
        return
      }
    } else if (type === 'read') {
      if (!isReadContractValid()) {
        console.warn('uniAtx: no read contract for', type, ...args)
        return
      } else {
        console.warn('uniAtx called for read?', type, ...args)
      }
    } else {
      brexru()
    }
    const contractObject = adapter[type]
    wassert(contractObject?.contract)
    return contractObject   
  }

  adapter.uniAtx = async (type, atx, parray, {onEst, onSuccess, onFailure}) => {
    const contractObject = getContractObject(type, atx, parray)
    const {nick, methName} = atx
    const logNick = `uniAtx.${nick}(${methName})`
    //const parray = parrayGen()
    log(`uniAtx will exec ${logNick} with pars:`, parray)
    log(`contractObject : `,contractObject)
    log(`methName : `,methName)

    const estResp = await adapter.testMeth(contractObject, methName, parray, {silent: true})
    if (estResp.wasOk) {
      onEst?.(estResp.gas)
    } else {
      console.warn(estResp.err)
      onFailure?.(estResp.err)
      return {}
    }
    const txResponse = contractObject.cryMeth[methName](...parray)
    
    const transaction = await txResponse
      .catch(err => {
        console.warn(err)
        onFailure?.(err)
      })

    const ret = {transaction}

    if (transaction?.wait) { // non-constant method
      const receipt = await transaction.wait()
      logReceipt(receipt, `⌛️awaited ${logNick}`)
      log(`⌛️awaited ${logNick} (non-constant)`, {transaction, receipt})
      ret.receipt = receipt
    } else { // constant method
      log(`⌛️awaited ${logNick} (constant), will return`, {transaction})
    }
    onSuccess?.(transaction)
    return ret
  }

  // rem ops calls this, write only:

  adapter.estGasForTx = async (name, parray, {silent = false, over = 100} = {}) => {
    wassert(isFun(adapter.write.cryMeth[name]))

    log(`parray :`,parray)
    try {
      const gasEstimate = await adapter.write.estGas[name](...parray)
      silent || log(`contract.${name}() will cost ${gasEstimate} gas and can be executed.`)
      return {wasOk: true, gasEstimate: gasEstimate.mul(over).div(100)}
    } catch (err) {
      silent || flog(`contract.${name}() cannot be called (estGas), error:`, err)
      const result = adapter.createResultFromError({err, extra: {parray, op: name}})
      return  result
      //return {wasOk: false, err}
    }
  }
}
