import PropTypes from "prop-types";

function ExternalLink({ border, children, ...props }) {
  let className = "font-bold disabled:border-gray-400 disabled:text-gray-400 hover:bg-gray-100 focus:bg-gray-100 focus:ring-4 active:bg-gray-200";

  if (border) {
    className = `${className} border-b-2 border-black`;
  }

  return (
    <a
      className={className}
      rel="noreferrer noopener"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

ExternalLink.defaultProps = {
  border: true,
};

export default ExternalLink;
