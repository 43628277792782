/* eslint-env browser, node, worker */
/* eslint-disable consistent-return, import/extensions */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, no-return-assign,
   quotes, multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */

import {Corelib} from './improxy-red.js'

const {pNow, pSince} = Corelib.Tardis
// const {wassert} = Corelib.Debug

export const extendContractAdapterWithLiquidityPool = ({adapter}) => {
  const {isReadContractValid, big2eth, log, clog, sumOfArray, liqlog, any2int, tileLimits} = adapter.helpers

  // ID _____ liquidity pool & value calculations ___________

  // chk test this

  const getEthFromBigNumberOrTwei = val => val?._hex ? big2eth(val) : val / 1E6 // Twei is ui32, not BN!

  adapter.processLiquidityPoolData = (lpMax, balance) => {
    //const liqPoolR = _liqPoolR.map(val => val / lpMax)
    //const liqPoolPt = _liqPoolR.map(val => val * 100 / lpMax)
    //const liqPool = liqPoolR.map(val => balance * val)
    //const liqPoolSum = sumOfArray(liqPool)
    const liqPool=balance
    const liqPoolSum=balance
    adapter.lastLiqPool = {liqPool, liqPoolSum, lpMax, balance}
    adapter.updatedNow('lastLiqPool')
    return adapter.lastLiqPool
  }
  
  adapter.getLiquidityPoolData = async () => { // PUB getLiquidityPoolData() _______________
    //clog(`getLiqPoolData`)
    if (!isReadContractValid()) {
      return {}
    }
    debugger

    return adapter.hasLpRatio
      ? adapter.read.cryMeth.getLiquidityPoolRatios()
          .then(_lp => {
            const {_liqPoolR, _lpMax, _balance} = _lp || {}
            return adapter.processLiquidityPool(_liqPoolR, any2int(_lpMax), big2eth(_balance))
          })
          .catch(adapter.contractException('getLiqPoolRatios'))

      : adapter.read.cryMeth.getLiquidityPoolValues()
          .then(_lp => {
            const liqPoolBN = [..._lp]
            const [liqPoolSum, liqPool] = liqPoolBN.map(getEthFromBigNumberOrTwei)
            // const liqPoolSum = liqPool.shift()
            adapter.updatedNow('lastLiqPool')
            return adapter.lastLiqPool = {liqPool, liqPoolSum}
          })
          .catch(adapter.contractException('getLiqPoolRatios'))
  }

  const liquidity = {
    isValid: false,
    receivedAt: 0
  }

  adapter.getLiquidityPoolState = async (lag = 0) => {
    const age = pSince(liquidity.receivedAt)
    if (age < lag && liquidity.isValid) {
      return
    }
    await adapter.hasLpRatio ? adapter.getState() : adapter.getLiquidityPoolData()
    liquidity.receivedAt = pNow()
    liquidity.isValid = true
    return adapter.lastLiqPool
  }

  
  // adapter.updateLiquidityPoolCache = async (lag = 0) => {
  //   const age = pSince(liquidity.receivedAt)
  //   if (age < lag && liquidity.isValid) {
  //     return
  //   }
  //   await adapter.hasLpRatio ? adapter.getState() : adapter.getLiquidityPoolData()
  //   liquidity.receivedAt = pNow()
  //   liquidity.isValid = true
  //   return adapter.lastLiqPool
  // }

  const completeFacesBonusArray = [0.1,0.2,1,2,3,4,10]

  adapter.js_calcCurrentPoolMods = fragment=> {
    let poolSum = 0
    let completeFaces=0;
    const maxSeries = adapter.getMaxSeries()
    
    for (let ix = 0; ix < 6; ix++) {
        if (fragment.squareCount[ix] === tileLimits[ix]) {
            completeFaces++
        }
    }
    const completeFact = completeFacesBonusArray[completeFaces];
    const mintingPrice =adapter.state.mintingCostETH;
    // clog(`mintingPrice when redeeming : `,mintingPrice)
    // clog(`completeFaces : `,completeFaces)
    // clog(`completeFact bonus : `,completeFact)
    poolSum = completeFact * mintingPrice;
    return {poolSum, maxSeries, completeFaces, series: fragment.series}
   
    // const isComplete = [false, false, false, false, false, false]
    // let completeFaces = 0
    // const pool = []
    // let poolSum = 0

    // for (let ix = 0; ix < 6; ix++) {
    //   if (fragment.squareCount[ix] === tileLimits[ix]) {
    //     isComplete[ix] = true
    //     completeFaces++
    //   }
    // }
    // const {liqPool} = adapter.lastLiqPool
    // const logs = []

    // const maxSeries = adapter.getMaxSeries()
    // const poolAccess =  1 - fragment.series / 5 / (maxSeries + 1)
    // const completeFact = completeFacesBonusArray[completeFaces] //* 1E6
    // const unCompleteFact = completeFacesBonusArray[0]  //* 1E6

    // for (let ix = 0; ix < 6; ix++) {
    //   const fact = isComplete[ix]
    //     ? completeFact
    //     : unCompleteFact * fragment.squareCount[ix] / tileLimits[ix] // 5M * 0..64 / 9..64 (0..1)

    //   pool[ix] = poolAccess * liqPool[ix] * fact / 1E3// / 1E9 // chk test v1 v2

    //   logs.push(['%ccalcCurrPoolMods ix, isComplete, fact', 'color: #0cf', ix, isComplete[ix], fact])

    //   poolSum += pool[ix]
    // }
    //console.log({pool, poolSum})
   
  }

  // adapter.contract_calcCurrentPoolMods = async fragment => { // chk test
  //   const epool = await adapter.read.cryMeth.calcCurrentPoolMods(fragment.id)
  //   const ret = {}
  //   if (adapter.hasLpRatio) {
  //     ret.pool = epool.map(getEthFromBigNumberOrTwei)
  //     ret.poolSum = sumOfArray(ret.pool)
  //   } else {
  //     [ret.poolSum, ...ret.pool] = epool.map(getEthFromBigNumberOrTwei)
  //   }
  //   // debugger
  //   console.log(`CONTRACT calcCurrentPoolMod of id=${fragment.id}`, {ret})
  //   return ret
  // }
 
  adapter.getLiquidityPoolAbsValues = async () => { // PUB getLiquidityPoolValues() _______________
    clog(`getLiqPoolAbsValues`)
    if (isReadContractValid()) {
      return adapter.hasLpRatio
        ? adapter.getState()
            .then(() => ({wasOk: true, ...adapter.lastLiqPool}))
        : Promise.all([adapter.getStats(1000), adapter.getLiquidityPoolData()])
            .then(() => {
              const {liqPool, liqPoolSum} = adapter.lastLiqPool
              // liqlog(liqPool, liqPoolSum)
              return {wasOk: true, liqPool, liqPoolSum}
            })
    }

  }

  // this is not used
  
  // adapter.setLiquidityPoolValues = async setLiqPool => { // PUB setLiquidityPoolValues() _______________
  //   clog(`setLiqPoolValues`, {setLiqPool})
  //   if (isReadContractValid()) {
  //     //await adapter.owner.cryMeth.setLiquidityPoolValues(liqPool, liqPoolSum)
  //   }
  // }
}
