/* eslint-disable no-restricted-syntax, prefer-template, no-underscore-dangle, spaced-comment,
   no-unused-expressions, no-nested-ternary, no-console, no-await-in-loop, no-param-reassign, no-void,
   import/extensions, array-callback-return, no-return-assign, no-multi-assign, default-param-last,
   no-debugger, object-curly-spacing, quotes, no-trailing-spaces, no-multi-spaces
*/
//import {getUserLanguages} from "../util/practitioners";

//export {inspectAsTable} from './stdlib/logre-esm.js'

export const onCombo = (mainFun, auxFun) => (...args) => {
  const ret = mainFun(...args)
  auxFun()
  return ret
}

export const tabLog = ({bg = '#bbb', ml = 0}, ...args) => {
  const stleft = [
    `border-bottom: 1.5px solid ${bg}`,
    `padding-left: ${ml}px`
  ].join(';')
  const stright = [
    `border-bottom: 1.5px solid ${bg}`,
    `padding-left: 400px`
  ].join(';')
  const st = [
    `background:${bg}`,
    `color:#000`,
    `font-weight:900`,
    `padding:6px 4px 2px`,
    `border-radius: 12px 12px 0 0`,
    `font:700 14px roboto condensed`,
    //`margin-left:${ml}px`
  ].join(';')
  console.log(args.shift().split('%c').join('%c %c') + '%c ', stleft, st, stright, ...args)
}