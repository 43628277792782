/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, no-multi-assign, guard-for-in, import/extensions */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger */

import {Corelib} from './improxy-red.js'

const {globalThis} = Corelib
const {createPerfTimer, pNow} = Corelib.Tardis
const {wassert, brexru} = Corelib.Debug

const logOn = false

const log = (...args) => logOn && console.log('🔮' + args.shift(), ...args)
const clog = (...args) => logOn && console.log('🔮❔❕' + args.shift(), ...args)
const rlog = (...args) => logOn && console.log('🔮✔️' + args.shift(), ...args)
const flog = (...args) => logOn && console.log('🔮❌' + args.shift(), ...args)

// const unpackFrag = frag => {
//   const {packedSquareCount} = frag
//   const mask = ('000000000000' + packedSquareCount?._hex?.slice(2)).slice(-12)
//   frag.squareCount = []
//   for (let ix = 5; ix >= 0; ix--) {
//     frag.squareCount[ix] = parseInt(mask.substr(ix * 2, 2), 16)
//   }
// }

export const extendContractAdapterWithFragCache = ({adapter}) => {
  const {pureSolid, mlog, mlogFrag, massert} = adapter.helpers

  const cache = adapter.cache = {
    fp: 0,
    pending: false,
    promise: null,
    resolvePending: null
  }
  const hashid = {}

  const fragHash = {
    isDirty: true,
    id: hashid,
    owner: {},
    drop: {},
    dropCombined: {},
    combined: {
      true: [],
      false: []
    },
    latestDrop: {}
  }

  cache.revisor = (context = '') => {
    mlog(`🕵️revisorStart(${context})`)
    console.groupCollapsed(`🕵️revisorStart(${context})`)
    for (const combinedFrag of fragHash.combined.true) {
      const hashedFrag = hashid[combinedFrag.id]
      mlogFrag('🕵️revisor', hashedFrag)
      //console.log('revisor', combinedFrag.id)
      wassert(JSON.stringify(combinedFrag) === JSON.stringify(hashedFrag))
    }
    console.groupEnd()
  }

  const rehash = () => { // rebuild fragHash, don't touch frags!
    if (fragHash.isDirty) { // fix isDirty is set ok?
      const timer = createPerfTimer()
      fragHash.owner = {}
      fragHash.drop = {}
      fragHash.dropCombined = {}
      fragHash.combined.true = []
      fragHash.combined.false = []

      for (const id in hashid) {
        const frag = hashid[id]
        if (frag.isBlank) { // CHK for now we won't hash any blanks (except in id)
          continue
        }
        if (typeof frag === 'undefined') {
          delete hashid[id]
          continue
        }
        try {
          fragHash.combined[frag.combined].push(frag)
        } catch (err) {
          const {...rest} = err
          console.log({rest})
          console.log(err)
          debugger
        }
        const {owner, drop} = frag
        if (frag.combined) {
          //fragHash.dropCombined[drop] ??= []
          fragHash.dropCombined[drop] || (fragHash.dropCombined[drop] = [])
          fragHash.dropCombined[drop].push(frag)
        }  
        fragHash.drop[drop] || (fragHash.drop[drop] = [])
        fragHash.drop[drop].push(frag)
        fragHash.owner[owner] || (fragHash.owner[owner] = [])
        fragHash.owner[owner].push(frag)
      }
      fragHash.isDirty = false
      const sum = timer.sum().dur.sum
      sum > 10 && console.log(`rehash done for ${hashid.getPropertyCnt()} fragments in ${sum}ms`)
      //cache.revisor('rehash')
    }
  }
  window.fragHash = () => rehash() || fragHash
  window.fragDump = () => window.fragHash().id.propertiesToArr().map(id => {
    const {series, squareCount: c, full, matches = []} = fragHash.id[id]
    const {id: matchId = -1, full: matchFull = -1} = matches[0] || {}

    return `${id},${series},${c[0]},${c[1]},${c[2]},${c[3]},${c[4]},${c[5]},${full},${matchId},${matchFull}`
  })

  cache.getOwnerHashFragments = owner => rehash() || fragHash.owner[owner] || []
  cache.getFragHash = () => rehash() || fragHash

  //const filteredFrags = ids => ids.map(id => hashid[id]).filter(frag => frag?.isReady)
  const unfilteredFrags = ids => ids.map(id => hashid[id])

  cache.createBlankFragment = id => ({
    id,
    isBlank: true,
    isReady: false,
    //und: console.warn(`CACHE: Creating a blank fragment`, id),
    squareCount: [0, 0, 0, 0, 0, 0],
    fullnessFloat: -1
  })

  const preprocessFragment = frag => { // this is called first, rehash() second
    wassert(frag)
    if (!adapter.hasDrops) {
      frag.drop = ~~frag.isValid
    }
    if (!frag.drop) {
      rlog('find a deleted frag?', frag)
      return frag.isBlank ? frag : cache.createBlankFragment(frag.id)
    }
  
    fragHash.isDirty = true // ID DIRT
    massert(frag.owner, 'BAD: NO OWNER', frag)

    if (frag.isReady) {
      const {packedSquareCount} = frag
      const mask = ('000000000000' + packedSquareCount?._hex?.slice(2)).slice(-12)

      frag.squareCount = []
      wassert(mask.length === 12)
      for (let ix = 5; ix >= 0; ix--) {
        frag.squareCount[ix] = parseInt(mask.substr(ix * 2, 2), 16)
      }
    } else {
      frag.squareCount = [0, 0, 0, 0, 0, 0]
    }

    adapter.calcFullness(frag)
    const {id, ...rest} = frag

    return {
      id,
      receivedAt: ~~pNow(),
      isRaw: true,
      username: adapter.mockUsername?.(frag.owner) || frag.owner.substr(2, 8),
      series: ~~(id / adapter.stats.SERIES_LENGTH),
      ...rest
    }
  }

  const invalidateToken = id => { // fix - cannot be called from outside - post rehash needed!!!
    if (!hashid[id]?.isBlank) { // not intentionally blank
      delete hashid[id]
      fragHash.isDirty = true
      flog(`Invalidate token`, id)
    }
  }

  cache.invalidateTokens = ids => {
    for (const id of ids) {
      invalidateToken(id)
    }
    rehash()
  }

  cache.invalidateOwner = owner => {
    rehash()
    const ownerFrags = fragHash.owner[owner]
    if (!ownerFrags) {
      return
    }
    const ids = []
    for (const {id} of ownerFrags) {
      invalidateToken(id)
      ids.push(id)
    }
    rehash()
    log(`invalidateOwner:`, ids.slice(0, 20).join(', '))
  }

  cache.getToken = async id => (await cache.getTokens([id]))?.[0]

  cache.checkToken = async id => hashid[id] || cache.getToken(id)

  adapter.getCachedFragment = id => hashid[id]

  cache.getTokens = async ids => {
    const hasDirty = ids.some(id => !hashid[id]?.isReady)  // fix!!!!
    if (hasDirty) {
      const timer = createPerfTimer()
      const fp = cache.fp++
      if (cache.pending) {
        clog(`cache.getTokens queue(#${fp})`, {ids}) // FIX this is messed up....
        await cache.promise
        clog(`cache.getTokens go!(#${fp})`)
      } else {
        clog(`cache.getTokens free to go, no wait!(#${fp})`, {ids})
      }
      cache.pending = true
      cache.promise = new Promise(resolve => cache.resolvePending = resolve)

      const queryIds = []  
      for (const id of ids) { // fix no id===0 !!!! starts from 1
        //id && 
        hashid[id]?.isReady || hashid[id]?.isBlank || queryIds.push((id))
      }

      if (queryIds.length) {
        wassert(typeof queryIds[0] !== 'undefined')
        log(`cache.getTokens reading(#${fp}) ${queryIds.length} fragments`)
        const fragsPromise = adapter.read.cryMeth.getFragmentsByIds(queryIds)
        fragsPromise.catch(err => {
          console.error(`cache.getTokens NU ERROR`, err, queryIds)
        })
        const [_frags, _owners] = await fragsPromise
        // console.log({ids, queryIds})
        // console.table(_frags)
        const frags = _frags.map((frag, ix) => ({...pureSolid(frag), owner: _owners[ix], origid: ids[ix]}))
        // console.table(frags)
        for (const frag of frags) {
          if (frag?.isValid) { // for v1, for v2 deleted frags cvan be valid (blank), so it needs fixing
            hashid[frag.id] = preprocessFragment(frag)
          }
        }
        rlog(`cache.getTokens ☢️☢️ read(#${fp}) ${frags.length}/${ids.length} in ${timer.sum().dur.sum}ms`, {frags})
      }

      log(`cache.getTokens will resolve block(#${fp}) len(id/quids) ${ids.length}/${queryIds.length}`)
      cache.resolvePending()
      cache.pending = false
    } else {
      rlog(`cache.getTokens returns full hit (0) of ${ids.length}`, {ids})
    }
    rehash()
    return unfilteredFrags(ids)
  }

  cache.getTokensByOwner = async owner => {
    const timer = createPerfTimer()

    const fragsPromise = adapter.read.cryMeth.getFragmentsByOwner(owner)
    fragsPromise.catch(err => console.error(`cache.getTokensByOwner NU ERROR`, err, owner))
    const _frags = await fragsPromise
    const frags = _frags.map((frag, ix) => ({...pureSolid(frag), owner}))
    for (const frag of frags) {
      hashid[frag.id] = preprocessFragment(frag)
    }
    rlog(`cache.getTokensByOwner ☢️☢️ read(${frags.length}) in ${timer.sum().dur.sum}ms`, {frags})

    rehash()
    return unfilteredFrags(frags.map(frag => frag.id))
  }
}
   