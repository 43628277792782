/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, quotes, semi,
   react/jsx-no-bind */

import PropTypes from "prop-types";
import * as Dialog from "../designSystem/Dialog";
import Button from "../designSystem/Button";
import ConnectWalletDialog from "../ethWallet/ConnectWalletDialog";
import { useWallet } from "../ethWallet/useWallet";
import Spinner from "../designSystem/Spinner";
import { forwardRef } from "react";
import clsx from "clsx";

// const ConnectButtonHomePage = forwardRef(({ children, className, loading, px, ...props }, ref) => (
//   <button
//     ref={ref}
//     className={clsx(
//       "_Button fm-button fm-gray-button py-3 px-6 text-3xl font-bold", 
//       className
//     )}
//     disabled={loading}
//     type="button"
//     {...props}
//   >
//     <span className={loading ? "opacity-0" : null}>{children}</span>
//     {loading ? (
//       <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
//         <Spinner />
//       </span>
//     ) : null}
//   </button>
// ));

function ConnectWalletDialogTrigger({ label, loading, onComplete, onOpenChange, open, ready, homepageCta=false }) {
  const wallet = useWallet();

  // console.log('---wallet.status=', wallet.status)

  function onOpenChangeInner(isOpen) {
    onOpenChange(isOpen);

    if (wallet.status === "error" && !isOpen) {
      wallet.reset();
    }

    if (wallet.status === "connected" && !isOpen) {
      onComplete();
    }
  }

  return (
    <Dialog.Root onOpenChange={onOpenChangeInner} open={open}>
      <Dialog.Overlay />
        <div className='_ConnectWalletDialogTrigger fm-gray-button-greenify'>
          {ready && 
          <>{homepageCta ?
          <Dialog.Trigger as={Button} loading={loading}>{label}</Dialog.Trigger>
         :<Dialog.Trigger as={Button} loading={loading}>{label}</Dialog.Trigger>}</>}
        </div>
      <ConnectWalletDialog onComplete={onComplete} />
    </Dialog.Root>
  );
}

ConnectWalletDialogTrigger.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  homepageCta: PropTypes.bool.isRequired
};

export default ConnectWalletDialogTrigger;