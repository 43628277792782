/* eslint-disable no-debugger, no-unused-vars, spaced-comment, no-multi-spaces,
   no-trailing-spaces, object-curly-spacing, indent, radix, prefer-template, no-param-reassign,
   no-nested-ternary, array-callback-return */

//8#49c dateHumanizer / date-humanizer.js

const {floor, round} = Math

const dateOrNow = date => typeof date === 'undefined' ? Date.now() : date

const uZone = d => new Date(dateOrNow(d) - new Date().getTimezoneOffset() * 6E4).toISOString().split('T')

export const timeStamp = () => uZone(Date.now()).join(' ').substr(0, 19) + ' '

export const dateToHumanDateTime = date => uZone(date).join(' ').substr(0, 19)

export const dateToHumanDateTimeHM = date => uZone(date).join(' ').substr(0, 16)

export const dateToHumanDateTimeMs = date => uZone(date).join(' ').substr(0, 23)

export const dateToHumanDate = date => uZone(date)[0].substr(0, 10)

export const dateToHumanYear = date => uZone(date)[0].substr(0, 4)
export const dateToHumanMonth = date => uZone(date)[0].substr(5, 2)
export const dateToHumanDay = date => uZone(date)[0].substr(8, 2)

export const dateToHumanTime = date => uZone(date)[1].substr(0, 8)

export const dateToHumanTimeMs = date => uZone(date)[1].substr(0, 12)

export const dateToHumanTimeHM = date => uZone(date)[1].substr(0, 5)

const hms2str = (h, m, s) => {
  const lastPad2 = u => ('0' + u).slice(-2)
  return (h ? lastPad2(h) + ':' : '') + lastPad2(m) + ':' + lastPad2(s)
}

export const timeToString = date => {  //2move to common/utils if possible
  if (!date) {
    return '--:--:--'      
  }
  const [h, m, s] = [date.getHours(), date.getMinutes(), date.getSeconds()]
  return hms2str(h, m, s)
}

export const getYMDArray = (date = Date.now()) => {
  if (typeof date === 'number') { //4like NoW() or no args => NoW()
    date = new Date(date)
  }
  return [date.getYear(), date.getMonth(), date.getDay()]
}

export const getHMSMsArray = (date = Date.now()) => {
  if (typeof date === 'number') { //4like NoW() or no args => NoW()
    date = new Date(date)
  }
  return [date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()]
}
export const getHMSArray = getHMSMsArray

export const secToString = sec => {
  sec = round(sec)
  const s = sec % 60
  sec = floor(sec / 60)
  const m = sec % 60
  const h = floor(sec / 60)
  return hms2str(h, m, s)
}

export const iso2sec = iso => {
  const lower = iso.toLowerCase()
  const upper = iso.toUpperCase()
  if (lower === upper) {
    return parseInt(iso)
  }
  let unused = upper.includes('T') ? upper.split('T')[1] : upper

  let core
  const ret = {}
    
  'HMS'.split('').map(t => {
    ret[t] = 0
    if (unused.includes(t)) {
      ;[core, unused] = unused.split(t)
      ret[t] = parseInt(core)
    }
  })
  return ret.H * 3600 + ret.M * 60 + ret.S
}

export const sec2iso = (seclen, zeroFill = false) => {
  const sec = seclen % 60
  seclen = floor(seclen / 60)
  const min = seclen % 60
  seclen = floor(seclen / 60)
  const hours = seclen ? seclen + 'H' : ''
  const secstr = zeroFill ? (100 + sec).toString().substr(1) : sec ? sec + 'S' : ''
  const minstr = zeroFill 
    ? hours 
      ? (100 + min).toString().substr(1) 
      : min.toString() 
    : min 
      ? min + 'M' 
      : ''
  
  return (!zeroFill || hours) ? `PT${hours}${minstr}${secstr}` : `PT${minstr}M${secstr}S`
}
/*
const testISO = _ => {
  const testISO__ = len => {
    const iso = sec2ISO(len)
    const relen = ISO2sec(iso)
    console.log(`testISO1: ${len} -> ${iso} -> ${relen}`)
  }
  const test__ISO = iso => {
    const len = ISO2sec(iso)
    const reiso = sec2ISO(len)
    console.log(`testISO2: ${iso} -> ${len} -> ${reiso}`)
  }
  ;[0, 1, 19, 79, 599, 601, 3599, 12600, 45000].map(len => testISO__(len))
  ;['PT1H1S', 'PT4S', 'PT3H43M', 'PT24M', 'PT2H', 'PT1H3M12S', 'PT10M2S', 'PT'].map(iso => test__ISO(iso))
  //hs s hm m h hms ms -
}
//testISO()
//debugger
*/
