/* eslint-disable react/no-array-index-key, react/prop-types, prefer-template, no-param-reassign,
   no-return-assign, no-nested-ternary, no-unused-expressions */

import {useState, useEffect} from 'react'
import {useCryptoartContract, useAdapterContext} from '../../contracts/useCryptoartContract'
import {Loading, NoContent, HistoryPresentation } from './Common'


export const RecentActivity = () => {
  const {adapterState } = useAdapterContext()
  const contractAdapter = useCryptoartContract()
  const {hasLeaderboard} = contractAdapter
  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [eventState, _setEventState] = useState(0)
  const changeEventState = () => _setEventState(eventState + 1)
  contractAdapter.setLazyEventListener('global', changeEventState, 1000)

  console.log('RecentActivity', {eventState, loading, adapterState, activities: activities.length})

  useEffect(() => {
    let mounted = true
    if (adapterState) {
      contractAdapter.onReady()
        .then(() => 
          contractAdapter.getRecentActivity({limit: 30, white: ['Mint', 'Combine', 'Redeem']})
            .then(history => {
              if (mounted) {
                const historyIds = history.map(({dispId}) => dispId)
                contractAdapter.getFragments(historyIds)
                  .then(fragments => {
                    if (mounted) {
                      for (let ix = 0; ix < history.length; ix++) {
                        history[ix].fragment = {...fragments[ix], receivedAt: 0} || {}
                      }
                      // if (!contractAdapter.helpers.jsonEq(history, activities)) {
                        console.log('Will set new (changed) activities', historyIds.join(', '))
                        setActivities(history)
                      // }
                      setLoading(false)
                    }
                  })
              }
            })
        )
    } else {
      mounted && setLoading(true)
    }

    return () => mounted = false
  }, [eventState, adapterState, contractAdapter/*, activities*/]) // fu rx

  if (!adapterState) {
    console.log('adapter is not ready yet, recentActivity will skip', {adapterState})
    return null
  }

  const pagination = {from: 1, perPage: activities.length}

  return loading
    ? <Loading />
    : activities.length
      ? <HistoryPresentation {...{activities, hasLeaderboard, pagination, title: 'Recent Activity'}} />
      : <NoContent title="Recent Activity" />
}


