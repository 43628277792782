import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { useCryptoartContract } from "../contracts/useCryptoartContract";
import { useWallet } from "../ethWallet/useWallet";
import { useDialogFlow } from "../utils/useDialogFlow";
import {useToast} from "../hooks/useToast";
import { actions, initialState, reducer } from "./mintReducer";
import MintContext from "./MintContext";

function MintProvider({ children }) {
  const { account } = useWallet();
  const toast = useToast();
  const queryClient = useQueryClient();
  const contractAdapter = useCryptoartContract();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { onDialogOpenChange, openDialog, setOpenDialog } = useDialogFlow();

  const { status } = state;
  useEffect(() => {
    if (
      status === "SUCCESS" &&
      (openDialog === "PURCHASE" || openDialog === null)
    ) {
      setOpenDialog("SUCCESS");
    }

    if (
      status === "ERROR" &&
      (openDialog === "PURCHASE" || openDialog === null)
    ) {
      setOpenDialog("ERROR");
    }
  }, [setOpenDialog, status, openDialog]);

  const location = useLocation();
  useEffect(function onLocationChange() {
    if (!location.pathname.startsWith("/wallet")) {
      dispatch({ type: actions.CLEAR_RECENTLY_MINTED });
    }
  }, [location]);

  const mint = useCallback(
    async n => {
      dispatch({ type: actions.TRANSACTION_REQUESTED, n });

      try { // CHK _recently_ minted tokens
        await contractAdapter.mint(account, n, {
          onConfirm: () => dispatch({ type: actions.TRANSACTION_CONFIRMED }),
          onSuccess: newTokenIds =>  {
            queryClient.invalidateQueries('myTokenCount')
            queryClient.invalidateQueries('myTokens')
            dispatch({ type: actions.TRANSACTION_SUCCEEDED, recentlyMintedTokens: newTokenIds })
          },
          onError: result => {
            // if (e.code === 4001) {
            //   // eslint-disable-next-line no-console
            //   console.info("Transaction cancelled:", e);
            //
            //   // User cancelled the transaction, in which case they already know
            //   // why the transaction didn't go through. Do nothing, and let the
            //   // user click "Mint now" again if necessary.
            //   dispatch({ type: actions.TRANSACTION_CANCELLED });
            //   return;
            // }
            toast("error", result.error.error)
            dispatch({ type: actions.TRANSACTION_FAILED })
          }
        })
      } catch (e) {
        //
      }
    },
    // [account, contract, queryClient]
    [account, queryClient, contractAdapter, toast]
  );

  const reset = useCallback(function reset() {
    dispatch({ type: actions.RESET });
  }, []);

  const highlightRecentlyMinted = useCallback(function highlightRecentlyMinted() {
    dispatch({ type: actions.HIGHLIGHT_RECENTLY_MINTED });
  }, []);

  const value = useMemo(
    () => ({
      highlightRecentlyMinted,
      status: state.status,
      n: state.n,
      mint,
      onDialogOpenChange,
      openDialog,
      recentlyMintedTokens: state.recentlyMintedTokens,
      recentMintHighlightIsActive: state.recentMintHighlightIsActive,
      reset,
      setOpenDialog,
    }),
    [
      highlightRecentlyMinted,
      mint,
      onDialogOpenChange,
      openDialog,
      reset,
      setOpenDialog,
      state.recentlyMintedTokens,
      state.recentMintHighlightIsActive,
      state.status,
      state.n,
    ]
  );

  return <MintContext.Provider value={value}>{children}</MintContext.Provider>;
}

MintProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MintProvider;
