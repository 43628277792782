import { Redirect, Route } from "react-router-dom";
import { useWallet } from "../ethWallet/useWallet";
import { isMobile } from '../utils/isMobile'

/**
 * Use instead of React Router's <Route /> for pages that need an active wallet connection.
 * Triggers redirect to home page instead of rendering the route when wallet is disconnected.
 */
function ProtectedRoute (props) {
  const wallet = useWallet();

  if (wallet.status !== "connected") {
    //Trying to force the connect popup as it's not appearing otherwise:
    if (isMobile()) {
      //wallet.connect('injected') 
      setTimeout(() => wallet.connect('injected'), 500)
    } else {
      setTimeout(() => wallet.connect('injected'), 500)
    }
    return <Redirect to="/" />
  }

  return <Route {...props} />
}

export default ProtectedRoute;
