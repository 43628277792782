import { useState, useEffect } from "react";
import { useWallet } from "use-wallet";
import { getContractEnv } from '../adapter/contractEnv'

/**
 * Call useEagerConnect() to attempt auto-connection to Metamask. The intended use case is
 * on app start: under the hood this works via a useEffect() hook that runs only on mount.
 *
 * Adapted from the sample code here: https://github.com/aragon/use-wallet/issues/70#issuecomment-821257314
 *
 * Note: that sample code somehow seems to get access to web3ReactConnector() without having to
 * await the init() function of the "injected" connector. Our use case seems to be a little
 * different, so the code is adapted accordingly to initialise the connector first.
 */
function useEagerConnect() {
  const { _web3ReactContext, connectors, status } = useWallet();
  const { activate } = _web3ReactContext;
  const [tried, setTried] = useState(false);

  useEffect(() => {
    async function autoConnect() {
      const contractEnv = await getContractEnv()
      const init = connectors.injected[0];
      const injectedConnector = await init();
      const injected = injectedConnector.web3ReactConnector({
        // chainId: env.CHAIN_ID
        chainId: contractEnv.chainId
      });
      const isAuthorized = await injected.isAuthorized();

      if (isAuthorized) {
        try {
          await activate(injected, undefined, true);
          //console.log('ue_activate awaited')
        } catch (e) {
          //console.log('ue_6')
          setTried(true);
          //console.log('ue_7')
        }
      } else {
        //console.log(`💥 setTried2`, window.location.href)
        setTried(true);
        //console.log(`💥 setTried2end`, window.location.href)
      }
    }
    //console.log('ue_bef autoconnect')
    autoConnect();
    //console.log('ue_aft autoconnect')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && status === "connected") {
      setTried(true);
    }
  }, [tried, status]);

  return tried;
  // return true
}

export { useEagerConnect };
