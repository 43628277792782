/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, no-shadow, quotes, object-curly-spacing, semi,
   no-unused-expressions, spaced-comment, object-shorthand, no-bitwise, no-nested-ternary, no-underscore-dangle,
   consistent-return, no-extra-boolean-cast, jsx-quotes, no-multi-spaces, multiline-ternary, no-void,
   no-return-assign, no-restricted-syntax, prefer-template, no-multi-assign, no-param-reassign, guard-for-in,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-debugger, consistent-return,
   jsx-a11y/control-has-associated-label
*/
import React, { useState, useEffect } from "react";
/*
import { ReactComponent as ArrowLeft } from "../icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../icons/ArrowRight.svg";

const ExpandButton = ({isOpen, onClick}) => 
  <button onClick={onClick} type="button" >
    {isOpen ? <ArrowRight /> : <ArrowLeft />}
  </button>

const Collapsible = ({open, collapsible, children, className: extraCC = ''}) => {
  const [isOpen, setOpen] = useState(open)
  const base = `select-none h-full absolute top-0 right-0 overflow-y-overlay bg-offblack bg-opacity-80 px-3 py-4`

  return (
    <div className={`_Collapsible ${extraCC} ${isOpen ? '' : '.half-wit'} ${base}`}>
      {collapsible && (
        <div className="mb-2">
          <ExpandButton isOpen={isOpen} onClick={() => setOpen(!isOpen)} />
        </div>
      )}
      <div className="_CollapsibleChildren">
        {children}
      </div>
    </div>
  )
}

expo*/
const EdgeCollapsibleExpandButton = ({isOpen, isLeft, ...props}) => 
  <button className='cmd noborder coll-arr' type='button' {...props}>
    {isOpen === isLeft ? '<<' : '>>'}
  </button>

export const EdgeCollapsible = ({open, collapsible, children, className: extraCC = '', isLeft, onChange}) => {
  const [isOpen, setOpen] = useState(open)
  const base = `edge-collapsible ` + (isLeft ? 'coll-left' : 'coll-right')

  const changeOpen = () => {
    setOpen(!isOpen)
    onChange?.(isOpen)
  }

  useEffect(() => {
    isOpen
      ? document.querySelector('._CubeMainWrapper')?.classList.add('hoverable')
      : document.querySelector('._CubeMainWrapper')?.classList.remove('hoverable')

      return () => document.querySelector('._CubeMainWrapper')?.classList.add('hoverable')
  }, [isOpen])

  return collapsible 
    ? <div className={`_EdgeCollapsible ${extraCC} ${isOpen ? 'coll-expanded' : 'coll-collapsed'} ${base}`}>
        <EdgeCollapsibleExpandButton {...{isOpen, isLeft, onClick: changeOpen}} />
        <div className="_CollapsibleChildren">{children}</div>
      </div>
    : <div>{children}</div>
}

export const LeftCollapsible = props => EdgeCollapsible({...props, isLeft: true})
export const RightCollapsible = props => EdgeCollapsible({...props, isLeft: false})
