/* eslint-disable no-debugger, no-console, no-unused-expressions, spaced-comment, no-unused-vars,
   no-param-reassign */

import { contractAdapter } from '../adapter/contractAdapter'

const verifyAccount = account => account || contractAdapter.getSelectedAccount()

export const getFetchBalanceFn = ({ account }) => () => contractAdapter.balanceOf(verifyAccount(account))

// export const getFetchBalanceFn = ({ account }) => async () => {
//   const balance = await contractAdapter.balanceOf(verifyAccount(account))
//   console.log('balance awaited', {account, balance})
//   return balance
// }

// export const getRenderHTMLFn = ({ tokenId }) => () => contractAdapter.render(tokenId)
export const getLiquidityPoolValuesFn = () => contractAdapter.getLiquidityPoolAbsValues()

const logOn = false
const wlog = logOn ? console.warn : () => {}
const log = logOn ? console.log : () => {}

let fpsource = 1000
//let ofi = 0
export const getFetchTokenIdsFn = ({ account, forceAll, pageSize, offset }) => {
  const fp = fpsource++
  log('📜getFetchTokenIdsFn created', { fp, fpsource, account, forceAll, pageSize, offset })

  return async () => {
    account = verifyAccount(account)
    const myTokenCount = account ? await contractAdapter.balanceOf(account) : 0
    log('📜📜getFetchTokenIdsFn called', { fp, account, myTokenCount })

    if (account) {
      const limit = pageSize
      if (typeof contractAdapter.getFragmentsOfOwner !== 'function') {
        wlog('contractAdapter called before onReady()!!!!')
        return []
      }
      //const fragmentsPre = await contractAdapter.getFragmentsOfOwner(account, {limit: 1000, offset: 0, silent: true})
      const topList = await contractAdapter.getLeaderboard({ limit }) // for side effect in v2
      const fragments = await contractAdapter.getFragmentsOfOwner(account, {limit: 10000, offset: 0, silent: true})
      //debugger
      log("📃query.getFetchTokenIds:pagination", { fp, offset, pageSize, limit, fragments, myTokenCount })
      //contractAdapter.helpers.dumpFrogs(fragments) // tmp debug log
      return fragments
    } else {
      wlog(`getFragmentsOfOwner cannot be called for null`)
      return []
    }
  }
}

export const getOwnersFragments = async ({ account }) => {
  account = verifyAccount(account)
  const myTokenCount = account ? await contractAdapter.balanceOf(account) : 0
  log('📜📜📜getOwnersFragments called', { account, myTokenCount })

  if (account) {
    if (typeof contractAdapter.getFragmentsOfOwner !== 'function') {
      wlog('contractAdapter called before onReady()!!!!')
      return []
    }
    const limit = 10000
    const topList = await contractAdapter.getLeaderboard({ limit }) // for side effect in v2
    const fragments = await contractAdapter.getFragmentsOfOwner(account, {limit, offset: 0, silent: true})
    return fragments
  } else {
    wlog(`getFragmentsOfOwner cannot be called for null`)
    return []
  }
}

export const getFragmentsByIds = async ({ ids }) => {
  log('📜📜📜getFragmentsByIds called', { ids })

  if (ids.length) {
    if (typeof contractAdapter.getFragments !== 'function') {
      wlog('contractAdapter called before onReady()! (getFragmentsByIds)')
      return []
    }
    const limit = 10000
    const topList = await contractAdapter.getLeaderboard({ limit }) // for side effect in v2
    const fragments = await contractAdapter.getFragments(ids)
    return fragments
  } else {
    wlog(`getFragmentsByIds cannot be called for []`)
    return []
  }
}

export const getFetchLeaderboardFn = ({ limit }) =>
  async () => {
    const { topFrags } = await contractAdapter.getLeaderboard({ limit })
    log({ topFrags })
    return topFrags
  }

export const getFetchHistoryFn = ({ account, offset = 0, limit = 20 }) => {
  if (typeof offset !== 'number' || typeof limit !== 'number') {
    wlog('getHistory can be called with numbers only', {offset, limit})
    return []
  }
  return () => contractAdapter.getHistory(account, { offset, limit })
}

// export const getFetchHistoryTotalFn = ({ account }) => () => contractAdapter.getHistoryLength(account)
