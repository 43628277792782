/* eslint-disable react/sort-prop-types, react/require-default-props
*/
import PropTypes from "prop-types";
import * as Dialog from "../designSystem/Dialog";
import { ReactComponent as CrossCircled } from "../icons/CrossCircled.svg";
import Button from "../designSystem/Button";

function MintErrorDialog({ onRetry, onOpenChange, open, onClose = () => {} }) {
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Overlay />
      <Dialog.Content>
        <Dialog.Body>
          <Dialog.CloseButton onClick={onClose} />
          <div className="space-y-10 flex flex-col items-center text-center text-white">
            <div className="space-y-4 flex flex-col items-center pt-4">
              <CrossCircled height="64px" width="64px" />
              <p id="mint-success-dialog-description">Transaction failed.</p>
            </div>
            <Button onClick={onRetry}>Try again</Button>
          </div>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}

MintErrorDialog.propTypes = {
  onOpenChange: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default MintErrorDialog;
