import PropTypes from "prop-types";
import { UseWalletProvider } from "use-wallet";
import { useAdapterContext } from '../contracts/useCryptoartContract'
import EthersWalletProvider from "./EthersWalletProvider";

const WalletProvider = ({ children }) => {
  const {contractEnv} = useAdapterContext()
  console.log('🔆🔆🔆', contractEnv?.chainId)

  return (
    <UseWalletProvider chainId={contractEnv?.chainId}>
      <EthersWalletProvider>{children}</EthersWalletProvider>
    </UseWalletProvider>)
}

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WalletProvider;
