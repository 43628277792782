import Page from "../designSystem/Page";
import Link from "../designSystem/Link";

export const FaqLink = () => 
  <a href='/faq' style={{textDecoration: 'normal', color: '#fff'}} target='_faq' >FAQ</a>

export const FaqPage = () => 
  <Page>
    <div className="space-y-8 pb-12 px-12">
      <h2 className="text-4xl font-bold">FAQ</h2>
      <div className="tos">
        
        <div level='1' mead='1'>What is Fragments?</div>
        <div level='1'         >Fragments is a gamified generative art project with dynamic NFTs by VerticalCrypto Art. The artwork is generated in relation to each fragments mathematical attributes; they are partial cubes that can be combined to create a full cube. Every time you combine, a new artwork is generated according to the mathematical attributes of the fragment.</div>
        <div level='1' mead='2'>Is there an allowlist? </div>
        <div level='1'         >No, it is a public mint. </div>
        <div level='1' mead='3'>How many fragments can you mint per transaction?</div>
        <div level='1'         >5 fragments can be minted per transaction. </div>
        <div level='1' mead='4'>Which fragment will I get?</div>
        <div level='1'         >Fragments are created with random initial attributes. We use an innovative non predictable on chain reveal method.</div>
        <div level='1' mead='5'>How long will the reveal take?</div>
        <div level='1'         >Fragments can be revealed within minutes or hours, it depends on our chain magic. </div>
        <div level='1' mead='6'>What does a fragment look like?</div>
        <div level='1'         >Fragments are partial cubes and they all look similar when they are created. As soon as they are combined they evolve and update their look, according to their attributes. On chain they look like 2d SVGs showing how many squares you have filled on each face, and in your browser they are 3d generative artworks. </div>
        <div level='1' mead='7'>Can I combine any fragments?</div>
        <div level='1'         >Fragments can be combined only if they have enough empty squares on all faces to fit the combining fragments.</div>
        <div level='1' mead='8'>Does the square positions count for the combination?</div>
        <div level='1'         >No, only the square count is relevant for the combination.</div>
        <div level='1' mead='9'>How can you complete the full cube?</div>
        <div level='1'         >By combining fragments to fill up each face.</div>
        <div level='1' mead='10'>How much does combining cost?</div>
        <div level='1'         >Combining is free, you will only pay the gas for the transaction.</div>
        <p>
          <Link to="/home">Return home</Link>
        </p>
      </div>
    </div>
  </Page>
