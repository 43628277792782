/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp
*/
import PropTypes from "prop-types";
import { useWallet } from "use-wallet";
import Button from "../designSystem/Button";
import * as Dialog from "../designSystem/Dialog";
import { ReactComponent as CrossCircled } from "../icons/CrossCircled.svg";
import { ReactComponent as CheckCircled } from "../icons/CheckCircled.svg";

function ConnectWalletDialog({ onComplete }) {
  const wallet = useWallet();

  async function onConnectWallet() {
    return wallet.connect("injected");
  }

  function onRetry() {
    return wallet.reset();
  }

  //console.log({wallet})

  function renderDialogContent() {
    switch (wallet.status) {
      case "disconnected":
      case "connecting":
        return (
          <div className="space-y-10 flex flex-col items-center text-center text-white">
            <p id="connect-wallet-dialog-description">
              Connect your Ethereum wallet to use Fragments.
            </p>

            <Button
              loading={wallet.status === "connecting"}
              onClick={onConnectWallet}
              darkBorder={true}
            >
              Connect wallet
            </Button>
          </div>
        );
      case "connected":
        return (
          <div className="space-y-10 flex flex-col items-center text-center text-white">
            <div className="space-y-4 flex flex-col items-center pt-4">
              <CheckCircled height="64px" width="64px" />
              <p id="connect-wallet-dialog-description">
                Your wallet is now connected.
              </p>
            </div>

            <Button onClick={onComplete}>OK</Button>
          </div>
        );
      case "error":
        return (
          <div className="space-y-10 flex flex-col items-center text-center text-white">
            <div className="space-y-4 flex flex-col items-center pt-4">
              <CrossCircled height="64px" width="64px" />
              <p id="connect-wallet-dialog-description">
                Failed to connect to wallet.
              </p>
              {wallet.error && (
                <p className="text-gray-600 text-sm">{wallet.error.message}</p>
              )}
            </div>

            <Button onClick={onRetry}>Try again</Button>
          </div>
        );
      default:
        throw new Error(`Unhandled wallet status: ${wallet.status}`);
    }
  }

  return (
    <Dialog.Content
      aria-describedby="connect-wallet-dialog-description"
      aria-label="Connect wallet"
      onInteractOutside={(event) => event.preventDefault()}
    >
      <Dialog.Body>
        <Dialog.CloseButton />
        {renderDialogContent()}
      </Dialog.Body>
    </Dialog.Content>
  );
}

ConnectWalletDialog.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default ConnectWalletDialog;
