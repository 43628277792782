import { useToastStateContext } from "../../context/ToastContext";
import Toast from "./Toast";

const ToastContainer = () => {
  const { toasts } = useToastStateContext();

  return (
    <div className="_ToastContainer z-top fixed bottom-20 w-full">
      <div className="max-w-xl mx-auto">
        {
          toasts && toasts.map(toast => (
            <Toast
              key={toast.id}
              id={toast.id}
              message={toast.message}
              type={toast.type}
            >
              {toast.children}
            </Toast>
          ))
        }
      </div>
    </div>
  )
}

export default ToastContainer