/* eslint-disable react/prop-types, prefer-template, no-void */

import { useRef, useEffect, useState } from 'react'
import { contractAdapter } from "../adapter/contractAdapter";

const ixa = [0, 1, 2, 3, 4, 5]
const faceLimit = [9, 16, 25, 36, 49, 64]
const faceCol = ['#ff003c', '#ff7b00', '#ffcd00', '#5ED723', '#1E63FF', '#ba0dbe', '#ffffff']

const Gauge = ({faceIx, frag, fullWidth = 452}) => {
  const act = frag.isReady ? frag.squareCount?.[faceIx] || 0 : 0

  const color = faceCol[faceIx]
  const max = faceLimit[faceIx]
  const wi = fullWidth / (max - 0)
  const shad = [...new Array(max + 1)]
    .map((_, ix) => `${wi * ix}px 0 0 0 ${ix <= act ? color : '#44444440'}`).join(', ')

  const hia = [2, 2, 2, 2, 2, 2] // [2, 3, 3, 4, 4, 5]  
  const css = {
    boxShadow: shad,
    width: (wi-1) + 'px',
    height: hia[faceIx] + 'px',
    marginLeft: -wi + 'px',
    marginBottom: '1px'
  }
  return <div className='gauge-line' style={{...css}} />
}

const useFullWidth = rowRef => {
  const [fullWidth, setFullWidth] = useState()

  const resizeRow = contractAdapter.helpers.startEndThrottle(() => {
    // console.log('setting fullWidth', rowRef.current.clientWidth - 16)
    setFullWidth(rowRef.current.clientWidth - 16)
  }, 1000)
  
  useEffect(() => void resizeRow())

  useEffect(() => {
    window.addEventListener('resize', resizeRow)

    return () => window.removeEventListener('resize', resizeRow)
  })

  return [fullWidth]
}

export const FragmentColorRow = ({frag = {}, extraStyle = {}}) => {
  const rowRef = useRef()
  const [fullWidth] = useFullWidth(rowRef)
  // const [fullWidth, setFullWidth] = useState()

  // const resizeRow = contractAdapter.helpers.startEndThrottle(() => {
  //   console.log('setting fullWidth', rowRef.current.clientWidth - 16)
  //   setFullWidth(rowRef.current.clientWidth - 16)
  // }, 1000)
  
  // useEffect(() => void resizeRow())

  // useEffect(() => {
  //   window.addEventListener('resize', resizeRow)

  //   return () => window.removeEventListener('resize', resizeRow)
  // })

  return (
    <tr ref={rowRef} className='_FragmentColorRow data-dia-row' style={extraStyle}>
      <td>
        <div>{ixa.map(faceIx => <Gauge key={faceIx} {...{faceIx, frag, fullWidth}} />)}</div>
      </td>
    </tr>
  )
}

export const FragmentColorBar = ({frag = {}, extraStyle = {}}) => {
  const rowRef = useRef()
  const [fullWidth] = useFullWidth(rowRef)
  // const [fullWidth, setFullWidth] = useState()

  // const resizeRow = contractAdapter.helpers.startEndThrottle(() => {
  //   setFullWidth(rowRef.current.clientWidth - 16)
  // }, 1000)
  
  // useEffect(() => void resizeRow())

  // useEffect(() => {
  //   window.addEventListener('resize', resizeRow)

  //   return () => window.removeEventListener('resize', resizeRow)
  // })

  return (
    <div ref={rowRef} className='_FragmentColorBar data-dia-row' style={extraStyle}>
      <div className='gauge-group'>{ixa.map(faceIx => <Gauge key={faceIx} {...{faceIx, frag, fullWidth}} />)}</div>
    </div>
  )
}