/* eslint-disable react/prop-types, no-void, jsx-quotes, quotes */

import React, {useEffect} from 'react'
import {CubeMain} from 'cryptoart_cube/src/components/react/CubeMain'
import {useToast} from '../hooks/useToast'
import {Corelib} from '../adapter/improxy-red'
import {isMobile} from "../utils/isMobile";
import { FaTruckMonster } from 'react-icons/fa';

const {getRnd} = Corelib

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) { // eslint-disable-line
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn('ErrorBoundary', error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Could not render cube.</h1>
    }

    return this.props.children
  }
}

export const CubeMainWrapper = props =>
 
  ( isMobile() ? (
    <ErrorBoundary>
      <div className='_CubeMainWrapperMobile hoverable'>
      <CubeMain cameraFov={85} {...props} />
      </div>
    </ErrorBoundary>
    ):(
    <ErrorBoundary>
      <div className='_CubeMainWrapper hoverable'>
      <CubeMain cameraFov={85} {...props} />
      </div>
    </ErrorBoundary>
    ))

export const CubeMainWrapperMyFragmentListPage = props =>
    <ErrorBoundary>
      <div className='_CubeMainWrapperMyFragmentListPage hoverable'>
      <CubeMain cameraFov={44} {...props} />
      </div>
    </ErrorBoundary>     

export const CubeMainWrapperHomePage = props =>
    <ErrorBoundary>
      <div className='_CubeMainWrapperHomePage hoverable'>
      <CubeMain cameraFov={70} {...props} />
      </div>
    </ErrorBoundary> 

export const CubeMainWrapperCombinations = props =>
<ErrorBoundary>
  <div className='_CubeMainWrapperCombinations hoverable'>
  <CubeMain cameraFov={70} {...props}  disableZoom={true}/>
  </div>
</ErrorBoundary> 

  
export const PendingCube = () => {
  const toast = useToast()

  useEffect(() => toast('success', `Please now wait for some on-chain magic to reveal your fragments.`, 60E3))

  return <CubeMainWrapper key="CubeMain" disableZoom={false} />
}

export const RandomCube = ({backgroundColor} = {}) => {
  void backgroundColor
  const frag1Config = [9, 16, 25, 36, 49, 64].map(x => getRnd(0, x))
  return <CubeMainWrapperHomePage key="CubeMain" disableZoom={true} {...{frag1Config}} />
}
export const RandomCube2 = ({backgroundColor} = {}) => {
  void backgroundColor
  
   const toast = useToast()

  const frag1Config = [9, 16, 25, 36, 49, 64].map(x => getRnd(0, x))

   useEffect(() => toast('success', `Displaying a random fragment.`, 60E3))

  return <CubeMainWrapper key="CubeMain" disableZoom={false} {...{frag1Config}} />
}


export const CubeWrapper = ({children}) => 
  <div className="_CubeWrapper flex flex-grow flex-col overflow-hidden">
    <div className="_CubeChildrenWrapper w-full h-full relative ">
      {children}
    </div>
  </div>
