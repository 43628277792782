import React from "react";
import PropTypes from "prop-types";

const Title = props => (
  <h2 className="fm-terminal-font text-sm text-green-452 mb-2">{props.text}</h2>
)

Title.propTypes = {
  text: PropTypes.string.isRequired
};

export default Title;