/* eslint-disable spaced-comment, no-console, quotes, object-curly-spacing, no-void, 
   no-unused-expressions, prefer-template, no-return-assign, react/prop-types, camelcase
*/

// rem orphan version of contractConfig, for the dApp with fixed path: public/contracts

export const contractRoot = '/contracts'

export const getContractsNodeRoot = () => './public' + contractRoot
export const getContractsWebRoot = () => contractRoot

export const envLine2obj = envLine => {
  const [
    id, datetime, version, network, chainId, contractName, size, hash, address, recAddress
  ] = envLine.split(':')
  const desc = {id, datetime, version, network, chainId, contractName, size, hash, address, recAddress}
  desc.chainId = ~~desc.chainId
  desc.urlPath = `${getContractsWebRoot()}/${id}-${datetime}/${contractName}.json`
  desc.filePath = './public' + desc.urlPath
  desc.envStr = envLine
  return desc
}

export const obj2envLine = conf => {
  const {id, datetime, version, network, chainId, contractName, size, hash, address, recAddress} = conf
  return [
    id, datetime, version, network, chainId, contractName, size, hash, address, recAddress
  ].join(':')
}

export const getLatestNodeConfig = async fsp => {
  const latest = await fsp.readFile(`${getContractsNodeRoot()}/latest.db`)
  console.log('getLatestConfig_node', latest.toString())
  return latest.toString()
}

const _getLatestWebConfig = async prefix => {
  const latest = await (await fetch(`${prefix}${getContractsWebRoot()}/latest.db`)).text()
  console.log('getLatestConfig_browser', latest.toString())
  return latest.toString()
}

export const getLatestWebConfig = async () => _getLatestWebConfig('')

export const getLatestWebSubConfig = async () => _getLatestWebConfig('../public')