/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, no-unused-vars, spaced-comment
*/
import MintSuccessDialog from "./MintSuccessDialog";
import MintErrorDialog from "./MintErrorDialog";
import { useMint } from "./useMint";

function MintFeedbackDialogs() {
  const { highlightRecentlyMinted, n, openDialog, reset, setOpenDialog } = useMint();

  function onDialogOpenChange(dialogName, isOpen) {
    setOpenDialog(isOpen ? dialogName : null);
  }

  function onNavigateToWallet() {
    // window.location.href = '/wallet'
    setOpenDialog(null);
    highlightRecentlyMinted();
    reset();
  }

  function onRetry() {
    reset();
    setOpenDialog("PURCHASE");
  }

  return (
    <>
      <MintSuccessDialog
        n={n}
        onNavigateToWallet={onNavigateToWallet}
        onOpenChange={(isOpen) => onDialogOpenChange("SUCCESS", isOpen)}
        open={openDialog === "SUCCESS"}
        onClose={reset}
      />
      <MintErrorDialog
        onOpenChange={(isOpen) => onDialogOpenChange("ERROR", isOpen)}
        onRetry={onRetry}
        open={openDialog === "ERROR"}
        onClose={reset}
      />
    </>
  );
}

export default MintFeedbackDialogs;
