/* eslint-disable react/prop-types, react/no-array-index-key */

import ColoredSquare from "../designSystem/ColoredSquare";
import {contractHelpers} from '../adapter/contractHelpers'

const {tileLimits} = contractHelpers

let rnd = 12

const FragmentColorChart = ({fragmentId = '-', squareCount, isReady, isCalculator=false}) => {
  // rnd++
  const key = `colour_${fragmentId}__${rnd++}`

  return isReady ? (
    <div className="grid grid-cols-6 gap-2">
      {["#ff003c", "#ff7b00", "#ffcd00", "#5ED723", "#1E63FF", "#ba0dbe"].map((name, i) => (
        <ColoredSquare key={key + i} color={name} maxi={tileLimits[i]} value={squareCount[i]} isCalculator={isCalculator}/>
      ))}
    </div>
  ) : (
    <div className="grid grid-cols-6 gap-2">
      {["#666", "#666", "#666", "#666", "#666", "#666"].map((name, i) => (
        <ColoredSquare key={key + i} color={name} maxi={tileLimits[i]} value={0} isCalculator={isCalculator}/>
      ))}
    </div>
  ) 
}

export default FragmentColorChart
