/* eslint-disable react/no-array-index-key, react/prop-types, prefer-template, no-param-reassign,
   no-return-assign, no-nested-ternary, no-unused-expressions */

import Spinner from '../../designSystem/Spinner'
import Title from '../../designSystem/Title'
import FragmentColorChart from '../../wallet/FragmentColorChart'
import {FragmentColorRow} from '../../wallet/FragmentColorRow'
import {ReactComponent as Opensea} from '../../icons/Opensea.svg'
import {isMobile} from '../../utils/isMobile'
import { FaEllipsisH } from "react-icons/fa";
import { useToast } from "../../hooks/useToast"

export const Loading = () => 
  <div className="flex flex-col items-center text-center h-full w-50 mb-auto mt-auto mx-auto pt-4">
      <div className="p-3 m-2 text-gray-200">
          <Spinner />
      </div>
  </div>

export const NoContent = ({ title }) => 
  <div>
      <Title text={title} />
      <div className="mt-2 text-xs text-gray-200">
          There is nothing to show here yet.
      </div>
  </div>



const Operation = ({activity, hasLeaderboard}) => ({
  mint: <span className="text-blue-500">Minted</span>,
  redeem: <span className="text-green-500">
      {`Redeemed${hasLeaderboard ? activity.lb ? ' (lb)' : ' (gen)' : ''}`}
    </span>,
  combine: <span className="text-pink-500">Combined</span>,
})[activity.op.toLowerCase()] || <span className="text-red-500">Unknown</span>

const Fullness = ({frag}) => <>{frag?.fullnessFloat > 0 ? frag.fullnessFloat.toFixed(3) : 0}</>

const Score = ({ fragment }) => <>{(fragment?.score || 0).toFixed(3)}</>

export const HistoryPresentation = ({pagination, activities, hasLeaderboard, title}) => {
  const from = pagination.from - 1
  const to = from + pagination.perPage
  const toast = useToast()

  const onDisplayDate = (Date) => {
     toast('success','Date : \n'+ Date, 60E3)
  }  
  return (
    <>
      {title && <Title text={title} />}
      <div className='_MyFragmentsListBody custom-scrollbar h-full ' >
      <table className="table-fixed fm-table text-gray-200 text-xs text-left ">
        <thead>
          <tr>
            <th className="fm-table-header pr-2">ID</th>
            <th className="fm-table-header pr-2">Properties</th>
            <th className="fm-table-header pr-2"> Score</th>
            <th className="fm-table-header pr-2">Action</th>
            <th className="fm-table-header">Date</th>
            <th className="fm-table-header pr-2"><Opensea fill="#ffffffd0"/></th>
          </tr>
        </thead>
        <tbody>
          {console.log({activities})}
          {activities.slice(from, to).map((activity, ix) => [
            <tr key={ix} className='data-text-row'>
              <td className="py-2 pr-3 text-gray-500">
                {/* {console.log(activity)} */}
                {activity.dispId}
              </td>
              <td className="pr-2">
                <FragmentColorChart
                  fragmentId={activity.dispId}
                  isReady={activity.fragment?.isReady}
                  squareCount={activity.fragment?.squareCount}
                />
              </td>
              <td className="pr-2 font-bold">
                <Score fragment={activity.fragment} />
              </td>
              <td className="pr-2">
                <Operation activity={activity} />
              </td>
             

              <td className="pr-2 text-center" >
              {/* style={{minWidth: '90px'}}  */}
              {isMobile()? 
              (<FaEllipsisH onClick={()=>{
                onDisplayDate(activity.humanDate);
              }} />) :
              (<>{activity.humanDate}</>)
              }
              </td>
              <td >
                <a href={`https://opensea.io/assets/ethereum/0xdEA65Dd08eB6d51fb23038322376897C54668964/${activity.dispId}`}
                 target="_blank" rel="noopener noreferrer" >
                <FaEllipsisH className='text-center'/>
                </a>
              </td>
            </tr>,
            <FragmentColorRow key={ix + 'fcr'} frag={activity.fragment} />
          ]).flat()}
        </tbody>
      </table>
      </div>
    </>
  )
}

export const TopListPresentation = ({pagination, activities, hasLeaderboard, title}) => {
 
  const from = pagination.from - 1
  const to = from + pagination.perPage
  let i=1;
  return (
    <>
      {title && <Title text={title} />}
      <table className="table-fixed fm-table text-gray-200 text-xs text-center">
        <thead>
          <tr>
            <th className="fm-table-header pr-2">Position</th>
            <th className="fm-table-header pr-2">ID</th>
            <th className="fm-table-header pr-2">Properties</th>
            <th className="fm-table-header pr-2">Score</th>
            <th className="fm-table-header pr-2">Combined</th>
            <th className="fm-table-header pr-2">Owner</th>
            <th className="fm-table-header text-center">Opensea</th>
          </tr>
        </thead>
        <tbody>
          {console.log({activities})}
          
          {activities.map((activity, ix) => [

            <tr key={ix} className='data-text-row '
                style={{backgroundColor: (activity.owner === "owned by you")? "#cfa50e" : "" }}>
               <td className="py-2 pr-3 ">
                {i++}
              </td>
              <td className="py-2 pr-3 ">
                {activity.id}
              </td>
              <td className="pr-2">
                <FragmentColorChart
                  fragmentId={ix}
                  isReady={activity.isReady}
                  squareCount={activity.squareCount}
                />
              </td>
              <td className="pr-2 ">
              <Score fragment={activity} />
              </td>
              <td className='tc'>{activity.combined ? 'Y' : 'N'}</td>
              <td className="pr-2">
                 { activity.owner}
              </td>
              <td className="flex text-center justify-center" > 
              <a href={`https://opensea.io/assets/ethereum/0xdEA65Dd08eB6d51fb23038322376897C54668964/${activity.id}`}
                 target="_blank" rel="noopener noreferrer">
                <FaEllipsisH />
                </a>
              </td>
            </tr>,
            <FragmentColorRow key={ix + 'fcr'} frag={activity} />
          ]).flat()}
        </tbody>
      </table>
    </>
  )
}



// const RecentActivityPresentation = ({ activities }) => (
//   <>
//     <Title text="Recent Activity" />
//     <table className="table-fixed fm-table text-left text-xs miwi-full">
//       <thead>
//         <tr>
//           <th className="fm-table-header">ID</th>
//           <th className="fm-table-header">Properties</th>
//           <th className="fm-table-header">Action</th>
//           <th className="fm-table-header">Date</th>
//         </tr>
//       </thead>
//       <tbody>
//         {/* {console.log({activities})} */}
//         {activities.map((activity, ix) => [// eslint-disable-next-line
//           <tr key={ix}>
//             <td className="py-2 pr-2 text-gray-500">
//               {activity.dispId}
//             </td>
//             <td className="py-2 pr-2">
//               <FragmentColorChart
//                 fragmentId={activity.fragment.dispId}
//                 isReady={activity.fragment.isReady}
//                 squareCount={activity.fragment.squareCount}
//               />
//             </td>
//             <td className="py-2 pr-2">
//               <Operation activity={activity} />
//             </td>
//             <td className="py-2">
//               {activity.humanDate}
//             </td>
//           </tr>,
//           <FragmentColorRow key={ix + 'fcd'} frag={activity.fragment} />
//         ]).flat()}
//       </tbody>
//     </table>
//   </>
// )
