import {TosLink} from '../pages/TosPage'

function Footer() {
  return (
    <footer 
      className="flex flex-col space-y-2 md:flex-row md:space-y-0 p-3 bg-offblack text-xs text-gray-200 z-20"
      style={{
        borderTop: "1px solid #228b22"
      }}
    >
      <div className="c-frag-nft">© Fragments NFT by
       <a href='https://www.verticalcrypto.art/' target="_blank" rel="noopener noreferrer"
              className='cursor-pointer pl-1 '><u>VerticalCrypto Art</u>
        </a>.
      </div>
      <div className="flex-grow" />
      <div className="text-xs text-green-dark">
        <TosLink />
        {/* <span className="font-bold mx-2">·</span> */}
      </div>
    </footer>
  )
}

export default Footer;
