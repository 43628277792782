/* eslint-disable react/prop-types */

import React from 'react'

const ColoredSquare = ({ color, value, maxi, isCalculator }) => (
 
  <div className={`col-square flex items-center place-content-center text-offblack ${isCalculator ? "text-xs" :"text-sm font-bold"}`} >
    {value}<em style={{color}}>/{maxi}</em>
  </div>
)

export default ColoredSquare