import * as Dialog from "../designSystem/Dialog";
import Button from "../designSystem/Button";
import DownloadWalletDialog from "../ethWallet/DownloadWalletDialog";

function DownloadWalletDialogTrigger() {
  return (
    <Dialog.Root>
      <Dialog.Overlay />
      <Dialog.Trigger as={Button}>Connect Wallet</Dialog.Trigger>
      <DownloadWalletDialog />
    </Dialog.Root>
  );
}

export default DownloadWalletDialogTrigger;