import React from 'react'
import { About } from '../components/About'
import { Sidebar } from '../components/Sidebar'
import { RandomCube2, CubeWrapper} from '../components/CubeComponents'
import {isMobile} from '../utils/isMobile'
import {useWallet} from '../ethWallet/useWallet'


const RecentActivityPage = () =>{
  const wallet = useWallet()
 
  return(

      <div className='page-mid-base'>
        <CubeWrapper>
          <RandomCube2 backgroundColor="#111111" />
          <Sidebar isMobile={isMobile()}/>
          <About />
        </CubeWrapper>
      </div>
    
  )
}
export default RecentActivityPage
