/* eslint-disable spaced-comment, no-console, quotes, object-curly-spacing, no-void, 
   no-unused-expressions, prefer-template, no-return-assign, react/prop-types
*/

import { env } from '../env.js'
import {contractRoot, envLine2obj} from './contractConfig'

const readOnRun = false

const _getContractEnv = async () => {
  if (readOnRun) { // read latest
    const str = await fetch(`${contractRoot}/latest.db`)
      .then(response => response.text())
      .catch(err => console.error('Error reading latest.db', err))

    console.log({str})
    return envLine2obj(str)
  } else {
    return envLine2obj(process.env.REACT_APP_CONTRACT)
  }
}

let contractEnv

export const getContractEnv = async () => contractEnv || (contractEnv = await _getContractEnv())

console.log(`env used:`, {env, contractEnv}, process.env)

export { env }