import { useState, useEffect } from "react";
import { useCryptoartContract, useAdapterContext } from "../contracts/useCryptoartContract";

const useOperations = () => {
  const contractAdapter = useCryptoartContract();
  const [dropState, setDropState] = useState({});
  const {adapterState} = useAdapterContext()

  useEffect(() => {
    const fetchData = async () => contractAdapter.hasDrops
      ? setDropState({ ...await contractAdapter.getControlFlags() })
      : setDropState({ mintOn: true, combineOn: true, genRedeemOn: true })

    fetchData()
      .catch(console.error)
  }, [contractAdapter, adapterState])

  return dropState;
}

export default useOperations;