// @ts-nocheck
/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, no-shadow, quotes, object-curly-spacing, semi,
   no-unused-expressions, spaced-comment, object-shorthand, no-bitwise, no-nested-ternary, no-underscore-dangle,
   consistent-return, no-extra-boolean-cast, jsx-quotes, no-multi-spaces, multiline-ternary, no-void,
   no-return-assign, no-restricted-syntax, prefer-template, no-multi-assign, no-param-reassign, guard-for-in,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-debugger, consistent-return,
   jsx-a11y/control-has-associated-label
*/
import React, {useEffect, useState} from 'react'
import {mergeCubesConclusion, mergeCubesIntro} from 'cryptoart_cube/src/components/react/CubeMain'
//} from '../../utils/CubeMock'
import {PendingCube, CubeWrapper, CubeMainWrapper} from '../../components/CubeComponents'
import {useCryptoartContract, useAdapterContext, useOpsAccess} from '../../contracts/useCryptoartContract'
import {useWallet} from '../../ethWallet/useWallet'
import {getOwnersFragments, getFragmentsByIds} from '../../wallet/queries'
import {Sidebar} from '../../components/Sidebar'
import Spinner from '../../designSystem/Spinner'
import {useToast, useToastComposite} from '../../hooks'
import {MxConfirm} from '../../components/MxDia'
import MobileMintButton from '../../mint/MobileMintButton'
import {isMobile} from '../../utils/isMobile'
import {Corelib} from '../../adapter/improxy-red'
import FragmentColorChart from '../../wallet/FragmentColorChart'
import {FragmentColorBar} from '../../wallet/FragmentColorRow'
import {useMint} from '../../mint/useMint'
import MyFragmentsList from './MyFragmentsList'
import CalculatorButton from '../../calculator/CalculatorButton'

const {wassert, weject} = Corelib.Debug
void wassert + weject

const SelectFragment = () => // never
  <div className='h-full mb-auto mt-auto mx-auto text-center pt-4'>
    Please select a fragment.
  </div>

const NoFragments = () => 
  <div className='_NoFragments fixed w-full items-center text-center mb-auto mt-32 mx-auto pt-4'>
    <div className='p-3 m-2 text-red-500'>
      You have no fragments.
    </div>
  </div>

const Loading = () =>
  <div className='_Loading fixed w-full items-center text-center mb-auto mt-32 mx-auto pt-4'>
    <div className='p-3 m-2 text-red-500'>
      Loading fragments...
    </div>
    <Spinner />
  </div>

void Loading  



// REM debug, useStateExt

const openCubeWindow = (primaryCubeFrag, secondaryCubeFrag, previewCube) => {
  const {squareCount: s1 = [], combined: c1 = false} = primaryCubeFrag || {}
  const {squareCount: s2 = [], combined: c2 = false} = secondaryCubeFrag || {}

  window.open([
    `https://cube.fragmentsnft.xyz/?config=${s1?.join(',')}&isCombined=${c1}&previewCube=${previewCube}`,
    secondaryCubeFrag ? `&config2=${s2?.join(',')}&is2Combined=${c2}` : ``
  ].join(''), 'cube')
}
void openCubeWindow

const ulog = console.log

const useXhash = window.ux = {}

const useStateExt = (initial, name = 'anon') => {
  //const usX = (useXhash[name] ??= {history: []})
  const usX = (useXhash[name] = useXhash[name] || {history: []}) // for the medieval era react webserver
  const [state, _setter] = useState(initial)

  const setter = newState => {
  // usX.setter ??= newState => {
    const hasChanged = state !== newState
    ulog(`setter(${name}) called, ${hasChanged ? '🔶' : '🔷'}`, {state, newState})
    if (name === 'upcomingSelectedId' && state !== -1 && newState === -1) {
      debugger
    }
    // console.trace()
    usX.history.push({ts: Date.now(), hasChanged, state, newState})
    _setter(newState)
  }

  return [state, setter] 
}

const lookupPageLoc = (lookupId, fragList, pageSize) => {
  for (let ix = 0; ix < fragList.length; ix++) {
    if (fragList[ix].id === lookupId) {
      return {inPage: ~~(ix / pageSize) + 1, inPos: (ix % pageSize) + 1}
    }
  }
  return {inPage: 0, inPPos: 0}
}

const stateByKey = {}
const logChanges = (obj, name) => {
  if (!stateByKey[name]) {
    stateByKey[name] = {...obj}
    console.log(`📒📘stateByKey[${name}] created`, stateByKey[name])
    return
  }
  const ref = stateByKey[name]
  for (const key in obj) {
    if (ref[key] !== obj[key]) {
      console.log(`📕📘${key} changed:`, ref[key], obj[key])
    }
  }
  stateByKey[name] = {...obj}
}

// PUB start_________________________________________________________

const MyFragmentsPage = () => {
  const wallet = useWallet()
  const contractAdapter = useCryptoartContract()
  const {eth} = contractAdapter?.helpers || {}
  const {adapterState} = useAdapterContext()
  const toast = useToast()
  const toastComposite = useToastComposite()
  const [alien, setAlien] = useState('')
  const [alienList, setAlienList] = useState([])
  const opsAccessState = useOpsAccess() // fix availableOps, useAvailableOps
  const [isCombinePanelMode, setCombinePanelMode] = useStateExt(false, 'combinePanelMode')
  const [selectedId, setSelectedId] = useState(-1)
  const [fragList, setFragList] = useState([])
  const [primaryCubeFrag, setPrimaryCubeFrag] = useState(null)
  const [secondaryCubeFrag, setSecondaryCubeFrag] = useState(null)
  const [previewCube, setPreviewCube] = useState(false)
  const [confirmRedeemFrag, setConfirmRedeemFrag] = useState(null)
  const [_loadingState, setLoadingState] = useState('loading')
  const isError = _loadingState === 'error'
  const isLoading = _loadingState === 'loading'
  const isDone = _loadingState === 'done'
  const [isCollapsed, setCollapsed] = useState(false)
  const {openDialog: mintDialogState} = useMint()
  const [hideSvg, setHideSvg] = useState(false)
  

  // REM Force refetch / redraw

  const [forcedFragFetchCnt, _setForcedFragFetch] = useState(0)
  // const [forcedRedrawCnt, _setForcedRedraw] = useState(0)
  const forceFragFetch = () => _setForcedFragFetch(forcedFragFetchCnt + 1)
  // const forceRedraw = () => _setForcedRedraw(forcedRedrawCnt + 1)

  const changeEventState = (...args) => {
    if (args[0] === 'Ready') {
      const id = ~~args[1][0]
      if (idHash[id]) { // eslint-disable-line
        forceFragFetch()
      }
    }
  }
  contractAdapter.setLazyEventListener('myfrags', changeEventState, 2000)

  // REM paging

  const [page, setPage] = useStateExt(1, 'page')
  const [pageSize, setPageSize] = useStateExt(5, 'pageSize')
  void setPageSize
  const [pageFollowMode, setPageFollowMode] = useStateExt(false, 'pageJump')

  // Debug

  const [__forceSimpleCube, __setForceSimpleCube] = useState(false)
  const __invertForceSimpleCube = () => {
    __setForceSimpleCube(!__forceSimpleCube)
    forceFragFetch()
  }
  void __invertForceSimpleCube
  
  // REM Sorting
  
  const [sortingState, setSortingState] = useState({by: 'id', asc: false})
  const sortLabels = ['id', 'combined', 'redeem', 'score', 'predscore', 'overlap']
  
  // INT End of states

  // REM selection helpers

  const getFragById = id => {
    if (id > -1) {
      for (let ix = 0; ix < fragList.length; ix++) {
        if (fragList[ix].id === id) {
          return fragList[ix]
        }
      }
    }
    return null
  }

  const setSelectedIdById = id => { // the single point of changing selectedId
    const frag = getFragById(id)
    if (frag) {
      // console.log('setSelectedIdById:', {id, frag})
      setSelectedId(id)
    } else {
      console.error('cannot find frag for selId', selectedId)
    }
  }

  const onSelectIdByFrag = frag => {
    if (frag) {
      setSelectedIdById(frag.id)
    } else {
      console.error('setselbyFrag cannot select from undef frag')
    }
  }

  // INT main

  const {by, asc} = sortingState
  
  if (fragList.length) {
    const sign = asc ? -1 : 1
    const prop = {predscore: 'predScore', overlap: 'hasOverlap', redeem: 'redeemValue'}[by] || by
    // const hashBefore = fragList.map(f => f.id).join(',')
    fragList.sort((a, b) => (b[prop] - a[prop]) * sign)
    // const hashAfter = fragList.map(f => f.id).join(',')
    // console.log(`fragList sorted, changed=${hashBefore === hashAfter}`, {fragList})
    // hashBefore !== hashAfter && forceRedraw()
  }

  // we have a sorted fragList here

  const idHash = {}
  for (const frag of fragList) {
    idHash[frag.id] = frag
  }

  if (!getFragById(selectedId)) { // invalid selectedId
    if (fragList.length) {
      // console.log('setting selected id to the first frag:', fragList[0].id)
      setSelectedIdById(fragList[0].id)
    } else {
      // console.log('cannot set selected id to the first frag as no fragList')
    }
  }

  // we have a selectedId (or -1) here
  
  const selectedFrag = getFragById(selectedId)
  if (selectedId !== -1) {
    if (selectedFrag !== primaryCubeFrag || primaryCubeFrag?.id !== selectedId) {
      // console.log('main will set primary', {primaryCubeFrag, selectedId, selectedFrag})
      setPrimaryCubeFrag(selectedFrag)
    }
  }

  // we have a selected frag (or null) here

  const {inPage, inPos} = lookupPageLoc(selectedId, fragList, pageSize)
  if (pageFollowMode && page !== inPage) {
    console.log({inPage, inPos, pageFollowMode})
    setPage(inPage)
  }

  useEffect(() => {
    let mounted = true
    setLoadingState('loading')
    contractAdapter.onReady().then(() => {
      Promise.all([getOwnersFragments({account: wallet.account}), getFragmentsByIds({ids: alienList})])
        .then(([myFragments, alienFragments]) => {
          if (mounted) {
            const fragments = [...myFragments, ...alienFragments.map(a => ({...a, isAlien: true}))]
            // console.log({fragments})
            // for (const frag of fragments) { // fix set fullness and redeemvalue string
            //   frag.redeemStr = frag.combined ? frag.redeemValue.toFixed(4) : '-'
            // }
            setPrimaryCubeFrag(null)
            setFragList(fragments)
            setLoadingState('done')
           
           
          }
        })
        .catch(err => {
          console.log({...err})
          setLoadingState('error')
        })
    })
    return () => mounted = false
  }, [wallet.account, adapterState, forcedFragFetchCnt, contractAdapter, alienList, mintDialogState])

  let isCombinationInProgress = false // protection against double-triggering of click events

  const onSimulateCombinationWith = async otherFrag => {
    if (isCombinationInProgress) {
      console.log('onSimulate() will skip')
      return
    }
    console.log('onSimulate() will go')
    setSecondaryCubeFrag(otherFrag)
    setPreviewCube(true)
    
    const previewFrag = await contractAdapter.combinePreview(selectedFrag, otherFrag)
    if(previewFrag != -1){
    previewFrag.isReady = true
    const {id, squareCount, fullnessFloat, redeemValue, leaderboardPos, score} = previewFrag
  
    console.log({previewFrag})

    toastComposite('success', [
      `Simulating combination \nof fragment \n#${selectedFrag.id} and #${otherFrag.id} into #${id}`,
      // `Faces: ${squareCount.join(' ')}`,
      ...(contractAdapter.hasLeaderboard
        ? [
            `Fullness: ${fullnessFloat?.toFixed(3)}`,
            // `Rainbow Whale Redeem value: ${eth(redeemValue, 4)}`,
            `Leaderboard position: ${leaderboardPos}`
          ]
        : [
            // `Redeem value: ${eth(redeemValue, 4)}`,
            `Score: ${score.toFixed(3)}`
          ]
      ),
    ].join('\n'),
      <div className='_ToastFragSimWrapper' style={{margin: '8px 0'}}>
        <FragmentColorChart fragmentId={previewFrag.id} {...{squareCount, isReady: true}} />
        <FragmentColorBar frag={previewFrag} />
      </div>, 30E3)
    }
    else
    {
      toastComposite('Error', [
        `Cannot combine beacuse there are some overlaps`
      ].join('\n'),
        <div className='_ToastFragSimWrapper' style={{margin: '8px 0'}}>
          <FragmentColorChart fragmentId={previewFrag.id} {...{squareCount, isReady: true}} />
          <FragmentColorBar frag={previewFrag} />
        </div>, 30E3)
    }
  }

  const onCombineFragments = async otherFrag => {
    if (alienList.includes(selectedFrag.id) || alienList.includes(otherFrag.id)) {
      console.warn(`Cannot combine alien frags!`, {selectedFrag, otherFrag})
      toast('error', `You can combine only your own fragments.`, 1E15) // fix this, never gets here
      return
    }
    if(selectedFrag.id == otherFrag.id){
    console.warn(`Cannot combine a fragment with itself!`)
    return
    }
    console.log('onCombineFragments() will set setPreviewCube false, combProg true')
    isCombinationInProgress = true
    setSecondaryCubeFrag(otherFrag)
    setPreviewCube(false)
    mergeCubesIntro()
    
    contractAdapter.combine(selectedFrag.id, otherFrag.id)
      .then(result => {
        if (result.wasOk) {
          const newId = result.returned?.ids?.[0]
          console.log('Result of combination', {newId, result})
          mergeCubesConclusion(() => {
            toast('success', `Successfully merged fragments #${selectedFrag.id} and #${otherFrag.id} into #${newId}. Now check your position in leaderboard.`);
            forceFragFetch()
            console.log('after combine set select by id', newId)
            setSelectedIdById(newId)
            setPageFollowMode(true)
            console.log('setPageJump=true')
            onLeaveCombinePanelMode() // eslint-disable-line no-use-before-define
          })
        } else {
          const reason = result?.error?.error || '-'
          toast('error', `Error merging fragments, reason: ${reason}`)
          setSecondaryCubeFrag(null)
        }
      })
  }

  const onConfirmRedeemFragment = setConfirmRedeemFrag

  const onRedeemFragment = async (fragment, useLbValue) => {
    setConfirmRedeemFrag(null)
    try {
      const result = await contractAdapter.redeem(fragment.id, {useLbValue, showPrediction: true})
      console.log('redeem', {result})
      if (result.wasError) {
        const reason = result?.error?.error || '-'
        toast('error', `Cannot (${useLbValue ? 'drop' : 'general'}) redeem fragment, reason: ${reason}`)
      } else {
        forceFragFetch()
        toast('success', `Successfully redeemed fragment for ${result?.response?.fragmentValue.toString()} ETH.`);
      }
    } catch (e) {
      toast('error', 'Error redeeming fragment.')
      console.error(e)
    }
  }

  const onPageTurn = (page, doFollow = false) => {
    setPage(page)
    setPageFollowMode(doFollow)
  }

 
  //useEffect(() => toast('success', `You need to find the best combinations to achieve the full cube and unlock rewards`, 60E3))
 

  // rem aliening

  const onAddAlien = () => {
    if (alien !== '') {
      const alienArray = []
      if (alien.includes('-')) {
        const [_from, _to] = alien.split(' ').join('').split('-')
        const [from, to] = [~~_from, ~~_to]
        for (let ix = from; ix <= to; ix++) {
          alienArray.push(ix)
        }
      } else {
        alienArray.push(~~alien)
      }
      for (const newAlien of alienArray) {
        if (alienList.includes(newAlien)) {
          console.log('duplicated alien:', alien)
          toast('error', `Cannot add an extra fragment twice.`, 15E3)
          return
        }
        if (idHash[newAlien]) {
          console.log('cannot add your own as an alien:', alien)
          toast('error', `Cannot add your own fragment as an extra.`, 15E3)
          return
        }
      }
      console.log('adding', {alienList, alienArray})
      setAlienList([...alienList, ...alienArray])
      setAlien('')
    }
  }
  const onResetAlienList = () => setAlienList([])

  const getAlienErrors = () => {
    const err = []
    for (const alienId of alienList) {
      if (!idHash[alienId] && !isLoading) {
        err.push(<div>{`Fragment #${alienId} has gone into the black hole.`}</div>)
      }
    }
    return err
  }

  const getAlienListStr = (cnt = 8) => alienList.slice(0, cnt).join(', ') + (alienList.length > cnt ? '...' : '')

  // REM Enter/leave combine mode

  const onEnterCombinePanelMode = frag => {
    console.log('----ENTER COMBINE MODE-------------', frag.id)
    setCombinePanelMode(true)
    setConfirmRedeemFrag(null)
  }

  const onLeaveCombinePanelMode = () => { // call on 1. close combine 2. done combine
    console.log('onLeaveCombine')
    setSecondaryCubeFrag(null)
    // setSelectedOtherId(-1)
    setCombinePanelMode(false)
    setConfirmRedeemFrag(null)
  }

  const onHideSvg = () => {
    setHideSvg(!hideSvg)
  }
  
  useEffect(() => {
    const coll = isCollapsed ? 'Collapsed' : ''
    isCombinePanelMode
      ? document.body.setAttribute('mode', 'combinePanel' + coll)
      : document.body.setAttribute('mode', 'fragmentList' + coll)

      // return () => document.querySelector('._CubeMainWrapper')?.classList.add('hoverable')
  }, [isCombinePanelMode, isCollapsed])

  const total = fragList.length
  const pagination = {
    total,
    perPage: pageSize,
    page,
    lastPage: Math.ceil(total / pageSize),
    from: ((page - 1) * pageSize) + 1,
    to: page * pageSize
  }
  // console.log({pagination})
  // console.log('MyFragments.Index', {pageSize}, pagination)

  useEffect(() => {
    if (isError) {
      toast('error', 'Failed to load fragments.')
      return null
    }
  }, [isError, toast])

  const stateSS = {
    wallet, walletStatus: wallet?.status,
    contractAdapter, adapterState,
    alien, alienList,
    opsAccessState, 
    selectedId, fragList,
    primaryCubeFrag, secondaryCubeFrag, previewCube, __forceSimpleCube, 
    isCombinePanelMode, confirmRedeemFrag, _loadingState, isCollapsed,
    forcedFragFetchCnt, page, pageSize, pageFollowMode, sortingState
  }

  logChanges(stateSS, 'myFragsPage.stateSS')

  // console.log([
  //   `🥁🥁 fl: ${fragList.length}`,
  //   `sId: ${selectedId}`,
  //   `sFrId: ${selectedFrag?.id}`, 
  //   `primCu: ${primaryCubeFrag}`,
  //   `preCu: ${previewCube}`,
  //   `ada: ${adapterState}`,
  // ].join(''))

  console.log(`🥁🥁🥁 MFP before render fragListLen=${fragList.length}`, {
    selectedId, selectedFrag: selectedFrag?.id, primaryCubeFrag, page, pageFollowMode,
    previewCube, isCombinePanelMode, sortingState, 
    fragList, confirmRedeemFrag: confirmRedeemFrag?.id,
    mintDialogState, forcedFragFetchCnt, adapterState
  })

  if (isCombinePanelMode) {
    contractAdapter.predictCombinedProps?.(fragList, selectedFrag)
  }

  // const o2s = obj => {
  //   const out = []
  //   for (const key in obj) {
  //     out.push(`${key}: ${obj[key]}`)
  //   }
  //   return out.join(', ')
  // }

  // const __setCubeMargin = val => document.body.setAttribute('marg', val)

  // const StateMon = () =>
  //   <div className='state-mon'>
  //     {/* <div>{`adapterState: ${adapterState}`}</div> */}
  //     {/* <div>{`loadingState: ${loadingState}`}</div> */}
  //     {/* <div>{`sortingState=${o2s(sortingState)}`}</div> */}
  //     {/* <div>{`forcedFragFetchCnt: ${forcedFragFetchCnt}`}</div> */}
  //     {/* <div>{`forcedRedrawCnt: ${forcedRedrawCnt}`}</div> */}
  //     <div>{`fragListLen: ${fragList?.length}`}</div>
  //     <div>{`selectedId/Frag: ${selectedId}/${selectedFrag?.id ?? '-'}`}</div>
  //     <div>{`primary/secondaryCubeFrag: ${primaryCubeFrag?.id ?? '-'}/${secondaryCubeFrag?.id ?? '-'}`}</div>
  //     <div>{`previewCube: ${previewCube}`}</div>
  //     <div>{`isCollapsed: ${isCollapsed}`}</div>
  //     {/* <div>{`confirmRedeemFrag: ${confirmRedeemFrag?.id}`}</div> */}
  //     <div className='inlink' onClick={__invertForceSimpleCube}>{`simpleCube: ${__forceSimpleCube}`}</div><br/>
  //     <div className='inlink' onClick={() => setPageSize(10)}>Page=10</div>&nbsp;
  //     <div className='inlink' onClick={() => setPageSize(15)}>Page=15</div>&nbsp;
  //     <div className='inlink' onClick={() => setPageSize(20)}>Page=20</div><br/>
  //     <div className='inlink' onClick={() => __setCubeMargin('margin1')}>CubeBig</div>&nbsp;
  //     <div className='inlink' onClick={() => __setCubeMargin('margin2')}>CubeMedium</div>&nbsp;
  //     <div className='inlink' onClick={() => __setCubeMargin('margin3')}>CubeSmall</div>&nbsp;
  //     {/* <div>{`dropState= ${o2s(dropState)}`}</div> */}
  //   </div>

  // sideb  list  co  comb  co  hist act abou
  // 224 vs 620 (207) 788 (263) 586  599 600
  //        396 (-17) 564 (39)  362  375
  // REM return shortcuts

  if (!contractAdapter.read) {
    return null
  }
  if (isLoading && !fragList.length) {
    return <Loading />
  }

  const alienComp = !isMobile() &&
    <>
      <div className='alien-prologue fm-terminal-font text-sm text-green-452'>
        Add fragments you don&apos;t own to this list to temporary simulate combinations.
      </div>
      <div className='alien-wrap fm-terminal-font text-sm text-green-452'>
        <div className='alien-label'>{`Enter ID (0-${contractAdapter?.stats?.totalSupply - 1}):`}</div>
        <input 
          className='alien-input'
          onChange={ev => setAlien(ev.target.value)} 
          onKeyPress={event => {
            if (event.key === 'Enter') {
              onAddAlien()
            }
          }} value={alien} />
        <div className = 'alien-cmd' onClick={onAddAlien}>{`Add ${alien}`}</div>
        <div className='alien-cmd' onClick={onResetAlienList}>Reset</div>
        <div className='alien-label'>{getAlienListStr(10)}</div>
        <div className='alien-errors'>{getAlienErrors()}</div>
      </div>
    </>

  const fragmentProps = {
    isCombinePanelMode, selectedId, selectedFrag, selectedOtherId: secondaryCubeFrag?.id, isError,// isLoading,
    onPageTurn, onEnterCombinePanelMode, onLeaveCombinePanelMode,
    onSelectIdByFrag, onCombineFragments, onSimulateCombinationWith, onConfirmRedeemFragment,
    opsAccessState, pagination, fragList, pageFollowMode, sorting: {sortingState, setSortingState, sortLabels},
    inPage, inPos, below: alienComp, isCollapsed, setCollapsed, hideSvg
  }

  const cubeMain = isDone
    ? fragList.length
      ? primaryCubeFrag // fix no need, if fraglist length -> there is always primarycube or vica versa
        ? primaryCubeFrag.isReady
          ? <CubeMainWrapper
              key={`Main${primaryCubeFrag.id}-${secondaryCubeFrag?.id}`}
              frag1Config={primaryCubeFrag.squareCount}
              isCombined={primaryCubeFrag.combined && !__forceSimpleCube}
              frag2Config={secondaryCubeFrag?.squareCount}
              is2Combined={secondaryCubeFrag?.combined && !__forceSimpleCube}
              previewCube={previewCube}
              hideControlsNot
            /> 
          : <PendingCube />
        : <SelectFragment />
      : <NoFragments />
    : <></>

  // console.log({__forceSimpleCube}, cubeMain.props)
  //openCubeWindow(primaryCubeFrag, secondaryCubeFrag, previewCube)
  
  const redeemConfirmDialog = confirmRedeemFrag &&
    <MxConfirm 
      lines={[
        'Redeeming will burn your fragment.', 
        'Are you sure you want to proceed?', 
        `Fragment id: ${confirmRedeemFrag.id}`,
        `Redeem value: ${confirmRedeemFrag.redeemValue?.toFixed(4) || 0} ETH`
      ]}
      onCancel={() => setConfirmRedeemFrag(null)}
      onConfirm={() => onRedeemFragment(confirmRedeemFrag)}
    >
      <div style={{margin: '32px 8px'}}>
        <FragmentColorChart 
          fragmentId={confirmRedeemFrag.id} 
          {...{squareCount: confirmRedeemFrag.squareCount, isReady: true}}
        />
        <FragmentColorBar frag={confirmRedeemFrag} />
      </div>
    </MxConfirm>

 

  return isMobile()
    ? (                                          // mobile
        <div className='_MyFragmentsPage-mobile'>
          <div className='mobile-grid'>
            {cubeMain}
            <div className="_MyFragList-wrapper fullrow fr-list">
              <MyFragmentsList {...fragmentProps} />
            </div>
            <div className="_Sidebar-wrapper fullrow fr-info">
              <Sidebar isMobile={true} />
            </div>
          </div>
        </div>
      )
    : (                                          // desktop
        <div className="_myFragmentsPage-desktop relative flex flex-1">
          <CubeWrapper>
            {cubeMain}
            <Sidebar previewFrag={selectedFrag} />
            <div className='absolute z-10  p-2' style={{top:"25%", width:'240px'}} >
              <CalculatorButton />
              <button  className="_MintButton fm-link-button py-1 px-2 text-lg font-bold w-full mt-2"
               style={{ border: "1px solid #228b22", padding: '.35rem .5rem'}}
               onClick={() => onHideSvg()}>{ hideSvg ? 'Show 2D Model' : 'Hide 2D Model'}</button>
            </div>
            <MyFragmentsList {...fragmentProps} />
          </CubeWrapper>
          {redeemConfirmDialog}
        </div>
    )
}

export default MyFragmentsPage
