/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, consistent-return, guard-for-in, import/extensions, no-continue,
   spaced-comment, prefer-template, import/extensions, no-await-in-loop, no-multi-assign, no-void,
   object-curly-spacing, quotes, no-trailing-spaces, indent, no-multi-spaces, object-property-newline */

/* eslint-disable no-unused-vars */

import {Corelib} from './improxy-red.js'
import {contractHelpers} from './contractHelpers.js'
import {extendContractAdapterWithStats} from './contractStats.js'
import {extendContractAdapterWithUpdates} from './contractUpdates.js'
import {extendContractAdapterWithFragCache} from './contractFragCache.js'
import {extendContractAdapterWithFrags} from './contractFrags.js'
import {extendContractAdapterWithTxs} from './contractTxs.js'
import {extendContractAdapterWithOps} from './contractOps.js'
import {extendContractAdapterWithEvents} from './contractEvents.js'
import {extendContractAdapterWithDrops} from './contractDrops.js'
import {extendContractAdapterWithHistory} from './contractHistory.js'
import {extendContractAdapterWithLiquidityPool} from './contractLiqPool.js'
import {extendContractAdapterWithLeaderboard} from './contractLeaderboard.js'
import {extendContractAdapterWithFunds} from './contractFunds.js'
import {waitForMetaMask} from './contractMetaMask.js'

const {isFun, nop} = Corelib
const {schedule, NoW, startEndThrottle, debounce} = Corelib.Tardis
const {wassert, brexru} = Corelib.Debug
const {log, clog, flog, rlog, cg, co, cw, cy, isApp, any2int, big2eth} = contractHelpers

export const contractAdapter = (() => { // ID __________________contractAdapter_______________
  const adapter = {
    ethers: null,
    ethersProvider: null,
    _contract: null,
    contractInfo: null,
    contractAddress: null,
    resetCounter: 0,
    ethereumInited: false,
    invalidWallet: true,
    useEthereum: true,       // dash will disable this if in Rpc mode
    accountZero: '',         // the selected account, it's only used in test/populator/dash
    massModeOn: false,
    stats: {
      status: 'VERY BAD AND EARLY'
    },
    // onFragChangeSidebar: null,
    // onFragChangeLiquidityPool: null,
    appEventCallback: {},
    appStateCallback: {},
    statsPromise: null,
    readyPromise: null,
    readyResolver: null,
    initStarted: false,
    initFinished: false,
    isReady: false,
    currDropState: {}, // deprecated
    controlFlags: {},
    controlVars: {},
    dropState: {},
    extDropState: {},
    state: {},
    lastUpdate: {},
    version: '',
    v1: false,
    v2: false,
    hasDrops: false,
    hasLeaderboard: false,
    hasMerkle: false,
    read: {},
    write: {},
    owner: {},
    setStateListener: nop,
    setEventListener: nop,
    setLazyEventListener: nop,
    getControlFlags: () => ({}),
    getControlVars: () => ({}),
    getLiquidityPoolValues: () => ({}),
    getHistory: () => new Promise(resolve => resolve([])),
    getFromEthersPromise: () => ({}),
    //getPrice: () =>({}),
    cnt: 0
  }
  const ensHash = {}
  const pfpHash = {}
  adapter.readyPromise = new Promise(resolve => adapter.readyResolver = resolve)
  
  adapter.triggerStateListeners = () => {
    for (const type in adapter.appStateCallback) {
      console.log(`Triggering ${type} state callback`)
      adapter.appStateCallback[type]?.()
    }
  }
  adapter.setStateListener = (type, cb) => adapter.appStateCallback[type] = cb

  // rem wall / events dummy placeholder injected from dash

  const vlog = () => {}

  adapter.wall = {
    on: (pattern, fun) => vlog(`adapter dummy event listener installed for ${pattern}`),
    emit: (pattern, args) => vlog(`dummy event emitted: ${pattern}`, args),
    post: (pattern, args) => vlog(`dummy event posted: ${pattern}`, args)
  }

  adapter.setWall = wall => adapter.wall = wall // before reset!

  // contract data structures age of last read

  adapter.updatedNow = what => {
    adapter.lastUpdate[what] = NoW()
    adapter.wall?.emit(`contract.updated.${what}`, {[what]: adapter[what]})
  }

  // adapter.expireNow = what => {
  //   adapter.lastUpdate[what] = NoW()
  //   adapter[what] = {}
  //   adapter.wall?.emit(`contract.expired.${what}`)
  //   adapter.wall?.emit(`contract.updated.${what}`, {[what]: {}})
  // }
  // rem general validity check

  const wlog = (...args) => console.log('%c' + args.shift(), 'color: #e40', ...args)
  const elog = (...args) => {
    console.warn('%c' + args.shift(), 'color: #c00', ...args)
    debugger
  }
  const olog = (...args) => console.log('%c' + args.shift(), 'color: #0a0', ...args)

  const validErr = (type, inf) => (inf ? wlog : elog)(`No valid ${type} contract! ${inf || ''}`, {adapter})

  const isReadContractValid = inf => adapter.read.contract || validErr('read', inf)
  const isWriteContractValid = inf => adapter.write.contract || validErr('write', inf)
  const isOwnerContractValid = inf => adapter.owner.contract || validErr('owner', inf)

  adapter.contractException = msg => err => {
    console.error(`Unexpected exception in ${msg}:`, err, {...err})
    console.log({adapter})
    //debugger
    return {}
  }

  adapter.helpers = {
    ...contractHelpers,
    isReadContractValid, isWriteContractValid, isOwnerContractValid, startEndThrottle, debounce
  }

  adapter.validateWallet = () => {
    olog('Wallet validated')
    adapter.invalidWallet = false
  }

  adapter.invalidateWallet = () => {
    wlog('Wallet invalidated')
    adapter.invalidWallet = true
  }

  // fix: mod writeContract

  adapter.setSelectedAccount = account => { // REM ________ account management (no arrays!) ________________
    //clog(`setSelectedAccount:`, account)
    adapter.accountZero = account || ''

    adapter.accountZero
      ? adapter.validateWallet()
      : adapter.invalidateWallet()

    adapter.accountZero
      ? log(`Selected account changed to %c${account}`, cg)
      : log(`No account connected!`)
  }
  adapter.getSelectedAccount = () => adapter.accountZero

  adapter.getBalanceInETH = async account => {
    //clog(`getBalanceInETH`, account)
    if (isReadContractValid() && account) {
      const balance = await adapter.ethersProvider.getBalance(account)
      const balanceETH = big2eth(balance)

      const mmBalance = await window.ethereum?.request({ // first metamask popup -> connect, maybe not needed?
        method: 'eth_getBalance',
        params: [account, 'latest']
      })
        .catch(err => console.error('eth_getBalance', err))
      const mmBalanceETH = big2eth(adapter.ethers.BigNumber.from(mmBalance))
      rlog(`getBalanceInETH: ${account} has ${balanceETH.toFixed(5)} / mm: ${mmBalanceETH.toFixed(5)}`)
      return balanceETH || mmBalanceETH
    } else {
      return 0
    }
  }

  //.rem  start 

  fetch('/pfp.json')
  .then(response => response.json())
  .then(preHash => {
    for (const ownerKey in preHash) {
      pfpHash[ownerKey] = preHash[ownerKey]
    }
    console.log(`pfp.json read:`, pfpHash)
  })
  .catch(async err => {
    console.error('Could not fetch pfp file!', err)
  })

  adapter.getPfp = ({owner}) =>  pfpHash[owner?.toLowerCase?.()] || '' 

  globalThis.getPfpHash = adapter.getPfpHash = () => pfpHash

  //.rem  end

  //.rem  start 

  const dis = {
    cnt: 0,
    url: `https://mainnet.infura.io/v3/f3093f161ffd48df998a5b0916ba696c`,
    provider: void 0
  }

  fetch('/ens.json')
    .then(response => response.json())
    .then(preHash => {
      // console.log(preHash)
      for (const ownerKey in preHash) {
        ensHash[ownerKey] = preHash[ownerKey]
      }
      console.log(`♻️ens.json read:`, ensHash)
    })
    .catch(async err => {
      console.error('Could not fetch ens file!', err)
    })

  adapter.getFromEthersPromise = owner => {
    dis.provider || (dis.provider = new adapter.ethers.providers.JsonRpcProvider(dis.url))
    const cnt = dis.cnt++
    // console.log(`${cnt}: getFromEthersPromise(${owner})`)

    const store = (ens, err) => {
      err && console.log('error:', {owner, err})
      const enS = ens + ''
      // console.log(`${cnt} lookupAddress.then(${owner} -> ${ens})`)
      localStorage.setItem('ens.' + owner, enS)
      ensHash[owner] = enS
      return enS
    }

    return dis.provider.lookupAddress(owner)
      .then(ens => store(ens))
      .catch(err => store('error', err))
  }

  const getFromCache = owner => ensHash[owner] = localStorage.getItem('ens.' + owner)


  adapter.getEnsAddress = ({owner}) =>
    ensHash[owner] || (ensHash[owner] = getFromCache(owner) || adapter.getFromEthersPromise(owner))

  globalThis.getEnsHash = adapter.getEnsHash = () => ensHash

  //.rem  end

  adapter.setConfig = url => adapter.write.cryMeth.configSetter(true, url)
 
  adapter.balanceOf = async account => isReadContractValid('balanceOf') && account // get token cnt of owner
    ? any2int(await adapter.read.cryMeth.balanceOf(account)) : 0

  adapter.processAccountsOnChange = accounts => { // Time to reload your interface with accounts[0]!
    log(`Accounts changed! %c${accounts?.join(', ')}`, cg) // this is only valid with metaMask
    adapter.setSelectedAccount(accounts?.[0])
  }

  adapter.addReceiver = async receiver => {
    adapter.receiverContract = receiver
    log(`Receiver is being added`, {receiver})
  }

  // rem drum -> fragment proxying

  const prepareContractMethods = (contractObject, contract) => { // looking for drummers
   
    contractObject.contract = wassert(contract)
    const cryMeth = contractObject.cryMeth = {}
    const estGas = contractObject.estGas = {}
 
    for (const prop in contract) {
      if (!prop.includes('(') && isFun(contract[prop])) {
        cryMeth[prop] = contract[prop]
        estGas[prop] = contract.estimateGas[prop]
      }
    }
    const dict = [['Fragment', 'Drummer']]
    for (const prop in cryMeth) {
      for (const [to, from] of dict) {
        if (prop.includes(from)) {
          cryMeth[prop.split(from).join(to)] = cryMeth[prop]
          estGas[prop.split(from).join(to)] = estGas[prop]
          //console.log(`Replaced ${prop} -> ${prop.split(from).join(to)}`)
        }
      }
    }
  }

  // chk resetReadContract MUST be called first (owner and write are optional)

  adapter.resetReadContract = (ethers, readContract, config = {}) => {
    window.readContract = readContract // debug 

   prepareContractMethods(adapter.read, readContract)
    const  {ethersProvider, contractInfo, contractAddress, version = 'v2', network} = config
    adapter.version = version
    adapter.v1 = version === 'v1'
    adapter.v2 = !adapter.v1
    adapter.hasFreeCombine = adapter.hasOnchainMetadata = adapter.hasOneState = adapter.hasLpRatio = adapter.v1
    adapter.hasDrops = adapter.hasLeaderboard = 
      adapter.hasMerkle = adapter.hasChainLink = adapter.hasReceiver = adapter.v2
    adapter.ethers = ethers
    adapter.ethersProvider = ethersProvider || ethers.getDefaultProvider() // fix

    // if (network === 'rinkeby') { // fix 
    //   adapter.ethersProvider = new ethers.providers.InfuraProvider('rinkeby', 'f3093f161ffd48df998a5b0916ba696c')
    // }
    if (network === 'goerli') { 
      adapter.ethersProvider = new ethers.providers.AlchemyProvider('goerli', 'W1L4iMyf8A7le_Dwx8aLXL3U8OcGYtgc')
    }

    adapter.contractInfo = contractInfo // not used
    adapter.contractAddress = contractAddress

   console.log(`Joined contract at: %c${readContract.address}`, co, isApp ? {readContract} : '')
    if (isApp) { // browser, baby!
      if (adapter.useEthereum) {                    // we could use ethereum, yes
        if (window.ethereum) {                      // we have ethereum injected
          if (!adapter.ethereumInited) {            // but we didn't inited it yet, this is a First time.
            adapter.ethereumInited = true           // We are in ethereum full go mode now.
            waitForMetaMask(adapter)
          }
          // else (not first call) we rest, nothing to do
        } else { // there is no ethereum in the browser, but we might need it...
          log(`✋🏻You have to connect to a valid wallet! You have to install MetaMask!`)
          adapter.invalidateWallet()
        }
      } else { // we are still in the browser, but we don't want ethereum -> Rpc in browser!
        // CHK ???
      }
    } else { // node.js, hardhat - deprecated, we don't use it this way
    }
    if (!adapter.resetCounter++) { // this is only executed on the first reset call.
      if (ethersProvider) {
        //  Logging only.
        const {connection: {url} = {}, _network: {name, chainId} = {}} = ethersProvider
        const dispName = name === 'unknown' ? name + ' (hardhat)' : name
        console.log(`Provider: %c${url} %cNetwork: %c${dispName} %cChainId: %c${chainId}`, cy, cw, cy, cw, cy)
      }
      // first reset:
      console.log('resetReadContract start async init...')
      initAdapterOnce()
    }
    return adapter
  }

  // multiple write contracts should be hashed by signer (dash)

  adapter.resetWriteContract = (writeContract, signer, config = {}) => {
    const  {merkleApiUrl} = config
    adapter.merkleApiUrl = merkleApiUrl || adapter.merkleApiUrl
    adapter.write.signer = signer
    //wassert(!rest)
    prepareContractMethods(adapter.write, writeContract)
    if (!adapter.owner.contract) {
      adapter.resetOwnerContract(writeContract, signer, config)
    }
  }
 
  adapter.resetOwnerContract = (ownerContract, ownerSigner, config = {}) => {
    const  {merkleApiUrl} = config
    adapter.merkleApiUrl = merkleApiUrl
    adapter.ownerSigner = ownerSigner
    prepareContractMethods(adapter.owner, ownerContract)
  }

  const initAdapterOnce = async () => { // ready for read
    if (adapter.initStarted) {
      console.warn('initAdapterOnce called twice?')
      return
    }
    adapter.initStarted = true
    // if (adapter.readyResolver) {
    //   console.warn('initAdapterOnce called twice?')
    //   return
    // }
    //adapter.readyPromise = new Promise(resolve => adapter.readyResolver = resolve)
    //await schedule(10)

    extendContractAdapterWithStats({adapter})
    extendContractAdapterWithUpdates({adapter})
    extendContractAdapterWithFragCache({adapter})
    extendContractAdapterWithFrags({adapter})
    extendContractAdapterWithTxs({adapter})
    extendContractAdapterWithOps({adapter})
    extendContractAdapterWithEvents({adapter})
    extendContractAdapterWithDrops({adapter})
    extendContractAdapterWithHistory({adapter})
    extendContractAdapterWithLiquidityPool({adapter})
    extendContractAdapterWithLeaderboard({adapter})
    extendContractAdapterWithFunds({adapter})
    log('Adapter submodules started.')

    adapter.initEventListeners?.()

    adapter.statsPromise = adapter.v1 ? adapter.getState() : adapter.getStats()
      //const firstStat = await adapter.statsPromise
      //log('FirstStat:', firstStat)

      // fix stats vs state, common part?

    adapter.statsPromise
      .then(async asp => {
        if (adapter.v1) {
          log(`statPromise.then`, {asp, adapterState: adapter.state})
          rlog(`throttle (100%): ${adapter.state.throttle}%`)
        } else {
          log(`statsPromise.then`, {asp, adapterStats: adapter.stats})
          rlog(`throttle (100%): ${adapter.stats.throttle}%`)

          await adapter.getControlFlags(true)
          await adapter.getControlVars(true)
        }
        log(`%cinitAdapterOnce: resolving readyPromise now.`, 'font-size:18px;color:#fff;background:#a50;')
        adapter.isReady = adapter.initFinished = true
        adapter.readyResolver()
        log(`initAdapterOnce: readyPromise is resolved.`)
      })
      .catch(err => {
        flog(`Could not call contractAdapter init methods`, err, {...err})
        const st = 'font-size: 18px;font-weight: 700;color: yellow;border-radius: 22px;'
        const stop = st + 'background: linear-gradient(to bottom, #C00, #00C);'
        const sbot = st + 'background: linear-gradient(to bottom, #00C, #C00);'
        console.log(`%cPlease check your deployed contract address!`, stop)
        console.log(`%cIt must be: ${adapter.contractAddress}\n`, sbot)
      })
  }

  adapter.onReady = () => adapter.readyPromise

  // adapter.onReady = async () => {
  //   if (adapter.readyResolver) {
  //     return adapter.readyPromise
  //   }
  //   console.warn('adapter.onReady should not be called before adapter.reset')
  //   await schedule(2000)
  //   return adapter.onReady()
  // }

  // rem ___________whiteList proxying (called only from dash)__________________________

  adapter.setWhiteListRoot = async rootHash => {
    if (!isOwnerContractValid()) {
      return
    }
    try {
      const gas = await adapter.owner.estGas.setWhiteListRoot(rootHash)
      log(`adapter.setWhiteList will cost ${gas} gas`)
    } catch (err) {
      flog(`adapter.setWhiteListRoot failed while estimating gas`, err)
      return
    }
    return adapter.owner.cryMeth.setWhiteListRoot(rootHash)
  }

  adapter.verifyWhiteList = async (candidate, proof) => {
    if (!isOwnerContractValid()) {
      return
    }
    try {
      const gas = await adapter.owner.estGas.verifyWhiteList(candidate, proof)
      log(`adapter.verifyWhiteList will cost ${gas} gas`)
    } catch (err) {
      flog(`adapter.verifyWhiteList failed while estimating gas`, err)
      return
    }
    return adapter.owner.cryMeth.verifyWhiteList(candidate, proof)
  }

  return adapter
})()
