// @ts-nocheck
/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, no-shadow, quotes, object-curly-spacing, semi,
   no-unused-expressions, spaced-comment, object-shorthand, no-bitwise, no-nested-ternary, no-underscore-dangle,
   consistent-return, no-extra-boolean-cast, jsx-quotes, no-multi-spaces, multiline-ternary, no-void,
   no-return-assign, no-restricted-syntax, prefer-template, no-multi-assign, no-param-reassign, guard-for-in,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, no-debugger, consistent-return,
   jsx-a11y/control-has-associated-label
*/
import React, {useEffect, useState} from 'react'
import {mergeCubesConclusion, mergeCubesIntro} from 'cryptoart_cube/src/components/react/CubeMain'
//} from '../../utils/CubeMock'
import {PendingCube, CubeWrapper, CubeMainWrapper} from '../../components/CubeComponents'
import {useCryptoartContract, useAdapterContext, useOpsAccess} from '../../contracts/useCryptoartContract'
import {useWallet} from '../../ethWallet/useWallet'
import {getOwnersFragments, getFragmentsByIds} from '../../wallet/queries'
import {SidebarHunting} from '../../components/SidebarHunting'
import Spinner from '../../designSystem/Spinner'
import {useToast, useToastComposite} from '../../hooks'
import {MxConfirm} from '../../components/MxDia'
import {isMobile} from '../../utils/isMobile'
import {Corelib} from '../../adapter/improxy-red'
import FragmentColorChart from '../../wallet/FragmentColorChart'
import {FragmentColorBar} from '../../wallet/FragmentColorRow'
import {useMint} from '../../mint/useMint'
import HuntingList from './HuntingList'
import { number } from 'prop-types'

const {wassert, weject} = Corelib.Debug
void wassert + weject

const SelectFragment = () => // never
  <div className='h-full mb-auto mt-auto mx-auto text-center pt-4'>
    Please select a fragment.
  </div>

const NoFragments = () => 
  <div className='_NoFragments fixed w-full items-center text-center mb-auto mt-32 mx-auto pt-4'>
    <div className='p-3 m-2 text-red-500'>
      You have no fragments.
    </div>
  </div>

const Loading = () =>
  <div className='_Loading fixed w-full items-center text-center mb-auto mt-32 mx-auto pt-4'>
    <div className='p-3 m-2 text-red-500'>
      Loading fragments...
    </div>
    <Spinner />
  </div>

void Loading  

// REM debug, useStateExt

const openCubeWindow = (primaryCubeFrag, secondaryCubeFrag, previewCube) => {
  const {squareCount: s1 = [], combined: c1 = false} = primaryCubeFrag || {}
  const {squareCount: s2 = [], combined: c2 = false} = secondaryCubeFrag || {}

  window.open([
    `https://cube.fragmentsnft.xyz/?config=${s1?.join(',')}&isCombined=${c1}&previewCube=${previewCube}`,
    secondaryCubeFrag ? `&config2=${s2?.join(',')}&is2Combined=${c2}` : ``
  ].join(''), 'cube')
}
void openCubeWindow

const ulog = console.log

const useXhash = window.ux = {}

const useStateExt = (initial, name = 'anon') => {
  //const usX = (useXhash[name] ??= {history: []})
  const usX = (useXhash[name] = useXhash[name] || {history: []}) // for the medieval era react webserver
  const [state, _setter] = useState(initial)

  const setter = newState => {
  // usX.setter ??= newState => {
    const hasChanged = state !== newState
    ulog(`setter(${name}) called, ${hasChanged ? '🔶' : '🔷'}`, {state, newState})
    if (name === 'upcomingSelectedId' && state !== -1 && newState === -1) {
      debugger
    }
    // console.trace()
    usX.history.push({ts: Date.now(), hasChanged, state, newState})
    _setter(newState)
  }

  return [state, setter] 
}

const lookupPageLoc = (lookupId, fragList, pageSize) => {
  for (let ix = 0; ix < fragList.length; ix++) {
    if (fragList[ix].id === lookupId) {
      return {inPage: ~~(ix / pageSize) + 1, inPos: (ix % pageSize) + 1}
    }
  }
  return {inPage: 0, inPPos: 0}
}

const stateByKey = {}
const logChanges = (obj, name) => {
  if (!stateByKey[name]) {
    stateByKey[name] = {...obj}
    console.log(`📒📘stateByKey[${name}] created`, stateByKey[name])
    return
  }
  const ref = stateByKey[name]
  for (const key in obj) {
    if (ref[key] !== obj[key]) {
      console.log(`📕📘${key} changed:`, ref[key], obj[key])
    }
  }
  stateByKey[name] = {...obj}
}



// PUB start_________________________________________________________

const HuntingPage = ({IsLeaderBoard=false}) => {
  const wallet = useWallet()
  const contractAdapter = useCryptoartContract()
  const {eth} = contractAdapter?.helpers || {}
  const {adapterState} = useAdapterContext()
  const toast = useToast()
  // const toastComposite = useToastComposite()
  const [alien, setAlien] = useState('')
  const [alienList, setAlienList] = useState([])
  const opsAccessState = useOpsAccess() // fix availableOps, useAvailableOps
  const [isCombinePanelMode, setCombinePanelMode] = useStateExt(false, 'combinePanelMode')
  const [selectedId, setSelectedId] = useState(-1)
  // const [originalFragList, setOriginalFragList] = useState([])
  const [fragList, setFragList] = useState([])
  const [primaryCubeFrag, setPrimaryCubeFrag] = useState(null)
  const [secondaryCubeFrag, setSecondaryCubeFrag] = useState(null)
  const [previewCube, setPreviewCube] = useState(false)
  const [confirmRedeemFrag, setConfirmRedeemFrag] = useState(null)
  const [_loadingState, setLoadingState] = useState('loading')
  const isError = _loadingState === 'error'
  const isLoading = _loadingState === 'loading'
  const isDone = _loadingState === 'done'
  const [isCollapsed, setCollapsed] = useState(false)
  const {openDialog: mintDialogState} = useMint()
 
 
  const [MinFace1, SetMinFace1] = useState(0)
  const [MinFace2, SetMinFace2] = useState(0)
  const [MinFace3, SetMinFace3] = useState(0)
  const [MinFace4, SetMinFace4] = useState(0)
  const [MinFace5, SetMinFace5] = useState(0)
  const [MinFace6, SetMinFace6] = useState(0)
  
  const [MaxFace1, SetMaxFace1] = useState(9)
  const [MaxFace2, SetMaxFace2] = useState(16)
  const [MaxFace3, SetMaxFace3] = useState(25)
  const [MaxFace4, SetMaxFace4] = useState(36)
  const [MaxFace5, SetMaxFace5] = useState(49)
  const [MaxFace6, SetMaxFace6] = useState(64)

  // REM Force refetch / redraw

  const [forcedFragFetchCnt, _setForcedFragFetch] = useState(0)
  const [forcedRedrawCnt, _setForcedRedraw] = useState(0)
  const forceFragFetch = () => _setForcedFragFetch(forcedFragFetchCnt + 1)
  const forceRedraw = () => _setForcedRedraw(forcedRedrawCnt + 1)

  const changeEventState = (...args) => {
    if (args[0] === 'Ready') {
      const id = ~~args[1][0]
      if (idHash[id]) { // eslint-disable-line
        forceFragFetch()
      }
    }
  }
  contractAdapter.setLazyEventListener('myfrags', changeEventState, 2000)

  // REM paging

  const [page, setPage] = useStateExt(1, 'page')
  const [pageSize, setPageSize] = useStateExt(10, 'pageSize')
  void setPageSize
  const [pageFollowMode, setPageFollowMode] = useStateExt(false, 'pageJump')

  // Debug

  const [__forceSimpleCube, __setForceSimpleCube] = useState(false)
  const __invertForceSimpleCube = () => {
    __setForceSimpleCube(!__forceSimpleCube)
    forceFragFetch()
  }
  void __invertForceSimpleCube
  
  // REM Sorting
  
  const [sortingState, setSortingState] = useState({by: 'id', asc: false})
  const sortLabels = ['id', 'combined', 'redeem', 'score', 'predscore', 'overlap']
  
  // INT End of states

  // REM selection helpers

  const getFragById = id => {
    if (id > -1) {
      for (let ix = 0; ix < fragList.length; ix++) {
        if (fragList[ix].id === id) {
          return fragList[ix]
        }
      }
    }
    return null
  }

  const setSelectedIdById = id => { // the single point of changing selectedId
    const frag = getFragById(id)
    if (frag) {
      // console.log('setSelectedIdById:', {id, frag})
      setSelectedId(id)
    } else {
      console.error('cannot find frag for selId', selectedId)
    }
  }

  const onSelectIdByFrag = frag => {
    if (frag) {
      setSelectedIdById(frag.id)
    } else {
      console.error('setselbyFrag cannot select from undef frag')
    }
  }

  // INT main
  const {by, asc} = sortingState
  
  if (fragList.length) {
    const sign = asc ? -1 : 1
    const prop = {predscore: 'predScore', overlap: 'hasOverlap', redeem: 'redeemValue'}[by] || by
    // const hashBefore = fragList.map(f => f.id).join(',')
    fragList.sort((a, b) => (b[prop] - a[prop]) * sign)
    // const hashAfter = fragList.map(f => f.id).join(',')
    // console.log(`fragList sorted, changed=${hashBefore === hashAfter}`, {fragList})
    // hashBefore !== hashAfter && forceRedraw()
  }

  // we have a sorted fragList here

  const idHash = {}
  for (const frag of fragList) {
    idHash[frag.id] = frag
  }

  if (!getFragById(selectedId)) { // invalid selectedId
    if (fragList.length) {
      // console.log('setting selected id to the first frag:', fragList[0].id)

      if(IsLeaderBoard)
        setSelectedIdById(fragList.sort((a, b) => b.score - a.score).filter(x=>x.isReady==true)[0].id)
      else
        setSelectedIdById(fragList.filter( x=>
          x.squareCount[0] >= MinFace1 && x.squareCount[0] <= MaxFace1 &&
          x.squareCount[1] >= MinFace2 && x.squareCount[1] <= MaxFace2 &&
          x.squareCount[2] >= MinFace3 && x.squareCount[2] <= MaxFace3 &&
          x.squareCount[3] >= MinFace4 && x.squareCount[3] <= MaxFace4 &&
          x.squareCount[4] >= MinFace5 && x.squareCount[4] <= MaxFace5 &&
          x.squareCount[5] >= MinFace6 && x.squareCount[5] <= MaxFace6 &&
          x.isReady==true)[0].id)
    } else {
      // console.log('cannot set selected id to the first frag as no fragList')
    }
  }

  // we have a selectedId (or -1) here
  
  const selectedFrag = getFragById(selectedId)
  if (selectedId !== -1) {
    if (selectedFrag !== primaryCubeFrag || primaryCubeFrag?.id !== selectedId) {
      // console.log('main will set primary', {primaryCubeFrag, selectedId, selectedFrag})
      setPrimaryCubeFrag(selectedFrag)
    }
  }

  // we have a selected frag (or null) here

  const {inPage, inPos} = lookupPageLoc(selectedId, fragList, pageSize)
  if (pageFollowMode && page !== inPage) {
    console.log({inPage, inPos, pageFollowMode})
    setPage(inPage)
  }

  

  const getIncArray = (z, to, len = to - z + 1) => new Array(len).fill(0).map((_, ix) => ix + z)

  useEffect(() => {
    let mounted = true
    setLoadingState('loading')
    contractAdapter.onReady().then(() => {
      contractAdapter.getFragments(getIncArray(0, 2000))
        .then(fragments => {
          if (mounted) {
            setPrimaryCubeFrag(null)
            setFragList(fragments)
            setLoadingState('done')

           
          if(IsLeaderBoard){
            Promise.all(fragments.map(frag => contractAdapter.getEnsAddress(frag)))
            .then(owners => {
            for (let i = 0; i < owners.length; i++) {
              fragments[i].ens = owners[i] === 'null' ? fragments[i].owner : owners[i]
              fragments[i].pfp = contractAdapter.getPfp(fragments[i])
            }
            setFragList([...fragments])
          })
           
          }
         }
        })
        .catch(err => {
          console.log(err)
          setLoadingState('error')
        })
    })
    return () => mounted = false
  }, [wallet.account, adapterState, forcedFragFetchCnt, contractAdapter, mintDialogState,IsLeaderBoard])


  /************************************************************** */
  

  const onPageTurn = (page, doFollow = false) => {
    setPage(page)
    setPageFollowMode(doFollow)
  }

  
  useEffect(() => {
    const coll = isCollapsed ? 'Collapsed' : ''
    IsLeaderBoard
      ? document.body.setAttribute('mode', 'leaderboardPanel' + coll)
      : document.body.setAttribute('mode', 'fragmentList' + coll)

      // return () => document.querySelector('._CubeMainWrapper')?.classList.add('hoverable')
  }, [IsLeaderBoard, isCollapsed])

  //const total = fragList.length
  const total = fragList.filter( x=>
    x.squareCount[0] >= MinFace1 && x.squareCount[0] <= MaxFace1 &&
    x.squareCount[1] >= MinFace2 && x.squareCount[1] <= MaxFace2 &&
    x.squareCount[2] >= MinFace3 && x.squareCount[2] <= MaxFace3 &&
    x.squareCount[3] >= MinFace4 && x.squareCount[3] <= MaxFace4 &&
    x.squareCount[4] >= MinFace5 && x.squareCount[4] <= MaxFace5 &&
    x.squareCount[5] >= MinFace6 && x.squareCount[5] <= MaxFace6 &&
    x.isReady==true
  ).length
  const pagination = {
    total,
    perPage: pageSize,
    page,
    lastPage: Math.ceil(total / pageSize),
    from: ((page - 1) * pageSize) + 1,
    to: page * pageSize
  }
  

  useEffect(() => {
    if (isError) {
      toast('error', 'Failed to load fragments.')
      return null
    }
  }, [isError, toast])

  const stateSS = {
    wallet, walletStatus: wallet?.status,
    contractAdapter, adapterState,
    alien, alienList,
    opsAccessState, 
    selectedId, fragList,
    primaryCubeFrag, secondaryCubeFrag, previewCube, __forceSimpleCube, 
    isCombinePanelMode, confirmRedeemFrag, _loadingState, isCollapsed,
    forcedFragFetchCnt, page, pageSize, pageFollowMode, sortingState
  }

  logChanges(stateSS, 'myFragsPage.stateSS')

  console.log(`🥁🥁🥁 MFP before render fragListLen=${fragList.length}`, {
    selectedId, selectedFrag: selectedFrag?.id, primaryCubeFrag, page, pageFollowMode,
    previewCube, isCombinePanelMode, sortingState, 
    fragList, confirmRedeemFrag: confirmRedeemFrag?.id,
    mintDialogState, forcedFragFetchCnt, adapterState
  })

  if (isCombinePanelMode) {
    contractAdapter.predictCombinedProps?.(fragList, selectedFrag)
  }


  if (!contractAdapter.read) {
    return null
  }
  if (isLoading && !fragList.length) {
    return <Loading />
  }
  const alienComp = <></>
 
  const fragmentProps = {
    isCombinePanelMode, selectedId, selectedFrag, selectedOtherId: secondaryCubeFrag?.id, isError,// isLoading,
    onPageTurn,
    onSelectIdByFrag, 
    opsAccessState, pagination, fragList, pageFollowMode, sorting: {sortingState, setSortingState, sortLabels},
    inPage, inPos, below: alienComp, isCollapsed, setCollapsed,
    MinFace1,MinFace2,MinFace3,MinFace4,MinFace5,MinFace6,
    MaxFace1,MaxFace2,MaxFace3,MaxFace4,MaxFace5,MaxFace6,IsLeaderBoard
    
  }

  const cubeMain = isDone
    ? fragList.length
      ? primaryCubeFrag // fix no need, if fraglist length -> there is always primarycube or vica versa
        ? primaryCubeFrag.isReady
          ? <CubeMainWrapper
              key={`Main${primaryCubeFrag.id}-${secondaryCubeFrag?.id}`}
              frag1Config={primaryCubeFrag.squareCount}
              isCombined={primaryCubeFrag.combined && !__forceSimpleCube}
              frag2Config={secondaryCubeFrag?.squareCount}
              is2Combined={secondaryCubeFrag?.combined && !__forceSimpleCube}
              previewCube={previewCube}
              hideControlsNot
            /> 
          : <PendingCube />
        : <SelectFragment />
      : <NoFragments />
    : <></>


  return isMobile()
    ? (                                          // mobile
        <div className='_MyFragmentsPage-mobile'>
          <div className='mobile-grid'>
            {cubeMain}

            {!IsLeaderBoard ? 
            <div className='_Sidebar static h-70 sm:h-full md:h-full lg:h-full p-2 z-10 overflow-y-hidden custom-scrollbar'>
                 
                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#1 (Red)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452  pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace1(ev.target.value)}} />
                         
                        <div className='text-sm text-green-452  pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={9}
                          onChange={(ev) => { SetMaxFace1(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace1 < MinFace1)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#2 (Orange)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace2(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={16}
                          type='tel'
                          defaultValue={16}
                          onChange={(ev) => { SetMaxFace2(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace2 < MinFace2)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#3 (Yellow)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={25}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace3(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={25}
                          type='tel'
                          defaultValue={25}
                          onChange={(ev) => { SetMaxFace3(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace3 < MinFace3)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#4 (Green)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={36}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace4(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={36}
                          type='tel'
                          defaultValue={36}
                          onChange={(ev) => { SetMaxFace4(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace4 < MinFace4)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#5 (Blue)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                         min={0}
                         max={49}
                         type='tel'
                         defaultValue={0}
                         onChange={(ev) => { SetMinFace5(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={49}
                          type='tel'
                          defaultValue={49}
                          onChange={(ev) => { SetMaxFace5(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace5 < MinFace5)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#6 (Purple)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={64}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace6(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={64}
                          type='tel'
                          defaultValue={64}
                          onChange={(ev) => { SetMaxFace6(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace6 < MinFace6)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

            </div>:<></>}
            <div className="_MyFragList-wrapper fullrow fr-list">
              <HuntingList {...fragmentProps} />
            </div>
            <div className="_Sidebar-wrapper fullrow fr-info">
              <SidebarHunting isMobile={true} />
            </div>
            
          </div>
        </div>
      )
    : (  
    (!IsLeaderBoard) ? (                                        // desktop
      <div className="_myFragmentsPage-desktop relative flex flex-1">
          <CubeWrapper>
            {cubeMain}
            <SidebarHunting previewFrag={selectedFrag} />
            <div className='_Sidebar absolute h-70 sm:h-full md:h-full lg:h-full p-2 z-10 overflow-y-hidden custom-scrollbar'
              style={{ width: false ? '100%' : '240px', position: false? 'static':'absolute' }} >
                 
                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#1 (Red)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452  pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace1(ev.target.value)}} />
                         
                        <div className='text-sm text-green-452  pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={9}
                          onChange={(ev) => { SetMaxFace1(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace1 < MinFace1)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#2 (Orange)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={9}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace2(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={16}
                          type='tel'
                          defaultValue={16}
                          onChange={(ev) => { SetMaxFace2(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace2 < MinFace2)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#3 (Yellow)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={25}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace3(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={25}
                          type='tel'
                          defaultValue={25}
                          onChange={(ev) => { SetMaxFace3(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace3 < MinFace3)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#4 (Green)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={36}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace4(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={36}
                          type='tel'
                          defaultValue={36}
                          onChange={(ev) => { SetMaxFace4(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace4 < MinFace4)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#5 (Blue)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                         min={0}
                         max={49}
                         type='tel'
                         defaultValue={0}
                         onChange={(ev) => { SetMinFace5(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={49}
                          type='tel'
                          defaultValue={49}
                          onChange={(ev) => { SetMaxFace5(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace5 < MinFace5)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

                  <h2 className="fm-terminal-font text-xs  text-gray-300 mb-1">Face#6 (Purple)</h2>
                  <div className='grid grid-rows grid-cols-2auto gap-y-1 mb-1 sm:mb-1 md:mb-1 lg:mb-2 p-1 '>
                    <div className='flex flex-row'>
                        <div className='text-sm text-green-452 p-1 pl-0 pr-2'>Min</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={64}
                          type='tel'
                          defaultValue={0}
                          onChange={(ev) => { SetMinFace6(ev.target.value)}} />
                        <div className='text-sm text-green-452 p-1 pl-2 pr-2'>Max</div>
                        <input className= "green-border text-center px-1 bg-offblack text-green-452 w-1/3"
                          min={0}
                          max={64}
                          type='tel'
                          defaultValue={64}
                          onChange={(ev) => { SetMaxFace6(ev.target.value)}} />
                      </div>
                  </div>
                  {(MaxFace6 < MinFace6)?(<h2 className="fm-terminal-font text-xs  text-red-300 mb-1">Minimum must be less than maximum</h2>):(<></>)}

            </div>
            <HuntingList {...fragmentProps} />
          </CubeWrapper>
      </div>
      ):(
        <div className="_myFragmentsPage-desktop relative flex flex-1">
          <CubeWrapper>
            {cubeMain}
            <HuntingList {...fragmentProps} />
          </CubeWrapper>
      </div>
      )
    )
}

export default HuntingPage
