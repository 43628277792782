import { useNumberFieldState } from "@react-stately/numberfield";
import { useLocale } from "@react-aria/i18n";
import { useNumberField } from "@react-aria/numberfield";
import PropTypes from "prop-types";
import { forwardRef, useImperativeHandle, useRef } from "react";
import Input from "./Input";
import Button from "./Button";

const NumberField = forwardRef((props, ref) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const inputRef = useRef();
  const { groupProps, inputProps } = useNumberField(props, state, inputRef);

  useImperativeHandle(ref, () => ({
    commit: state.commit
  }));

  return (
    <div className="flex space-x-2" {...groupProps}>
      <Button
        disabled={props.disabled || state.numberValue <= state.minValue}
        onClick={state.decrement}
        px="6"
      >
        -
      </Button>
      <Input
        ref={inputRef}
        className="w-40 border-gray-200 bg-offblack text-white text-center"
        {...inputProps}
        disabled={props.disabled}
      />
      <Button
        disabled={props.disabled || state.numberValue >= state.maxValue}
        onClick={state.increment}
        px="6"
      >
        +
      </Button>
    </div>
  );
});

NumberField.propTypes = {
  disabled: PropTypes.bool,
};

NumberField.defaultProps = {
  disabled: false,
};

export default NumberField;
