/* eslint-env browser, node, worker */
/* eslint-disable no-return-assign, no-shadow, guard-for-in, import/extensions */
/* eslint-disable no-unused-vars */

/* eslint-disable object-property-newline,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */

import {Corelib} from './improxy-red.js'

const {globalThis} = Corelib
const {wassert} = Corelib.Debug
const {pNow, pSince, NoW} = Corelib.Tardis

const ZILLION = 9 * 16 * 25 * 36 * 49 * 64
//const ETH = 1E18
const hETH = 1E9

const big2int = big => parseInt(big._hex, 16)
const big2str = big => big._hex
const any2int = big => big?._hex ? parseInt(big._hex, 16) : big
const bigZero = {_hex: '0x0', _isBigNumber: true}
const bigCheck = bn => bn?._isBigNumber ? bn : (console.warn('Bad BN', bn) || bigZero)
const big2eth = bn => (bigCheck(bn).div?.(hETH) || 0) / hETH
const big2gwei = bn => (bigCheck(bn).div?.(1E6) || 0) / 1E3

const genCurrency = (num, currency, fix) => 
  typeof num === 'undefined' || Number.isNaN(num) ? '-' : `${num.toFixed(fix)} ${currency}`
const lnk = (num, fix = 3) => genCurrency(num, 'LINK', fix)
const eth = (num, fix = 3) => genCurrency(num, 'ETH', fix)
const meth = (num, fix = 3) => genCurrency(num * 1E3, 'mETH', fix)
const gwei = (num, fix = 3) => genCurrency(num * 1E9, 'Gwei', fix)
const ggwei = (num, fix = 3) => genCurrency(num, 'Gwei', fix)
//typeof num === 'undefined' || Number.isNaN(num) ? '-' : `${(num * 1000).toFixed(fix)}mETH`

const chk = cond => cond ? '✔️' : '❌'
const chkrg = cond => cond ? '🟢' : '🔴'
const pad2 = s => (' ' + s).slice(-2)
const pad3 = s => ('  ' + s).slice(-3)
const pad = x => ('0' + x).slice(-2)
const padHMS = (h, m, s) => `${pad(h)}:${pad(m)}:${pad(s)}`
const padYMD = (y, m, d) => `${y}-${pad(m)}-${pad(d)}`
const ymd = d => padYMD(d.getFullYear(), d.getMonth() + 1, d.getDate())
const hms = d => padHMS(d.getHours(), d.getMinutes(), d.getSeconds())
const getDateStringYMDHMS = (date, d = new Date(date)) => ymd(d) + ' ' + hms(d)
const flexTime = t => typeof t === 'string' && t.slice(-1) === 's' ? parseInt(t, 10) * 1000 : t
const adelay = delay  => new Promise(resolve => setTimeout(resolve, flexTime(delay)))

const pureSolid = obj => {
  const ret = {}
  for (const key in obj) {
    if (key.length > 1 && !~~key) {
      ret[key] = obj[key]
    }
  }
  return ret
}

const tileLimits = [9, 16, 25, 36, 49, 64]

const massert = (cond, ...args) => { // FIX -> helpers
  if (!cond) {
    console.warn(...args)
    debugger
  }
}

export const isApp = typeof window === 'object'
//const isScript = !isApp

const logOn = {
  log: true,
  liqLog: false,
  frog: false, // was true
  called: true,
  results: true,
  failure: true
}
const hideLogs = () => {
  for (const key in logOn) {
    logOn[key] = false
  }
}
const hideVerboseLogs = () => {
  logOn.results = false
  logOn.called = false
  logOn.log = false
}
//hideVerboseLogs()

const showVerboseLogs = () => {
  logOn.results = true
  logOn.called = true
  logOn.log = true
  logOn.failure = true
}
const setLog = (key, value) => logOn[key] = value

//const log = (...args) => logOn.log && console.log('☢️' + window.location.href + ' / ' + args.shift(), ...args)
const log = (...args) => logOn.log && console.log('☢️' + args.shift(), ...args)
const clog = (...args) => logOn.called && console.log('☢️❔❕' + args.shift(), ...args)
const rlog = (...args) => logOn.results && console.log('☢️✔️' + args.shift(), ...args)
const flog = (...args) => logOn.results && console.log('☢️❌' + args.shift(), ...args)

const cy = 'color: yellow;'
const co = 'color: orange;'
const cg = 'color: #0f0;'
const cw = 'color: white;'

const frog = token => { // one-line fragment log
  debugger // deprecated
  if (!logOn.frog) {
    return
  }
  const {id, series, combined, isReady, fullnessFloat = 0, squareCount, matches = [], prize = 0} = token
  const {usericon = '-'} = token // CHK: they are not really needed
  const col = squareCount.map(pad2)
  const imp = `🕚${chk(isReady)} ➰${series} 🔀${chk(combined)}`
  const combs = `🧮[${matches.slice(0, 2).map(({id, full}) => `${id}(${full})`).join(', ')}]`

  let sum = 0
  for (const cc of squareCount) { // reduce is evil
    sum += cc
  }
  const sq = `🔋${pad3(sum)}/199`
  const full = fullnessFloat.toFixed(3) + (prize ? ` ♻️${prize.toFixed(3)} ETH` : ``)
  const format = combined ? '%s:%s🟥 %s🟧 %s🟨 %s🟩 %s🟦 %s🟪' : '%s:%s🔴 %s🟠 %s🟡 %s🟢 %s🔵 %s🟣'
  console.log(usericon + format, '🔳' + pad2(id), ...col, sq, imp, combs, full)
}
const dumpFrogs = frags => frags?.map(frog)
const liqlog = (liqPool, liqPoolSum) => {
  if (logOn.liqLog) {
    const pool = liqPool.map(a => a.toFixed(4))
    const poolSum = liqPoolSum.toFixed(4)
    console.log('Liquidity Pool: %s❤️ %s🧡 %s💛 %s💚 %s💙 %s💜 Sum: %s💖', ...pool, poolSum)
  }
}
const jsonEq = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const createThrottledAsyncGetter = (asyncFun, log = console.log, name = '') => {
  const tag = {
    receivedAt: 0,
    promise: null,
    state: null
  }

  return (lag = 0, ...args) => {
    const context = `TAG.${name}(lag: ${lag})`
    const age = pSince(tag.receivedAt)
    if (age < lag) {
      log(`🟢🟢Reusing ${context} state`)
      return tag.state
    }
    if (!tag.promise) {
      log(`🔴Creating ${context} promise`)

      tag.promise = asyncFun(...args).then(state => {
        log(`🟡🟢Resolving ${context} promise`)

        tag.promise = null
        tag.receivedAt = pNow()
        return tag.state = state
      })
    } else {
      log(`🟠Reusing ${context} promise`)
    }
    return wassert(tag.promise)
  }
}

const mlog = (selector, arg1 = '', arg2 = '', ...args) => {
  globalThis.memlog || (globalThis.memlog = [])
  globalThis.memlog.push({at: pNow().toFixed(3) + ' - ' + getDateStringYMDHMS(NoW()), selector, arg1, arg2, args})
  globalThis.memslog || (globalThis.memslog = [])
  globalThis.memslog.push(`at: ${pNow().toFixed(3)} - ${getDateStringYMDHMS(NoW())} ${selector} ${arg1} ${arg2} ${args[0] || ''}`)
}

const mlogFrag = (selector, frag = {}) => {
  const {receivedAt, id, isRaw, isReady, combined, username} = frag
  const s1 = `${id} (${username}) rec: ${receivedAt}`
  const s2 = `raw=${chkrg(!isRaw)} rdy=${chkrg(isReady)} comb=${chkrg(combined)}`
  mlog(selector, s1, s2, frag)
  console.log(selector, s1, s2, frag)
}
const mlogFrags = (selector, frags) => {
  console.groupCollapsed(selector)
  for (const frag of frags) {
    mlogFrag(selector, frag)
  }
  console.groupEnd()
}

const hashStore = {}  

const hashChanged = (obj, hashKey) => {
  if (obj) {
    const hash = JSON.stringify(obj)
    if (hashStore[hashKey] !== hash) {
      hashStore[hashKey] = hash
      return true
    }
  }
  return false
}

const extendObject = (target, source) => {
  for (const key in source) {
    target[key] = source[key]
  }
}

const sumOfArray = (arr = [], from = 0, to = arr.length) => {
  let sum = 0
  for (let i = from; i < to; i++) {
    sum += arr[i]
  }
  return sum
}

export const contractHelpers = {
  logOn, log, clog, flog, rlog, liqlog, hideLogs, hideVerboseLogs, showVerboseLogs, setLog, 
  mlog, mlogFrag, mlogFrags, chkrg, hashChanged, adelay, extendObject, sumOfArray,
  dumpFrogs, tileLimits, cy, cg, co, cw, isApp, eth, lnk, meth, gwei, ggwei, any2int, big2eth, big2gwei,
  ZILLION, getDateStringYMDHMS, pureSolid, massert, jsonEq, createThrottledAsyncGetter
}
