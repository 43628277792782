import * as RadixDialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import IconButton from "./IconButton";

function Root({ ...props }) {
  return <RadixDialog.Root {...props} />;
}

function Trigger({ ...props }) {
  return <RadixDialog.Trigger {...props} />;
}

function Overlay({ className, ...props }) {
  return (
    <RadixDialog.Overlay
      className={clsx("fixed inset-0 bg-black opacity-30", className)}
      {...props}
    />
  );
}

Overlay.propTypes = {
  className: PropTypes.string,
};

Overlay.defaultProps = {
  className: null,
};

function Content({ ...props }) {
  return (
    <RadixDialog.Content
      className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-offblack border-2 border-green-452 shadow-md"
      {...props}
    />
  );
}

function Body({ ...props }) {
  return <div className="px-12 pt-12 pb-10" {...props} />;
}

function Title({ ...props }) {
  return <RadixDialog.Title {...props} />;
}

function Description({ ...props }) {
  return <RadixDialog.Description {...props} />;
}

function Close({ ...props }) {
  return <RadixDialog.Close {...props} />;
}

function CloseButton({ ...props }) {
  return (
    <div className="absolute top-4 right-4">
      <Close aria-label="Close" as={IconButton} {...props}>
        <Cross2Icon />
      </Close>
    </div>
  );
}

export {
  Root,
  Trigger,
  Overlay,
  Content,
  Body,
  Title,
  Description,
  Close,
  CloseButton,
};
