/* eslint-env browser, node, worker */
/* eslint-disable no-await-in-loop, import/extensions */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */

import {Corelib} from './improxy-red.js'

const {createPerfTimer} = Corelib.Tardis
const {wassert, weject} = Corelib.Debug

export const extendContractAdapterWithFrags = ({adapter}) => {
  const {isReadContractValid, log, clog, rlog, flog, createThrottledAsyncGetter, tileLimits, ZILLION} = adapter.helpers

  const z6 = ZILLION * 6

  adapter.calcFullness = frag => {
    frag.fullness = 0
    let fullFaces = 0
    for (let ix = 0; ix < 6; ix++) {
      frag.fullness += frag.squareCount[ix] * ZILLION / tileLimits[ix]
      if (frag.squareCount[ix] === tileLimits[ix]) {
        fullFaces++
      }
    }
    frag.fullnessFloat = frag.fullness / ZILLION
    frag.score = fullFaces === 6 ? 6 : (frag.fullness / z6 + fullFaces)
    return frag
  }

  adapter.canCombine = (frag1, frag2) => {
    if (!adapter.hasFreeCombine && !adapter.controlFlags.overlapOn) {
      for (let ix = 0; ix < 6; ix++) {
        if (frag1.squareCount[ix] + frag2.squareCount[ix] > tileLimits[ix]) {
          return false
        }
      }
    }
    return true
  }

  adapter.combineHasOverlap = (frag1, frag2) => {
    if(frag1.id == frag2.id){
    return true
    }
    for (let ix = 0; ix < 6; ix++) {
      if (frag1.squareCount[ix] + frag2.squareCount[ix] > tileLimits[ix]) {
        return true
      }
    }
    return false
  }

  adapter.combinePreview = (frag1, frag2, extra) => { // for dapp
    const {skipRedeemCalc = false, skipLeaderboardCalc = false} = extra || {}
    const {k, actWinners, contractTopList} = adapter.extDropState
    const hasOverlap = adapter.combineHasOverlap(frag1, frag2)
    if(hasOverlap)
    return -1;

    // const biggerId = adapter.hasFreeCombine // === v1
    //   ? hasOverlap                     // v1: just check if there is an overflow
    //   : adapter.controlFlags.overlapOn // v2: always swap when overlap mode is on

    const frag = {
      // id: biggerId ? Math.max(frag1.id, frag2.id) : Math.min(frag1.id, frag2.id),
      id: Math.min(frag1.id, frag2.id),
      squareCount: [],
      prediction: {k, actWinners, contractTopList},
      hasOverlap
    }
    frag.series = ~~(frag.id / adapter.stats.SERIES_LENGTH)
    try {
      for (let ix = 0; ix < 6; ix++) {
        frag.squareCount[ix] = frag1.squareCount[ix] + frag2.squareCount[ix]
        // if (frag.squareCount[ix] > tileLimits[ix]) {
        //   frag.squareCount[ix] = 2 * tileLimits[ix] - frag.squareCount[ix]
        // }
      }
    } 
    catch (err) {
      console.log(err)
      debugger
    }
    adapter.calcFullness(frag)
    if (!skipRedeemCalc) {
      const poolMods = adapter.js_calcCurrentPoolMods(frag)
      frag.redeemValue = poolMods.poolSum
      weject(Number.isNaN(frag.redeemValue))
      // adapter.js_calcCurrentPoolMods(frag)
      //frag.redeemValue = 0.0395282
    }
    if (!skipLeaderboardCalc) {
      if (adapter.hasLeaderboard) {
        let betters = 0
        for (const {fullness} of contractTopList) {
          if (fullness >= frag.fullness) {
            betters++
          }
        }
        if (actWinners) { // toplist is not empty, we have some valid numbers
        } else { // this is the first combined one, special case

        }
        frag.leaderboardValue = frag.fullnessFloat * k
        frag.leaderboardPos = betters + 1
      }
    }
    //console.log('combinePreview:', frag)
    return frag
  }

  adapter.predictCombinedProps = (fragments, fragment) => { // v1 only!
    wassert(fragment)
    for (const frag of fragments) {
      wassert(frag)
      if (frag.id === fragment.id) {
        frag.predScore = 0
        frag.hasOverlap = false
      } else {
        const combFrag = adapter.combinePreview(frag, fragment, {skipRedeemCalc: true})
        if(combFrag == -1){
        frag.predScore = 0
        frag.hasOverlap = true
        } else {
        frag.predScore = combFrag.score
        frag.hasOverlap = combFrag.hasOverlap
        }
      }
    }
  }

  const getFragFullness = ({squareCount}) => {
    let full = 0
    for (const cc of squareCount) {
      full += cc
    }
    return full
  }

  const getCombinedFullness = (fraga, fragb) => {
    if (adapter.controlFlags.overlapOn) {
      const squareCount = []
      for (let ix = 0; ix < 6; ix++) {
        squareCount[ix] = fraga.squareCount[ix] + fragb.squareCount[ix]
        if (squareCount[ix] > tileLimits[ix]) {
          squareCount[ix] = 2 * tileLimits[ix] - squareCount[ix]
        }
      }
      return getFragFullness({squareCount})
    } else {
      return fraga.full + fragb.full
    }
  }
  void getCombinedFullness

  adapter.updateAllCombinations = fragments => { // exposed for tests only
    for (const fragment of fragments) {
      fragment.matches = []
      fragment.full = getFragFullness(fragment)
    }
    // const len = fragments.length
    // for (let a = 0; a < len; a++) {
    //   const fraga = fragments[a]
    //   for (let b = a + 1; b < len; b++) {
    //     const fragb = fragments[b]
    //     if (adapter.canCombine(fraga, fragb)) {
    //       const full = getCombinedFullness(fraga, fragb)
    //       fraga.matches.push({id: fragb.id, full})
    //       fragb.matches.push({id: fraga.id, full})
    //     }
    //   }
    // }
    for (const fragment of fragments) {
      fragment.matches = fragment.matches.sort((a, b) => b.full - a.full).slice(0, 3) // chk 5, 10
    }
  }

  const _processRawFragments = rawFragments => { // oh why
    wassert(Array.isArray(rawFragments))

    return rawFragments.map(frag => {
      wassert(frag)
      if (frag.isRaw) {
        if (frag.combined) {
          const poolMods = adapter.js_calcCurrentPoolMods(frag)
          frag.redeemValue = poolMods.poolSum
          weject(Number.isNaN(frag.redeemValue))
          // adapter.js_calcCurrentPoolMods(frag)
          if (frag.redeemValue < .000001) {
            flog(`redeem value zero - thats not good thing hey ho`)
            debugger
          }
        } else {
          frag.redeemValue = 0
        }
        frag.isRaw = false
      }
      return frag
    })
  }

  const postProcess = inTokens => {
    if (!Array.isArray(inTokens)) {
      return adapter.postProcess([inTokens])[0]
    } 
    const tokens = inTokens.filter(Boolean)
    if (!tokens.length) {
      return []
    }
    const timer = createPerfTimer()
    if (tokens.some(token => token.isRaw)) {
      const fragments = _processRawFragments(tokens)
      rlog(`postProcess finished in ${timer?.sum().dur.sum}ms`, {fragments})
      return fragments
    } else {
      const sum = timer?.sum().dur.sum
      sum > 1 && rlog(`postProcess skipped ${tokens.length} frags in ${sum}ms`)
      return tokens
    }
  }

  // PUB get fragments

  adapter.getFragments = async (ids, {silent = false} = {}) => {
    if (!isReadContractValid()) {
      return []
    }
    const timer = createPerfTimer()
    const logThis = !silent
    logThis && clog(`getFragments(${ids.length})`)

    await adapter.getLiquidityPoolState(500) // fix optimize
    timer.mark('liqP')

    const tokens = await adapter.cache.getTokens(ids)
    timer.mark('getTokens')

    const fragments = postProcess(tokens)
    timer.mark('postProc')

    adapter.markTopFragments()
    timer.mark('mrkTop')

    rlog(`getFragments run in ${timer.summary()} for ${fragments.length} frags.`)

    return fragments
  }

  const finalizeOwnerFragments = fragments => {
    //These three operations are valid only for fragment from THE SAME OWNER:
    fragments.sort((a, b) => ~~a.isReady - ~~b.isReady)
    fragments.sort((a, b) => ~~b.combined - ~~a.combined)
    adapter.updateAllCombinations(fragments)
  }

  // adapter.getFragmentsOfOwner = async (account, {limit = 250, offset = 0, silent = false} = {}) => {
  //   if (!isReadContractValid()) {
  //     return []
  //   }
  //   console.warn('getFragmentsOfOwner')
  //   const timer = createPerfTimer()
  //   const logThis = !silent
  //   logThis && clog(`getFragmentsOfOwner`, {limit, offset, account})
  //   if (offset < 0) {
  //     flog(`getFragmentsOfOwner was called with negative offset!!!!`, {offset})
  //     return 0
  //   }
  //   const ownerIds = await adapter.read.cryMeth.getAllTokenIdsOfOwner(account) // returns ALL, no paging
  //   timer.mark('ownerIds')
  //   const balance = ownerIds.length
  //   logThis && console.groupCollapsed(`getFragmentsOfOwner - getting all (${balance}) frags from acc`, account)
  //   // log('ownerIds:', ownerIds)

  //   const ids = []
  //   for (let i = 0, ixToFetch = offset; ixToFetch < balance && i < limit; i++, ixToFetch++) {
  //     const tokenId = ownerIds[ixToFetch]
  //     logThis && log(`getFragmentsOfOwner getting fragment from contract`, {account, ixToFetch, tokenId})
  //     ids.push(tokenId)
  //   }
  //   logThis && console.groupEnd()  // TODO: handle errors with a strategy that accepts partial failure
  //   timer.mark('idsArr')

  //   const fragments = await adapter.getFragments(ids)
  //   timer.mark('getFrags')

  //   finalizeOwnerFragments(fragments)
  //   timer.mark('sort/match')

  //   fragments.length && rlog(`getFragmentsOfOwner returns`, fragments)

  //   rlog(`getFragmentsOfOwner run in ${timer.summary()} for ${fragments.length} frags.`, account)

  //   return fragments
  // }

  // rem local (CryptoartLocal.sol) shortcut for mass test

  const getFragmentsOfOwner = async (account, {silent = false} = {}) => {
    silent || clog(`getFragmentsOfOwner`, {account})
    const timer = createPerfTimer()

    await adapter.getLiquidityPoolState(500) // fix optimize
    timer.mark('liqP')

    const tokens = await adapter.cache.getTokensByOwner(account)
    timer.mark('getTokens')
    
    const fragments = postProcess(tokens)
    timer.mark('postProc')
        
    adapter.markTopFragments()
    timer.mark('mrkTop')

    finalizeOwnerFragments(fragments)
    timer.mark('sort/match')

    fragments.length && rlog(`getFragmentsOfOwner returns`, fragments)

    rlog(`getFragmentsOfOwnerNu run in ${timer.summary()} for ${fragments.length} frags.`, account)

    return fragments
  }

  const getOwnerFragFun = {}

  adapter.getFragmentsOfOwner = async (account, {silent = false} = {}) => {
    if (!isReadContractValid()) {
      return []
    }
    getOwnerFragFun[account] = getOwnerFragFun[account] ||
      createThrottledAsyncGetter(() => getFragmentsOfOwner(account, {silent}), 
        log, 'getOwnerFragFun.' + account.slice(0, 8))
    
    return getOwnerFragFun[account]()    
  }
} 
