/* eslint-disable */
/* idiotic eslint no-debugger, spaced-comment, no-multi-spaces, no-unused-vars, no-return-assign,
   valid-typeof, object-curly-spacing, no-trailing-spaces, indent, new-cap, object-property-newline,
   block-spacing, comma-spacing, handle-callback-err,  camelcase, no-restricted-properties,
   no-floating-decimal, no-void, quotes, guard-for-in, import/extensions, no-console, prefer-template,
   no-shadow, prefer-exponentiation-operator, no-param-reassign, func-names, no-nested-ternary,
   prefer-const, no-restricted-syntax, prefer-object-spread, consistent-return, no-underscore-dangle */

import {wassert, weject} from './debug-esm.js'

//8#69e Core library - type extensions

const {pow, round} = Math

export const Ø = 'undefined'
export const undef = undefined
export const yes = true
export const yeah = true
export const no = false
export const nooo = false

export const nObj = _ => Object.create(null)

export const isObj = o => typeof o === 'object' && o && typeof o.splice !== 'function' // not array
export const isArr = o => typeof o === 'object' && o && (typeof o.splice === 'function' || typeof o.fill === 'function' || Array.isArray(o))
export const isNum = n => typeof n === 'number' && !Number.isNaN(n)
export const isStr = s => typeof s === 'string'
export const isFun = f => typeof f === 'function'

export const extendInternalType = (entity, methodName, methodFunction) =>
  Object.defineProperty(entity.prototype, methodName, {
    enumerable: false,
    configurable: true,
    writable: true,
    value: methodFunction
  })
const ET = extendInternalType

export const maxFract = (float, fractDigits) => {
  const base = pow(10, fractDigits)
  return round(float * base) / base
}

export const safeParseJSON = janos => {
  let parsedData = void 0
  try { parsedData = JSON.parse(janos) } catch (e) { return undef }
  return parsedData
}

export const safeStringifyJSON = (...args) => {
  let janos = ''
  try { janos = JSON.stringify(...args) } catch (e) { return 'safeStringifyError+' + e.message}
  return janos      
}

export const robustJSONExceptions = ['arrej', 'GoogleAuth', 'bufferArr']

export const robustStringifyJSON = (arg, noerr = false) => {
  try {
    const json = JSON.stringify(arg)
    return json
  } catch (err) {
    const used = []
    
    try {
      return JSON.stringify(arg, (key, value) => {
        if (typeof value === 'object') {
          if (value !== null) {
            const oType = Object.prototype.toString.call(value)
            if (used.includes(value)) { 
              return '[duplicate]'
            } else if (value.nodeName === '#document') {
              return '[document]'
            } else if (value.nodeType) {
              return '[Node]'
            } else if (value.jquery) {
              return '[jQuery]'
            }  else if (robustJSONExceptions.includes(key)) {
              return `[${key}: Exception]`
            } else if (['[object DOMWindow]', '[object Window]', '[object global]'].includes(oType)) {
              return oType
            }
            used.push(value)
          } else {
            return 'null'
          }
        }
        return value 
      })
    } catch (err) {
      if (!noerr) {
        console.error(err.message)
        console.log('errored object:', arg)
        console.error(err)
      }
      return ''
    }
  }
}

extendInternalType(Number, 'maxFract', function kocsogeslint (val) {
  return maxFract(this, val)
})
  
extendInternalType(Array, 'removeFirstByValue', function kocsogeslint (val) {
  for (let i = 0; i < this.length; i++) { 
    if (this[i] === val) {
      this.splice(i, 1)
      return
    }
  }
})

//%String prototype extensions
//!:
//!- splice            
//!- beginS, endS      
//!- wipE              
//!- repL              
//!- linE              
//!- firstLine         
//!- beginTrim, endTrim
//!- camelCase         

extendInternalType(String, 'splice', function kocsogeslint (index, cnt, add) {
  if (index < 0) {
    index = this.length + index
    if (index < 0) {
      index = 0
    }
  }
  return this.slice(0, index) + (add || '') + this.slice(index + cnt)
})

const String_begins = (str, begin) => !begin || (str && !str.indexOf(begin))
const String_ends = (str, end) => !end || (str && str.slice(-(end.length)) === end)
const String_isin = (str, lst) => lst.split(',').includes(str)
const String_wipe = (str, towipe) => str && str.split(towipe).join('')
const String_replace = (str, from, to) => str && str.split(from).join(to)
const String_line = (str, ix) => str && (str.split('\n')[ix] || '')//9lehet undef jobb?
const String_firstLine = str => String_line(str, 0)//4not used from prototypes
const String_beginTrim = (str, pa) => typeof pa !== 'string' || str.indexOf(pa) ? str : str.substr(pa.length)
const String_endTrim = (str, pa) => typeof pa === 'string' 
  ? str.slice(-(pa.length)) === pa ? str.slice(0, -(pa.length)) : str
  : isArr(pa) && isArr(pa.map(p => str = String_endTrim(str, p))) ? str : str    
const String_camelCase = str => str.replace(/[\W]+\w/g, a => a.slice(-1)[0].toUpperCase())//
const String_lefT = (str, n) => str && str.substr(0, n)
const String_righT = (str, n) => str && str.substr(-n)
const String_noncludes = (str, pat) => !str || !str.includes(pat)

ET(String, 'beginS', function (begin) { return String_begins(this, begin) })
ET(String, 'endS', function (end) { return String_ends(this, end) })
ET(String, 'isIn', function (lst) { return String_isin(this, lst) })
ET(String, 'wipE', function (towipe) { return String_wipe(this, towipe) })
ET(String, 'repL', function (from, to) { return String_replace(this, from, to) })
ET(String, 'linE', function (ix) { return String_line(this, ix) })
ET(String, 'firstLine', function () { return String_line(this, 0) })
ET(String, 'beginTrim', function (poly) { return String_beginTrim(this, poly) })
ET(String, 'endTrim', function (poly) { return String_endTrim(this, poly) })
ET(String, 'camelCase', function () { return String_camelCase(this)})
ET(String, 'lefT', function (n) { return String_lefT(this, n)})
ET(String, 'righT', function (n) { return String_righT(this, n)})
ET(String, 'lT', function (n) { return String_lefT(this, n)})
ET(String, 'rT', function (n) { return String_righT(this, n)})
ET(String, 'noncludes', function (pat) { return String_noncludes(this, pat)})

//= #ifdef __test
wassert('macimalac'.beginTrim('maci') === 'malac')
wassert('macimalac'.endTrim('malac') === 'maci')
wassert('maci.wav'.endTrim(['.wav', '.mp3']) === 'maci')
wassert('maci.mp3'.endTrim(['.wav', '.mp3']) === 'maci')
wassert('maci.mp3'.endTrim(['.wav', '.mp3']) === 'maci')
weject('macimalac'.beginTrim('malac') === 'malac')
weject('macimalac'.endTrim('maci') === 'maci')
//= #endif

//%Object prototype extensions
//!:
//!- nu            
//!- loopValues      
//!- loopKeys              
//!- allKeys, sallKeysEx              
//!- getPropertyCnt
//!- isOwnProperty
//!- Object_deepCopy, deepCopy
//!- Object_deepSearch, deepSearch
//!- camelCase         

//4?. => obj.nu('d.c') || Object.nu('d.c') || nu('d.c')

export const nu = function (p1, p2, p3) {
  if (typeof p1 === Ø || !p1) {
    return p3
  }
  let [ob, s, def] = typeof p1 === 'object' ? [p1, p2, p3] : [this, p1, p2]        
  const proparr = s.split('.')
  for (const nextprop of proparr) {
    if (nextprop && typeof ob === 'object' && ob) {
      //if (nextprop.includes('[')) {//4array :-( better check: last char is ']'
      if (nextprop.endS(']')) {//4array :-( better check: last char is ']'
        const nextproparr = nextprop.split('[')
        const prop = nextproparr[0]
        if (isArr(ob[prop])) {                  
          const index = nextproparr.slice(1)[0].slice(0, -1)//9should be a number
          ob = ob[prop][index]
        } else {
          return def//4xxx[yyy] format, but xxx is not an array
        }
      } else {
        wassert(ob)
        ob = ob[nextprop]
      }
    } else {
      return def
    }
  }
  return typeof ob !== Ø ? ob : def
}
extendInternalType(Object, 'nu', nu)
/*
extendInternalType(Object, 'nu', function (p1, p2, p3) {
  if (typeof p1 === Ø || !p1) {
    return p3
  }
  let [ob, s, def] = typeof p1 === 'object' ? [p1, p2, p3] : [this, p1, p2]        
  const proparr = s.split('.')
  for (const nextprop of proparr) {
    if (nextprop && typeof ob === 'object' && ob) {
      //if (nextprop.includes('[')) {//4array :-( better check: last char is ']'
      if (nextprop.endS(']')) {//4array :-( better check: last char is ']'
        const nextproparr = nextprop.split('[')
        const prop = nextproparr[0]
        if (isArr(ob[prop])) {                  
          const index = nextproparr.slice(1)[0].slice(0, -1)//9should be a number
          ob = ob[prop][index]
        } else {
          return def//4xxx[yyy] format, but xxx is not an array
        }
      } else {
        wassert(ob)
        ob = ob[nextprop]
      }
    } else {
      return def
    }
  }
  return typeof ob !== Ø ? ob : def
})

export const nu = Object.nu
*/
extendInternalType(Object, 'capture', function (extras) {
  if (isArr(this) && isArr(extras)) {
    this.push(...extras)
    /*for (const extra of extras) {
      this.push(extra)
    }*/
  } else if (isObj(this) && isObj(extras)) {
    for (const key in extras) {
      this[key] = extras[key]
    }
  } else {
    console.log('object.capture cannot mix arg types.', this, extras)
    debugger
  }
  return this
})
extendInternalType(Object, 'extract', function (essence) {
  if (isArr(this) && isArr(essence)) {
    return essence.map(index => this[index])
  } else if (isObj(this) && isStr(essence)) {
    const keyArr = essence.split(essence.includes(',') ? ',' : ' ')
    const reto = {}
    for (const key of keyArr) {
      reto[key] = this[key]
    }
    return reto
  } else {
    console.log('object.extract cannot mix arg types.', this, essence)
    debugger
  }
})
//const maci = ['alma', 'korte', 'banan', 'citrom', 'dinnye', 'kiwi']
//console.log(maci.extract([0, 2, 3, 5, 6]))

extendInternalType(Object, 'toArr', function (obj) { //: obj.loopValues(fun)
  obj = obj || this
  const ret = []
  isObj(obj) && obj.loopKeys(key => ret.push(typeof obj[key] === 'object' ? {key, ...obj[key]} : {key, val: obj[key]}))
  return ret
})

extendInternalType(Object, 'propertiesToArr', function (obj) { //: obj.loopValues(fun)
  obj = obj || this
  const ret = []
  if (isObj(obj)) {
    for (const prop in obj) {
      ret.push(prop)
    }
  }
  return ret
})

extendInternalType(Object, 'loopKeys', function (loopFun) { //: obj.loopKeys(fun)
  if (isArr(this)) {
    console.log('loopKeys called for an array.', this)
    debugger
  }
  const itera = Object.assign({}, this)
  const ret = {}
  for (const property in itera) {
    ret[property] = loopFun(property)
  }
  return ret
})

extendInternalType(Object, 'loopValues', function (loopFun) { //: obj.loopValues(fun)
  if (isArr(this)) {
    console.log('loopValues called for an array.', this)
    debugger
  }
  const itera = Object.assign({}, this)//9{...this}
  const ret = {}
  for (const property in itera) {
    ret[property] = loopFun(itera[property])
  }
  return ret
})

extendInternalType(Object, 'loopKeysValues', function (loopFun) { //: obj.loopKeysValues(fun)
  if (isArr(this)) {
    console.log('loopKeys called for an array.', this)
    debugger
  }
  const itera = Object.assign({}, this)
  const ret = {}
  for (const property in itera) {
    ret[property] = loopFun(property, itera[property])
  }
  return ret
})

extendInternalType(Object, 'loopKeysValues', function (loopFun) { //: obj.loopKeysValues(fun)
  if (isArr(this)) {
    console.log('loopKeys called for an array.', this)
    debugger
  }
  const itera = Object.assign({}, this)
  const ret = {}
  for (const property in itera) {
    ret[property] = loopFun(property, itera[property])
  }
  return ret
})

extendInternalType(Object, 'allKeysEx', function (obj) { //: obj.allKeys() | Object.allKeys(obj)
  obj = obj || this
  const keys = []
  for (const key in obj) keys.push(key)
  return keys
})//9ez szar, a getOwnPropertyNames kellene inkabb, bar lehet, h az el fogja baszni a moisture-t, szoval egy realAllKeys talan
    
extendInternalType(Object, 'allKeys', function (obj) { //: obj.allKeys() | Object.allKeys(obj)
  obj = obj || this
  return Object.getOwnPropertyNames(obj)
})

extendInternalType(Object, 'getPropertyCnt', function (par1, par2) { // (own | obj | obj, own)
  if (par1 === null) {
    return 0
  }
  const par1Obj = typeof par1 === 'object' && par1
  // par1Obj === true
  //   Object.getPropertyCnt(myObj)
  //   Object.getPropertyCnt(myObj, filterOwn)
  //   otherObj.getPropertyCnt(myObj)             // otherObj is N/A
  //   otherObj.getPropertyCnt(myObj, filterOwn)  // otherObj is N/A
  // par1Obj === false
  //   myObj.getPropertyCnt()
  //   myObj.getPropertyCnt(filterOwn)
  //   Object.getPropertyCnt()                    // INVALID ('Object' is calculated)
  //   Object.getPropertyCnt(filterOwn)           // INVALID ('Object' is calculated)
  
  const obj = par1Obj ? par1 : this
  const filterOwn = par1Obj ? !!par2 : !!par1
  return Object[(filterOwn ? 'keys' : 'allKeys')](obj).length 
})

extendInternalType(Object, 'isOwnProperty', (obj, prop) =>
  // robust version of hasOwnProperty (works for empty objects too)
  obj.hasOwnProperty === 'function' ? Object.prototype.hasOwnProperty.call(obj, prop) : true
  // empty objects doesn't have any inherited properties, so it's always true
  // non-standard AND non-empty objects still can have problems here
)

//%Object.deepCopy()

export const objectDeepCopy = sourceObj => { // no circular (repeated object) check!
  if (isObj(sourceObj) && !sourceObj) {
    return null
  }
  const targetObj = isArr(sourceObj) ? [] : {}
  if (isArr(sourceObj)) {
    for (const a of sourceObj) {
      targetObj.push(typeof a === Ø ? void 0 : objectDeepCopy(a))
    }
  } else if (isObj(sourceObj)) {
    sourceObj.loopKeys(p => {
      targetObj[p] = typeof sourceObj[p] === Ø ? void 0 : objectDeepCopy(sourceObj[p])
    })
  } else {
    return sourceObj
  }
  return targetObj
}

ET(Object, 'deepCopy', function () { return objectDeepCopy(this) })

//export const objectDeepCopy = Object_deepCopy

//%Object.deepTransform(transFun)

export const deepTransform = (sourceObj, transFun) => { // no circular (repeated object) check!
  if (isObj(sourceObj) && !sourceObj) {//null? why?
    return null
  }
  const targetObj = isArr(sourceObj) ? [] : {}
  if (isArr(sourceObj)) {
    for (const a of sourceObj) {
      targetObj.push(typeof a === Ø ? void 0 : deepTransform(a, transFun))
    }
  } else if (isObj(sourceObj)) {
    sourceObj.loopKeys(p => {
      targetObj[p] = typeof sourceObj[p] === Ø ? void 0 : deepTransform(sourceObj[p], transFun)
    })
  } else {
    return transFun(sourceObj)
  }
  return targetObj
}

ET(Object, 'deepTransform', function (transFun = _ => _) { return deepTransform(this, transFun) })

//%Object.safeDeepCopy()  

const Object_safeDeepCopy = (sourceObj, params) => { // no circular (repeated object) check!
  try {
  const dontRepeat = []
  const exKeys = (params || {}).ex || []
  
  const _Object_safeDeepCopy = sourceObj => {
    if (isObj(sourceObj) && !sourceObj) {
      return 'OSDC:null'
    }
    if (dontRepeat.includes(sourceObj)) {
      return 'OSDC:circularObj'
    }
    
    const targetObj = isArr(sourceObj) ? [] : {}
    if (isArr(sourceObj)) {
      for (const a of sourceObj) {
        targetObj.push(typeof a === Ø ? undef : _Object_safeDeepCopy(a))
      }
    } else if (isObj(sourceObj)) {
      dontRepeat.push(sourceObj)
      if (typeof sourceObj.loopKeysValues !== 'function') {
        return 'OSDC:alienType'
      }
      sourceObj.loopKeysValues((key, value) => {
        if (exKeys.includes(key)) {
          targetObj[key] = 'OSDC:bannedKey'
        } else {
          targetObj[key] = typeof value === Ø ? undef : _Object_safeDeepCopy(value)
          dontRepeat.push(value)
        }
      })
    } else if (typeof sourceObj === 'fXR-----unction') {
      return 'OSDC:function'
    } else {
      return sourceObj
    }
    return targetObj
  }
  return _Object_safeDeepCopy(sourceObj)
  } catch (err) {
    console.log(err, err.message, err.stack)
  }
}

ET(Object, 'safeDeepCopy', function (params) { return Object_safeDeepCopy(this, params) })

//%Object.deepSearch(pattern, params)

const Object_deepSearch = (obj, pat, params = {}) => {
  const depth = params.depth || 3
  const results = []
  const propResults = []
  const cleared = []
  let counter = 0
  const concise = false
  
  const _Object_deepSearch = (obj, path, depth, parent) => {
    if (depth) {
      counter++
      params.logLines && console.log(`entering level ${depth}:`, path)
      if (cleared.includes(obj)) {
//= #ifdef __test        
        //const loc = depth + ':' + path
        //console.log(`already scanned: ${loc}`)
//= #iendif        
        return
      } else if (typeof obj === 'object') {
        cleared.push(obj)
      }
      try {
        if (concise) {
          isArr(obj)
            ? obj.map((a, i) => _Object_deepSearch(a, path + '.' + i, depth - 1))
            : isObj(obj)
              ? obj.loopKeys(p => _Object_deepSearch(obj[p], path + '.' + p, depth - 1))
              : typeof obj === 'string' && obj.includes(pat) && results.push({path, obj})
        } else {
          if (isArr(obj)) {
            wassert(typeof obj.map === 'function')
            obj.map((a, i) => _Object_deepSearch(a, path + '.' + i, depth - 1, obj))
          } else if (isObj(obj)) {
            //if (typeof obj.loopKeys === 'function') {
              obj.loopKeys(p => {
                p.includes(pat) && propResults.push({path, parent, obj})
                p.beginS('apiproxy') || _Object_deepSearch(obj[p], path + '.' + p, depth - 1, obj)
              })
            //} else {
              //console.log(`loopKeys not a function: ${loc}`)
            //}
          } else {
            typeof obj === 'string' && obj.includes(pat) && results.push({path, parent, obj})
          }
        }
      } catch (err) { console.warn(err.message, path, depth, parent, err) }
    }
  }
  
  _Object_deepSearch(obj, 'Obj', depth)
  console.log(`${counter} items scanned, dups: ${cleared.length}.`)
  results.map(r => r.obj.substr(0, 2) === '{"' && (r.parsed = safeParseJSON(r.obj)))
  results.map(console.log)
  //debugger
  return {results, propResults}
}

ET(Object, 'deepSearch', function (pat, params) { return Object_deepSearch(this, pat, params) })

const Object_quantum = obj => {
  const _q = {
    props: obj.getPropertyCnt(),
    tstrings: 0,
    vstrings: 0,
    vnumbers: 0,
    astring: 0,
    qvalid: true
  }
  obj.loopKeysValues((key, val) => {
    if (key !== '_q') {
      _q[`t${typeof val}s`] = (_q[`t${typeof val}s`] || 0) + 1
      if (typeof val === 'string' && val.length) {
        _q.astring += val.length
        _q.vstrings++
      } else if (typeof val === 'number' && val) {
        _q.vnumbers++
      }
    }
  })
  _q.quantSum = _q.props + _q.vstrings * 3 +  _q.vnumbers * 2 - (_q.tfunctions / 2 || 0) 

  return _q
}
ET(Object, 'quantum', function () { return Object_quantum(this) })  

const Object_quantify = obj => {
  if (!obj._q || obj._q.qvalid) {
    obj._q = obj.quantum()
  }
  return obj
}
ET(Object, 'quantify', function () { return Object_quantify(this) })

const Object_unQuantify = obj => {
  if (obj._q && obj._q.qvalid) {
    delete obj._q
  }
  return obj
}
ET(Object, 'unQuantify', function () { return Object_unQuantify(this) })

const Object_quantumRecap = obj => {
  obj = obj || {}
  const _q = obj._q && obj._q.qvalid 
    ? obj._q : obj.qvalid
      ? obj 
      : obj.quantum()
      
  const ret = []
  for (const key in _q) {
    const paren = key === 'tstrings' && _q.vstrings 
      ? '(' + _q.vstrings + ')'
      : key === 'tnumbers' && _q.vnumbers 
       ? '(' + _q.vnumbers + ')'
       : ''
       
    key[0] === 't' && ret.push(key.substr(1, 2) + '=' + _q[key] + paren)
  }
  _q.astring && ret.push('aggS=' + _q.astring)
  ret.push('_su=' + _q.quantSum)
  //_q.vstrings && ret.push('vs=' + _q.vstrings, 'as=' + _q.astring)
  //_q.vnumbers && ret.push('vn=' + _q.vnumbers)
  return ret.sort().reverse().join(', ')
}
ET(Object, 'quantumRecap', function () { return Object_quantumRecap(this) })
