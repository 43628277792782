/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, no-unused-vars, spaced-comment
*/
import PropTypes from "prop-types";
import clsx from "clsx";
import { useRef, forwardRef, useState,useEffect } from "react";
import * as Dialog from "../designSystem/Dialog";
import Spinner from "../designSystem/Spinner";
import {useCryptoartContract} from '../contracts/useCryptoartContract'
import {getFragmentsByIds} from '../wallet/queries'
import {Sidebar} from '../components/Sidebar'
import FragmentColorChart from '../wallet/FragmentColorChart'
import SVG from 'react-inlinesvg';
import {useToast} from '../hooks'


const MintButton = forwardRef(({ children, className, loading, disabled, px, ...props }, ref) => (
  <button
    ref={ref}
    className="_MintButton fm-link-button py-1 px-2 text-lg font-bold w-full"
    style={{
      border: "1px solid #228b22",
      padding: '.35rem .5rem'
    }}
    disabled={loading || disabled}
    type="button"
    {...props}
  >
    {/* {console.log('++++++Button', {loading, disabled})} */}
    <span className={loading ? "opacity-0" : null}>{children}</span>
    {loading ? (
      <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </span>
    ) : null}
  </button>
))

const svgHead = '\
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 1120 760">\
  <style>\
    .c0 {fill: url(#c0);}\
    .c1 {fill: url(#c1);}\
    .c2 {fill: url(#c2);}\
    .c3 {fill: url(#c3);}\
    .c4 {fill: url(#c4);}\
    .c5 {fill: url(#c5);}\
    .c6 {fill: url(#c6);}\
  </style>\
  <defs>\
  <linearGradient id="c0" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(0,75%,50%)" offset="10%"/>\n\
    <stop stop-color="hsl(0,75%,40%)" offset="50%"/>\n\
    <stop stop-color="hsl(0,75%,30%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c1" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(25,85%,60%)" offset="10%"/>\n\
    <stop stop-color="hsl(25,85%,50%)" offset="50%"/>\n\
    <stop stop-color="hsl(25,85%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c2" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(55,75%,60%)" offset="10%"/>\n\
    <stop stop-color="hsl(55,75%,50%)" offset="50%"/>\n\
    <stop stop-color="hsl(55,75%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c3" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(140,75%,50%)" offset="10%"/>\n\
    <stop stop-color="hsl(140,75%,40%)" offset="50%"/>\n\
    <stop stop-color="hsl(140,75%,30%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c4" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(200,75%,60%)" offset="10%"/>\n\
    <stop stop-color="hsl(200,75%,50%)" offset="50%"/>\n\
    <stop stop-color="hsl(200,75%,40%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c5" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(300,75%,50%)" offset="10%"/>\n\
    <stop stop-color="hsl(300,75%,40%)" offset="50%"/>\n\
    <stop stop-color="hsl(300,75%,30%)" offset="90%"/>\n\
  </linearGradient>\
  <linearGradient id="c6" x1="0" x2="1" y1="0" y2="1">\
    <stop stop-color="hsl(0,0%,30%)" offset="10%"/>\n\
    <stop stop-color="hsl(0,0%,20%)" offset="50%"/>\n\
    <stop stop-color="hsl(0,0%,10%)" offset="90%"/>\n\
  </linearGradient>\
  </defs>';

function CalculatorDialogTrigger({
  label,
  loading,
  disabled,
  onOpenChange,
  open,
  ready
}) {
 
  const contractAdapter = useCryptoartContract()
  const {tileLimits, ZILLION} = contractAdapter.helpers

  const [alien, setAlien] = useState('')
  const [alienList, setAlienList] = useState([])
  const [fragList, setFragList] = useState([])
  const [displayError, setDisplayError] = useState(false)
  let Sum = [0,0,0,0,0,0]
  const toast = useToast()

  const idHash = {}
  for (const frag of fragList) {
    idHash[frag.id] = frag
  }

  const z6 = ZILLION * 6

  const calcScore = squareCount => {
    let fullness = 0
    let fullFaces = 0
    for (let ix = 0; ix < 6; ix++) {
      fullness += squareCount[ix] * ZILLION / tileLimits[ix]
      if (squareCount[ix] === tileLimits[ix]) {
        fullFaces++
      }
    }
   return fullFaces === 6 ? 6 : (fullness / z6 + fullFaces)
  }
 
  useEffect(() => {
    let mounted = true
   
    contractAdapter.onReady().then(() => {
      Promise.all([ getFragmentsByIds({ids: alienList})])
        .then(([alienFragments]) => {
          if (mounted) {
            const fragments = [...alienFragments.map(a => ({...a, isAlien: true}))]
            setFragList(fragments)
          }
        })
        .catch(err => {
          console.log({...err})
        })
    })
    return () => mounted = false
  }, [contractAdapter, alienList])


  const onAddAlien = () => {
    if(alienList.length >= 6){
      // toast('error', `Cannot add more than four fragments.`, 15E3)
      setDisplayError(true)
      return
    }
    setDisplayError(false)
    if (alien !== '') {
      const alienArray = []
      if (alien.includes('-')) {
        const [_from, _to] = alien.split(' ').join('').split('-')
        const [from, to] = [~~_from, ~~_to]
        for (let ix = from; ix <= to; ix++) {
          alienArray.push(ix)
        }
      } else {
        alienArray.push(~~alien)
      }
     
      
      for (const newAlien of alienArray) {
        if (alienList.includes(newAlien)) {
          console.log('duplicated alien:', alien)
          toast('error', `Cannot add an extra fragment twice.`, 15E3)
          return
        }
        if (idHash[newAlien]) {
          console.log('cannot add your own as an alien:', alien)
          toast('error', `Cannot add your own fragment as an extra.`, 15E3)
          return
        }
      }
      console.log('adding', {alienList, alienArray})
      setAlienList([...alienList, ...alienArray])
      setAlien('')
    }
  }
  const onResetAlienList = () => { 
    setAlienList([]) 
    setDisplayError(false)
  }

  const getAlienErrors = () => {
    const err = []
    for (const alienId of alienList) {
      if (!idHash[alienId] ) {
        err.push(<div>{`Fragment #${alienId} has gone into the black hole.`}</div>)
      }
    }
    return err
  }

  const getAlienListStr = (cnt = 8) => alienList.slice(0, cnt).join(', ') + (alienList.length > cnt ? '...' : '')
  
  ////////////////////////////////////////////////////////////////////

  const zoom = 2;
  const facewi = 168 * zoom;
  const facehi = 168 * zoom;

  const dimsX = [20, 200, 380, 20, 200, 380];
  const dimsY = [20, 20, 20, 200, 200, 200];


  const shuffleArray = ( array, length) => {
    let newArray=[];
    let k = 0;
    for (let i = length ; i > 0; i--) {
      newArray[k] = array[i-1];
      k++;
    }
    return newArray;
  }

  function squareCount2faces( col,  squareCount) {
    let max = (col + 3) * (col + 3)
    let shuffled = [];

    for (let i = 0; i < max; i++ ) {
      shuffled[i] = i < squareCount ? 1 : 0;
    }
   return shuffleArray(shuffled, max);
  }

  function drawFace( i,  faces) {
    let side = i + 3;
    let fawi = (facewi + (side / 2)) / side;
    let wihi = (fawi - zoom).toString();
    let xy = [(dimsX[i] - 4) * zoom, (dimsY[i] - 4) * zoom];
    let faceCnt = 0;
   let out = '';
    for (let yy = 0; yy < side; yy++) {
      let y = xy[1] + yy * fawi;
      for (let xx = 0; xx < side; xx++) {
        let x = xy[0] + xx * fawi;
        let classs = faces[faceCnt++] > 0 ? i : 6;
        out = 
          out+
          '<rect class="c'+ classs.toString()+ '" x="'+ x.toString()+'" y="'+ y.toString()
          + '" width="' + wihi + '" height="' + wihi + '"></rect>'
      }
    }
    
    return out;
  }
  const drawFrag = (squareCount) => {
    let facesArr=[];
    let svgs=[];
    
   
    for (let i = 0; i < 6; i++ ) {
      facesArr[i] = squareCount2faces(i, squareCount[i]);
      svgs[i] = drawFace(i, facesArr[i]);
    }
  
   return svgs[0]+ svgs[1]+svgs[2]+ svgs[3]+ svgs[4]+ svgs[5];
  }

  const genSVG = (squareCount) => {
   
    return svgHead +
      '<rect x="0" y="0" width="1120" height="760" style="fill:#00000080"/>'+
      drawFrag(squareCount)+
      '</svg>'
    ;
  }


  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Overlay />
        {ready && (
        <Dialog.Trigger as={MintButton} loading={loading} disabled={disabled}>
          {label}
        </Dialog.Trigger>
      )}
    
      <Dialog.Content
        aria-describedby="mint-fragments-description"
        aria-label="Mint fragments"
        style={{width:"95%"}}
      >
        <Dialog.Body>
          <Dialog.CloseButton />
          <form
            className="_MintPurchaseDialogTrigger flex flex-col items-center space-y-12">
            <p 
              className="text-white mt-1 text-center" >Calculate the Combination of Fragments 
              <br/> Add fragments to temporary simulate combinations.
              <p className="text-green-450 text-sm mt-2">Press Reset to restart.</p>
            </p>
             
              <div className='calculator-wrap fm-terminal-font text-sm text-green-452 '>
                <div className='alien-label'>{`Enter ID (0-${contractAdapter?.stats?.totalSupply - 1}):`}</div>
                <input 
                  className='alien-input'
                  onChange={ev => setAlien(ev.target.value)} 
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      onAddAlien()
                    }
                  }} value={alien} />
                <div className = 'alien-cmd' onClick={onAddAlien}>{`Add ${alien}`}</div>
                <div className='alien-cmd' onClick={onResetAlienList}>Reset</div>
                <div className='alien-label'>{getAlienListStr(10)}</div>
                <div className='alien-errors'>{getAlienErrors()}</div>
             { displayError? <p className="text-red-600 text-sm mt-2 text-center">Cannot add more than six fragments.</p>:<></>}
              </div>

              <div className="flex flex-row flex-grow">
                  {fragList.map((frag, i) => {
                  const {id: fragId, squareCount, isReady, score } = frag
                  let HasOverLap = false
                  const scoreStr = score?.toFixed(3) || 0

                  for(let j = 0; j < 6 ; j++ ){
                    Sum[j] += squareCount[j]
                    if(Sum[j]>tileLimits[j])
                    {
                      HasOverLap = true;
                      break;
                    }
                  }
                
                  return [
                  <>
                    <div  key={`fragment_${fragId}`}>
                      <div className="flex flex-row flex-grow mx-6">
                        <p className=" text-bold text-green-450 text-xs text-left pb-1">Frag #{fragId}</p>
                        <p className="flex-1 text-bold text-green-450 text-xs text-right pb-1">Score : {scoreStr}</p>
                      </div>
                      <Sidebar justSVG={true} previewFrag={frag} />
                      <FragmentColorChart fragmentId={fragId} {...{squareCount, isReady}} isCalculator={true}/>
                    </div>
                    { i < fragList.length-1 ?
                    <p className=" text-bold text-green-450 text-3xl text-center" style={{marginTop:"6%"}}>+</p>:
                    <>
                    {fragList.length > 1 ?
                    <>
                    {HasOverLap ?
                    <>
                    <br />
                    <p className=" text-bold text-green-450 text-3xl text-center" style={{marginTop:"6%"}}>=</p>
                    <p className="text-white font-bold text-xl text-center text-red-600  ml-6" >Cannot Combine! <br />There is an overlap.<br /> Please Reset.</p> 
                    </>
                    :<>
                    <p className=" text-bold text-green-450 text-3xl border-green-450 text-center" style={{marginTop:"6%"}}>=</p>
                    <div className="mx-1"  key={`result${fragId+1}`}>

                    <div className="flex flex-row flex-grow mx-1">
                        <p className="flex-1 text-bold text-green-450 text-xs text-center pb-1">Score : {calcScore(Sum).toFixed(3)}</p>
                      </div>
                      <div className="flex text-center justify-center">
                        <SVG src={genSVG(Sum)} width={"75%"}/>
                      </div>
                      <FragmentColorChart fragmentId={5000} squareCount={Sum} isReady={true} isCalculator={true}/> 
                    </div>
                    </>}
                    </>:<></>
                    }</>
                    }
                  </>
                  ]})}
              </div>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}

CalculatorDialogTrigger.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired
};

export default CalculatorDialogTrigger;