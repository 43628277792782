import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useWallet } from "use-wallet";
import { ethers } from "ethers";
import { usePrevious } from "../utils/usePrevious";
import EthersWalletContext from "./EthersWalletContext";

/**
 * Exposes ethers.js Web3Provider instance via the useWallet() hook.
 */
function EthersWalletProvider({ children }) {
  const wallet = useWallet();
  const previousAccount = usePrevious(wallet.account);
  const queryClient = useQueryClient();

  const ethersProvider = useMemo(
    () =>
      window.ethereum
        ? new ethers.providers.Web3Provider(window.ethereum)
        : null,
    []
  );

  const value = useMemo(
    () => ({ ...wallet, ethersProvider }),
    [wallet, ethersProvider]
  );

  // Reset React Query cache if account changes
  useEffect(function accountChangedEffect() {
    if (wallet.status !== "connected" || previousAccount === wallet.account) {
      return;
    }

    // eslint-disable-next-line no-console
    console.info("Account changed:", wallet.account);
    queryClient.clear();
  });

  return (
    <EthersWalletContext.Provider value={value}>
      {children}
    </EthersWalletContext.Provider>
  );
}

EthersWalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EthersWalletProvider;
