/* eslint-disable no-bitwise */

const env = {
  // CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
  // CHAIN_ID: ~~process.env.REACT_APP_CHAIN_ID,
  METADATA_API_URL: process.env.REACT_APP_METADATA_API_URL,
  MERKLE_API_URL: process.env.REACT_APP_MERKLE_API_URL,
  CONTRACT: process.env.REACT_APP_CONTRACT,
}

export { env }