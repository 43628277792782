/* eslint-disable consistent-return */

import {Corelib} from './improxy-red.js'

const {wassert} = Corelib.Debug
const {adelay} = Corelib.Tardis

const log = console.log
const wlog = (...args) => console.log('%c' + args.shift(), 'color: #b00', ...args)
//const olog = (...args) => console.log('%c' + args.shift(), 'color: #0a0', ...args)

export const initDisconnectedMetaMask = async (ethereum, adapter) => {
  //return
  const cry = {metaMask: {}}
  // A Web3Provider wraps a standard Web3 provider, which is what MetaMask injects as window.ethereum
  if (ethereum?.isMetaMask) {                                
    cry.provider = new adapter.ethers.providers.Web3Provider(ethereum)
    console.log(`===MetaMask provider:`, cry.provider)

    cry.metaMask.networkVersion = ethereum.networkVersion   // 4
    cry.metaMask.selectedAddress = ethereum.selectedAddress // metamask ethereum account 02d
    console.log(`===Metamask spec`, cry.metaMask)
    cry.modeMetaMask = true
    cry.isReady = true
    let failed = false

    try {
      cry.accounts = await ethereum.request({method: 'eth_requestAccounts'}) // [02d] -> more?
        .catch(err => {
          failed = true
          if (err?.message.includes('already pending')) {
            console.warn(err)
          } else {
            console.error('===reqPerm', err)
          }
        })
    } catch (err) {
      failed = true
      console.warn('failed', {err})
    }
    console.log('===MetaMask accounts re ethereum requestAccount', cry.accounts)
    if (failed) {
      return
    }

    // const a = await ethereum.request({ // first metamask popup -> connect, maybe not needed?
    //   method: 'wallet_requestPermissions',
    //   params: [{eth_accounts: {}}]
    // })

    cry.metaMaskWalletRequestPermissions = async () => {
      try {
        const resp = await ethereum.request({ // first metamask popup -> connect, maybe not needed?
          method: 'wallet_requestPermissions',
          params: [{eth_accounts: {}}]
        })
          .catch(err => {
            if (err?.message.includes('already pending')) {
              console.warn(err)
            } else {
              console.error('===reqPerm', err)
            }
          })

        const accountArray = resp?.[0]?.caveats?.[1]?.value || []
        console.log('===wallet_requestPermissions', {accountArray})
        if (accountArray.length > cry.accounts.length) {
          cry.accounts = accountArray
          // fix maybe call somebody, carly
          //cVerse.AddToLocalUsers(accountArray)
        }
      } catch (err) {
        console.warn(`===MetaMask error tried to slip away!`, err)
      }
    }

    if (!cry.accounts?.length) {
      await adelay(1000)
      await cry.metaMaskWalletRequestPermissions()
      return
    }

    // ethereum.on('accountsChanged', accounts => {
    //   // Time to reload your interface with accounts[0]!
    //   mlog(`Accounts changed!`, accounts)
    // })

    ethereum.on('chainChanged', chainId => {
      // Handle the new chain. Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have good reason not to.
      console.log(`===chainId changed!`, chainId)
      setTimeout(window.location.reload, 3000)
    })
  } else {
    console.error(`===MetaMask not found.`)
  }
}

export const waitForMetaMask = adapter => {
  wassert(adapter)
  adapter.ethereumInited = false

  const {_state = {}} = window.ethereum   // This is just for logging.
  const {accounts = [], initialized, isConnected, isUnlocked} = _state
  log(`🦊MetaMask: window.ethereum injected`, {initialized, isConnected, isUnlocked}, window.ethereum)
  if (!initialized) {
    log(`🦊MetaMask is not ready yet, waiting for .5secs...`)
    setTimeout(() => waitForMetaMask(adapter), 500)
    return
  }
  // intialized!!!!!
  log(`🦊MetaMask is ready!`)
  adapter.ethereumInited = true
  window.ethereum.on('accountsChanged', adapter.processAccountsOnChange)
  log(`🦊MetaMask: window.ethereum accounts:`, accounts)
  if (!accounts?.length) {
    wlog(`🦊MetaMask: Accounts: Please setup/login into your MetaMask!`)
    adapter.invalidateWallet()
    //adapter.setSelectedAccount('0x02dCCbFCC8Df72b8E915A534344C67f093FCA6e5')
    //validateWallet()
    //initDisconnectedMetaMask(window.ethereum, adapter)
    return
  } else {
    adapter.validateWallet()
  }
  //adapter.setSelectedAccount(accounts[0])
  log(`🦊MetaMask: accounts: YAY!!!!`)
  adapter.processAccountsOnChange(accounts) // CHK account load on init -> this is a bad place for this!
  //adapter.setSelectedAccount(window.ethereum.selectedAddress)
}
