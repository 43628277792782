/* eslint-env browser, node, worker */
/* eslint-disable guard-for-in, no-await-in-loop, import/extensions, no-return-assign */
/* eslint-disable no-unused-vars */

/* eslint-disable object-curly-spacing, no-multi-spaces, object-property-newline, quotes,
   multiline-ternary, spaced-comment, no-trailing-spaces, no-debugger, no-floating-decimal */

import {Corelib, Logre} from './improxy-red.js'
import {tabLog} from './misc.js'

const {brexru} = Corelib.Debug
const {createPerfTimer} = Corelib.Tardis
const {inspectAsTable} = Logre

export const extendContractAdapterWithLeaderboard = ({adapter}) => {
  const {cache, helpers} = adapter
  const {
    isReadContractValid, log, mlogFrags, rlog, flog, createThrottledAsyncGetter, logOn, chkrg
  } = helpers

  const objectToArray = obj => {
    const arr = []
    for (const key in obj) {
      arr.push(obj[key])
    }
    return arr
  }

  const inspectFragList = (frags, msg, {exp = true, hueRot = 0} = {}) => {
    if (!logOn.log) {
      return
    }
    if (!Array.isArray(frags)) {
      frags = objectToArray(frags)
    }
    const fmap = frags.map(({
      id = brexru(), drop, isReady, isRaw, combined, fullnessFloat = brexru(), isWinner, 
      leaderboardPos: lbPos, leaderboardValue: lbValue
    } = brexru()) => ({
      id, drop,
      status: `Ry${chkrg(isReady)} Rw${chkrg(!isRaw)}.`,
      combined, lbPos, lbValue, fullFlt: fullnessFloat.toFixed(3), isWinner, isRaw, isReady
    }))
    exp ? console.group(msg) : console.groupCollapsed(msg)
    inspectAsTable(fmap, 'id:5,drop,status:15,combined,lbPos,lbValue,fullFlt,isWinner,isRaw,isReady', {hueRot})
    console.groupEnd()
  }

  window.inspectFragArr = ids => {
    const fragHash = cache.getFragHash() 
    inspectFragList(ids.map(id => fragHash.id[id]), 'win')
  }

  // HEAD start

  const maxMarkedPos = 1000
  const Curr = {
    isExtDropStateDirty: true
  }
  
  const recalcExtDropState = () => {
    const timer = createPerfTimer()
    const {drop, zkETH, topList: contractTopList = brexru() || [], actWinners, ...rest} = adapter.dropState
    const extDropState = {drop, k: zkETH, contractTopList, actWinners, ...rest}

    extDropState.topList = contractTopList
      .slice(0, maxMarkedPos)
      .map(({id}, pos) => ({id, pos, isWinner: pos < actWinners}))

    logOn.log && console.log({topList: extDropState.topList, contractTopList})
    rlog(`recalcExtDropState in ${timer.summary()}ms`)
    return adapter.extDropState = extDropState
  }

  const clearLeaderboardValuesFor = fragment => {
    if (fragment) {
      fragment.leaderboardPos = 0
      fragment.leaderboardValue = 0
      fragment.isWinner = false // CHK 
    } else {
      flog('clearLeaderboardValuesFor: undef fragment')
    }
  }

  // pub interface

  adapter.markTopFragments = () => {
    if (!adapter.hasLeaderboard) {
      // console.log('skip markTopFragments (!hasLb)')
      return
    }
    const fragHash = cache.getFragHash() // calls rehash(), not needed but will be skippd
    fragHash.combined.true.map(clearLeaderboardValuesFor)
    const {k, topList = []} = adapter.extDropState

    for (const item of topList) {
      const {id, pos, isWinner} = item
      const frag = fragHash.id[id] || cache.createBlankFragment(id)
      frag.isWinner = isWinner
      frag.leaderboardPos = pos + 1
      frag.leaderboardValue = frag.fullnessFloat * k
      item.frag = frag
    }
  }
  
  adapter.invalidateExtDropState = () => Curr.isExtDropStateDirty = true
  
  const updateExtDropState = async () => { // we HAVE adapter.dropState already updated
    if (!(isReadContractValid() && adapter.hasLeaderboard)) {
      return {}
    }
    if (!Curr.isExtDropStateDirty) {
      return adapter.extDropState
    }
    const timer = createPerfTimer()
    
    logOn.log && tabLog({ml: 80, bg: '#aa8', c: '#00a'}, `\n%c🥇🥈🥉updateExtDropState`)
    const topList = adapter.dropState.topList.slice(0, maxMarkedPos)
    const topListIds = topList.map(({id}) => id)
    const extDropState = recalcExtDropState() 
    
    await adapter.getFragments(topListIds) // will call markTopFragments
    inspectFragList(extDropState.topList.map(a => a.frag), `updateExtDrops: TopList after mark`)

    Curr.isExtDropStateDirty = false
    rlog(`updateExtDropState done in ${timer.sum().dur.sum}ms`, {extDropState})
    return extDropState
  }
  adapter.updateExtDropState = createThrottledAsyncGetter(updateExtDropState, log, 'updateExtDropState')

  adapter.getLeaderboard = async ({limit = 250} = {}) => { //____________ get LeaderBoard (dApp)
    if (!(isReadContractValid() && adapter.hasLeaderboard)) {
      return 0
    }
    await adapter.getDropState()
    const fragHash = cache.getFragHash()
    const {topList} = await adapter.updateExtDropState()
    const topFrags = topList.map(({frag}) => frag)
    return {isLatest: true, topFrags}
    // todo: limit
  }
}
