/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, spaced-comment, prefer-template, react/no-array-index-key,
   jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
*/
import React from 'react'
import Title from '../../designSystem/Title'
import {RightCollapsible} from '../../components/Collapsible'
// import {ReactComponent as FragmentCombine} from '../../icons/FragmentCombine.svg'
// import {ReactComponent as FragmentRedeem} from '../../icons/FragmentRedeem.svg'
// import {ReactComponent as FragmentShowCombinations} from '../../icons/FragmentShowCombinations.svg'
import Pagination from '../../components/Pagination'
import {useCryptoartContract} from '../../contracts/useCryptoartContract'
import CombinePanel from '../../wallet/CombinePanel'
import FragmentColorChart from '../../wallet/FragmentColorChart'
import FragmentColorChartVertically from '../../wallet/FragmentColorChartVertically'
import {FragmentColorRow} from '../../wallet/FragmentColorRow'
import {isMobile} from '../../utils/isMobile'
import { CubeMainWrapperMyFragmentListPage} from '../../components/CubeComponents'
import {Sidebar} from '../../components/Sidebar'

const Action = ({children, disabled, onClick, title}) => 
  <button className='fm-gray-button  py-1 px-1'  {...{disabled, onClick, title}}>{children}</button>

const Actions = ({
  isCombinePanelMode, frag, opsAccessState: {combineOn}, hasLeaderboard, isAlien, isSelectedFrag,
  onEnterCombinePanelMode, onCombineFragments
}) => 
  isCombinePanelMode
    ? 
    <Action disabled={!combineOn || isAlien || isSelectedFrag } onClick={() => onCombineFragments(frag)} title='Combine'>
        {/* <FragmentCombine style={{width: '20px'}} /> */}
        Confirm Combination
      </Action>
 
    : <>
        {(!hasLeaderboard || frag.matches.length > 0) && 
          <Action disabled={!combineOn} onClick={() => onEnterCombinePanelMode(frag)} title='Show Combinations'>
            {/* <FragmentShowCombinations style={{width: '20px'}} /> */}
            Show Combinations
          </Action>
        }
        {frag.combined &&
          <>
            {/* {hasLeaderboard &&
              <Action 
                disabled={!lbRedeemOn || isAlien} 
                onClick={() => onConfirmRedeemFragment(frag, true)} 
                title='White Whale Redeem'
              >
                <FragmentRedeem style={{width: '20px'}} />
              </Action>
            } */}
            {/* <Action 
              disabled={!genRedeemOn || isAlien} 
              onClick={() => onConfirmRedeemFragment(frag, false)} 
              title={hasLeaderboard ? 'Rainbow Whale Redeem' : 'Redeem'}
            >
              <FragmentRedeem style={{width: '20px'}} />
            </Action> */}
          </>
        }
      </>

const TableHeaderSortable = ({sorting, label, title = label}) => {
  const {sortLabels, sortingState, setSortingState} = sorting || {}
  const by = label.toLowerCase()

  if (sortLabels.includes(by)) {
    const act = sortingState.by === by
    const ascC = 'sort-arrow sort-asc ' + (act && sortingState.asc ? 'on' : '')
    const descC = 'sort-arrow sort-desc ' + (act && !sortingState.asc ? 'on' : '')
    return (
      <th className='fm-table-header sort-header' title={title}>
        {label}
        <div className={ascC} onClick={() => setSortingState({by, asc: true})} />
        <div className={descC} onClick={() => setSortingState({by, asc: false})} />
      </th>
    )
  } else {
    return <th className='fm-table-header' title={title}>{label}</th>
  }
}

const MyFragmentsList = ({
  isError, isCombinePanelMode, pageFollowMode,
  onPageTurn, inPage, inPos,
  selectedFrag, selectedId, selectedOtherId,
  onEnterCombinePanelMode, onLeaveCombinePanelMode,
  onSelectIdByFrag, onSimulateCombinationWith, onCombineFragments, onConfirmRedeemFragment,
  sorting, pagination, isCollapsed, setCollapsed,
  fragList, opsAccessState, below, hideSvg
}) => {
  const contractAdapter = useCryptoartContract()
  const {hasLeaderboard} = contractAdapter
  const hasPredScore = isCombinePanelMode && !hasLeaderboard
  const fragsLen = fragList.length
  let from = pagination.from -1
  let to = pagination.to 
  
  if (isError || (!isCombinePanelMode && fragsLen === 0)) {
    return <></>
  }

  const onWheel = event => {
    if (event.deltaY < 0 && pagination.page > 1) {
      onPageTurn(pagination.page - 1)
    }
    if (event.deltaY > 0 && pagination.page < pagination.lastPage) {
      onPageTurn(pagination.page + 1)
    }
  }

  const fragmentsTitle = `My Fragments` + (fragsLen ? ` (${fragsLen})` : ``)
  let fragments = fragList.slice(from, to)

  
  if(isCombinePanelMode){
   fragments = fragments.filter(x=>!x.hasOverlap && x.id != selectedFrag.id )
  }
  // console.log('MyFragmentsList', {from, to, pageSize}, pagination)

  const labelAndTitle = label => ({
    predScore: {label, title: 'Predicted score of the combined fragment'},
    Overlap: {label, title: 'The new fragment will be overlapped'}
  }[label] || {})
  
  return (
    <RightCollapsible className='_MyFragsListFrame' collapsible={true} open={!isCollapsed} onChange={setCollapsed}>
      {isCombinePanelMode && <CombinePanel onExit={onLeaveCombinePanelMode} frag={selectedFrag} />}  
      <div className='_MyFragmentsListHeader' onWheel={onWheel}>
        <div className='grid grid-cols-3 gap-2 place-content-center w-100'>
          <Title text={isCombinePanelMode ? 'Possible Combinations' : fragmentsTitle} />
          {isCombinePanelMode || isMobile()
            ? <div />
            : <div className='fm-terminal-font text-sm text-green-452 mb-2'>
                {`Selected: #${selectedId} `}
                <div className='inlink' onClick={() => onPageTurn(inPage, true)}>
                  {`p${inPage}.${inPos}`}&nbsp;{pageFollowMode ? '' : ' Follow'}
                </div>
              </div>
          }
          <div className={`_PaginationWrapper flex items-center justify-end mb-2 ${pageFollowMode ? 'dim' : ''}`}>
            <Pagination {...{pagination, onPageTurn}} />
          </div>
        </div>
      </div>
      <div className='_MyFragmentsListBody fraglist-wrap custom-scrollbar' comb={'' + isCombinePanelMode}>
        <table className='table-fixed fm-table text-gray-200 text-xs text-center'>
          <thead>
            <tr>
              <TableHeaderSortable {...{sorting, label: 'ID'}} />
              <TableHeaderSortable {...{sorting, label: 'Properties'}} />
              <TableHeaderSortable {...{sorting, label: hasLeaderboard ? 'Fullness' : 'Score'}} />
              {hasPredScore && <TableHeaderSortable {...{sorting, ...labelAndTitle('predScore')}} />}
              {/* {hasPredScore && <TableHeaderSortable {...{sorting, ...labelAndTitle('Overlap')}} />} */}
              {hasLeaderboard && <th className='fm-table-header'>#</th>}
              {hasLeaderboard && <th className='fm-table-header'>White Whale</th>}
              {/* <TableHeaderSortable {...{sorting, label: 'Redeem'}} /> */}
              {/* <TableHeaderSortable {...{sorting, label: 'Combined'}} /> */}
              <th className='fm-table-header'>Combine</th>
            
            </tr>
          </thead>
          <tbody>
            {fragments.map((frag, i) => {
              const {
                id: fragId, squareCount, combined, isReady, hasOverlap, isAlien,
                fullnessFloat, score, predScore, redeemStr, leaderboardPos, leaderboardValue
              } = frag
              const isSelectedFrag = selectedId === fragId
              const isSelectedOtherFrag = selectedOtherId === fragId
              const fullnessStr = fullnessFloat > 0 ? fullnessFloat.toFixed(3) : 0
              const scoreStr = score?.toFixed(3) || 0
              const predScoreStr = predScore?.toFixed(3) || 0
              const leaderboardValueStr = leaderboardValue?.toFixed(4) || 0
              const trClass = `fm-table-row data-text-row ${isCombinePanelMode ? 'combine-mode ' : ''}` +
                `${isSelectedFrag ? 'fm-table-row-selected ': ''}` + 
                `${isSelectedOtherFrag ? 'fm-table-row-other-selected ': ''}` +
                `${isAlien ? 'alien-frag ' : 'own-frag '}`

              return [
                <tr
                  key={`fragment_${fragId}`}
                  className={trClass}
                  onClick={() => {isCombinePanelMode ? onSimulateCombinationWith(frag) : onSelectIdByFrag(frag)}}
                >
                  <td>#{fragId}</td>
                  <td style={{maxWidth:"250px"}}>
                  {hideSvg ? <></>:
                  <Sidebar justSVG={true} previewFrag={frag} />}
                  <FragmentColorChart fragmentId={fragId} {...{squareCount, isReady}} />
                  </td>
                  <td>{hasLeaderboard ? fullnessStr : scoreStr}</td>
                  {hasPredScore && <td className='predScore'>{predScoreStr}</td>}
                  {/* {hasPredScore && <td className='overlap tc'>{hasOverlap ? 'Y' : 'N'}</td>} */}
                  {hasLeaderboard && <td>{leaderboardPos || '-'}</td>}
                  {hasLeaderboard && <td>{leaderboardValueStr}</td>}
                  {/* <td>{redeemStr}</td> */}
                  {/* <td className='tc'>{combined ? 'Y' : 'N'}</td> */}
                  <td className='tc'>
                  <Actions
                      {...{frag, opsAccessState, hasLeaderboard, isCombinePanelMode, isAlien, isSelectedFrag}}
                      {...{onEnterCombinePanelMode, onCombineFragments}}
                    />
                  </td>
                 
                </tr>,
                <FragmentColorRow key={i + 'fcr'} frag={frag} />
              ]}).flat()}
          </tbody>
        </table>
      </div>
      {below}
    </RightCollapsible>
  )
}

export default MyFragmentsList
