import { Route, Switch } from "react-router-dom";
import MyFragmentsPage from "./pages/MyFragmentsPage";
import LandingPage from "./pages/LandingPage";
import HistoryPage from "./pages/HistoryPage";
import {TosPage} from "./pages/TosPage";
import {FaqPage} from "./pages/faq";
import RecentActivityPage from "./pages/RecentActivityPage";
import HuntingPage from "./pages/HuntingPage";
// import {StatsPage} from "./pages/StatsPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProtectedRoute from "./routing/ProtectedRoute";


const Routes = () => 
// isMobile()
//   ? (
//     <Switch>
//       <ProtectedRoute path="*">
//         <MyFragmentsPage />
//       </ProtectedRoute>
//     </Switch>
//     )
//   : (
    <Switch>
      <ProtectedRoute exact path="/wallet">
        <MyFragmentsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path="/searching">
        <HuntingPage IsLeaderBoard={false}/>
      </ProtectedRoute>
      <ProtectedRoute exact path="/leaderboard">
        <HuntingPage IsLeaderBoard={true}/>
      </ProtectedRoute>
      <Route exact path="/">
      <LandingPage />
      </Route>
      {/* <Route exact path="/instructions">
      <InstructionPage />
      </Route> */}
      <Route exact path="/history">
        <HistoryPage />
      </Route>
      <Route exact path="/tos">
        <TosPage />
      </Route>
      <Route exact path="/faq">
        <FaqPage />
      </Route>
      <Route exact path="/recentActivity">
        <RecentActivityPage />
      </Route>
      <Route exact path="/home">
        <LandingPage />
      </Route>
     
      {/* <Route exact path="/stats">
        <StatsPage />
      </Route> */}
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
    //)

export default Routes;
