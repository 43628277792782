/* eslint-disable react/prop-types, react/button-has-type, react/jsx-boolean-value, react/jsx-no-bind,
   react/jsx-sort-props, react/self-closing-comp, jsx-a11y/control-has-associated-label,
   no-unused-expressions, no-unused-vars, spaced-comment
*/
import PropTypes from "prop-types";
import clsx from "clsx";
import { useRef, forwardRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Dialog from "../designSystem/Dialog";
import Button from "../designSystem/Button";
import NumberField from "../designSystem/NumberField";
import Spinner from "../designSystem/Spinner";
import {TosLink} from '../pages/TosPage'

const MintButton = forwardRef(({ children, className, loading, disabled, px, ...props }, ref) => (
  <button
    ref={ref}
    className="_MintButton fm-link-button"
    style={{
      border: "1px solid #228b22",
      padding: '.35rem .5rem'
    }}
    disabled={loading || disabled}
    type="button"
    {...props}
  >
    {/* {console.log('++++++Button', {loading, disabled})} */}
    <span className={loading ? "opacity-0" : null}>{children}</span>
    {loading ? (
      <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </span>
    ) : null}
  </button>
))

const MintButtonHomePage = forwardRef(({ children, className, loading, disabled, px, ...props }, ref) => (
  <button
    ref={ref}
    className="_MintButton fm-link-button py-3 px-6 text-3xl font-bold"
    style={{
      border: "1px solid #228b22",
    }}
    disabled={loading || disabled}
    type="button"
    {...props}
  >
    {/* {console.log('++++++Button', {loading, disabled})} */}
    <span className={loading ? "opacity-0" : null}>{children}</span>
    {loading ? (
      <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </span>
    ) : null}
  </button>
))

function MintPurchaseDialogTrigger({
  label,
  loading,
  disabled,
  onMint,
  onOpenChange,
  open,
  ready,
  homepageCta=false
}) {
  const numberFieldRef = useRef();
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      count: 2,
    },
  });
  const { isSubmitting } = formState;

  async function commitAndSubmit(data) {
    numberFieldRef.current.commit();
    return onMint(data);
  }

  

  //console.log(`$$$$MintPurchaseDialogTrigger`, {disabled, loading})

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Overlay />
      {/* {ready && <>
        {homepageCta ?
        <Dialog.Trigger as={MintButtonHomePage} loading={loading} disabled={disabled}>
          {label}
        </Dialog.Trigger>
        : 
        <Dialog.Trigger as={MintButton} loading={loading} disabled={disabled}>
        {label}
       </Dialog.Trigger>}
        </>} */}
        {ready && (
        <Dialog.Trigger as={MintButton} loading={loading} disabled={disabled}>
          {label}
        </Dialog.Trigger>
      )}
    
      <Dialog.Content
        aria-describedby="mint-fragments-description"
        aria-label="Mint fragments"
      >
        <Dialog.Body>
          <Dialog.CloseButton />
          <form
            className="_MintPurchaseDialogTrigger flex flex-col items-center space-y-12"
            onSubmit={handleSubmit(commitAndSubmit)}
          >
            <p 
              id="mint-fragments-description"
              className="text-white mt-4"
            >
              How many Fragments would you like to mint?
            </p>
            <p 
              id="mint-fragments-description"
              className="text-white mt-1"
            >
             In order to do combinations, you need at least two fragments.
            </p>
            <Controller
              control={control}
              name="count"
              render={({ field: { ref, ...fieldProps } }) => (
                <NumberField
                  ref={numberFieldRef}
                  {...fieldProps}
                  aria-labelledby="mint-fragments-description"
                  disabled={isSubmitting}
                  maxValue={5}
                  minValue={1}
                />
              )}
              rules={{ required: true, min: 1, max: 5 }}
            />
             <p className="text-white mt-4" style={{fontSize: 'small', marginBottom: '-2rem', color: '#888'}}>
               By minting, you confirm you agree with the&nbsp;
               <TosLink />.
            </p>          

            <Button loading={isSubmitting} px='4' type="submit">
              Mint now
            </Button>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}

MintPurchaseDialogTrigger.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onMint: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  homepageCta: PropTypes.bool.isRequired
};

export default MintPurchaseDialogTrigger;